import React from 'react'


function ServiceCard({
    heading,
    content,
}) {
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1'>{paragraph}</p>);
  return (
    <div className="service-card py-4">
    <div className="service-card-info">
      
      <div className="profile-info px-2 d-flex flex-column gap-3 text-center ">
        
          <h3 className='body-heading mt-3' style={{fontSize:"20px"}}>{heading}</h3>
          <p className="body-paragraph px-4">{paragraphs}</p>
          
      </div>
     
    </div>
</div>
  )
}

export default ServiceCard