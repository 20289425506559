import React,{useState} from "react";
import "./Footer.css"
import { Link,useNavigate,NavLink } from "react-router-dom";
import {Spinner} from "react-bootstrap"
import axios from "axios"

function Footer({
    newsletterSection=false
}) {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const formSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
          .post("/send-newsletter", {
            email: email,
            formType: "Newsletter",
          })
          .then((response) => {
            if (response.data.msg === "success") {
              setError(false);
              setSuccess("Newsletter Subscribed Successfully!");
              setLoading(false);
              setEmail("");
            } else if (response.data.msg === "fail") {
              setSuccess(false);
              setError("Email failed to send, try again by reloading the page.");
              setLoading(false);
              setEmail("");
            }
          });
      };
  return (
    <>
    <div className="form container">
        {newsletterSection && 
            <div className="container standard-padding-space">
                <div className="form-container w-75 m-auto px-2">
                <div className="text-container text-center">
                    {/* <p className="sub-heading" >LOREM IPSUM</p> */}
                    <h3 className="body-heading" >JOIN OUR NEWS LETTER</h3>
                </div>
                <form className='inp-container d-md-flex d-flex align-items-center justify-content-between' onSubmit={formSubmitHandler}>
                    <input 
                         required
                         type="email"
                         value={email}
                         maxLength="56"
                         onChange={(e) => setEmail(e.target.value)}
                        placeholder='Your Email'
                    />
                    <button className='inp-btn' type="submit">Sign Up
                        {loading ? (
                            <Spinner
                                animation="border"
                                role="status"
                                style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                            />
                        ) : (
                        success ? (
                            <div >
                            {setLoading(false)}
                            {alert(success)}
                            {setSuccess(false)}
                            </div>
                        ) : (
                            error && (
                            <div>
                                {setLoading(false)}
                                {alert(error)}
                                {setError(false)}
                            </div>
                            )
                        )
                        )}
                    </button>
                </form>
                
                </div>
            </div>
        }
    </div>
    <div className={`footer-container `}>
        <div className="container">
        {/* newsletter Upper section */}
        <div className="upper-newsletter-container standard-padding-space container-fluid mx-auto" >

            <div className="row d-flex align-items-center gy-5">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="text-container">
                        <div className="newsletter-logo img-fluid mb-3">
                            {/* <img  src="/images/icons/nav-logo.svg" alt="" className="img-fluid" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}}/> */}
                            <img  src="/images/icons/nav-logo-new-w.png" alt="logo" className="img-fluid" onClick={()=>{navigate("/")}} style={{cursor:"pointer",maxWidth:"200px"}}/>
                        </div>
                        <div className="text-content">
                            <p>The Association provides a transformative experience where every stride, kick, and swing propels your child towards greatness. The MAC, developed by Olympians for Nexgen Olympians.</p>
                        </div>
                    
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="page-links d-flex flex-md-row gap-3 flex-column align-items-md-start align-items-center text-center justify-content-md-evenly justify-content-center">
                        <div className="text-md-start text-center d-flex flex-column gap-3">
                            <NavLink to={"/"} className="anchor" exact activeClassName="active">
                                Home
                            </NavLink>
                            <NavLink to={"/about"} className="anchor" exact activeClassName="active">
                                About
                            </NavLink>
                            <NavLink to={"/house-leagues"} className="anchor" exact activeClassName="active">
                                House Leagues
                            </NavLink>
                            <NavLink to={"/development"} className="anchor" exact activeClassName="active">
                                Development
                            </NavLink>
                            <NavLink to={"/grass-root-development"} className="anchor" exact activeClassName="active">
                                Grassroots
                            </NavLink>
                        </div>
                        <div className="text-md-start text-center d-flex flex-column  gap-3">
                            <Link to={"/blog"} className="anchor" exact activeClassName="active">
                                NEWS
                            </Link> 
                            <Link to={"/contact"} className="anchor" exact activeClassName="active">
                                Contact Us
                            </Link> 
                            <p className="anchor mb-0">Terms & Conditions</p>
                            <p className="anchor mb-0">Privacy Policy</p>
                            <p className="anchor mb-0">Cookie Policy</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <h3 className="body-heading" style={{color:"white",fontSize:"26px"}}>GET IN TOUCH</h3>
                    <div className="info-container">
                       <div className="d-flex flex-column align-items-center justify-content-start gap-3">
                            <div className="d-flex align-items-center gap-2 w-100">
                                <img src="/images/icons/footer-phone.svg"/>
                                <div>
                                    <a href="tel:363 653 5869" className="body-heading info-content" style={{color:"white",textDecoration:"none",cursor:"pointer"}}>(363) 653 5869</a>
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-2  w-100">
                                <img src="/images/icons/footer-mail.svg"/>
                                <div>
                                    <a className="body-heading info-content mb-0 info-content-mail"  href="mailto:info@Multisportsassociation.ca" style={{color:"white",textDecoration:"none",cursor:"pointer",textTransform:"none"}}>info@Multisportsassociation.ca</a>
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-2 w-100">
                                <img src="/images/icons/footer-location.svg"/>
                                <div>
                                    
                                    <p className="body-heading info-content mb-0" style={{color:"white"}}>Multi-Sports Association Ontario, Canada</p>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>

            </div>
            
           
        </div>
        </div>
        {/* div for border lining */}
        <div className="my-5"></div>

        {/* Lower sections */}

        <div className="lower-section text-center py-2">
            <div className="lower-container container flex-column gap-3 flex-md-row justify-content-md-between justify-content-center">
                <p className="mb-0" style={{color:"black"}}>Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank" style={{color:"black"}}>Creative Squad </a></p>
            <p className="mb-0" style={{color:"black"}}>© Copyright 2024. All Rights Reserved by MAC</p>
                <div className="social-link">
                    <img src="/images/icons/fb-icon.svg" alt="" />
                    <img src="/images/icons/insta-icon.svg" alt="" />
                    <img src="/images/icons/twitter-icon.svg" alt="" />
                    <img src="/images/icons/linked-icon.svg" alt="" />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer