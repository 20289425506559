import "./Testimonials.css"
import React,{useRef,useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import TestimonialCard from "./TestimonialCard";

function Testimonials() {
  const swiperRef = useRef(null);
  const [mouseHoverBtn,setMouseHoverBtn] = useState(false)
  const blogData = [
    {clientName:"Ranold Richards",clientImg:"/images/icons/profile-icon-male.svg",clientReview:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"},
    {clientName:"Amelia	Edmunds",clientImg:"/images/icons/profile-icon-female.svg",clientReview:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"},
    {clientName:"John Doe",clientImg:"/images/icons/profile-icon-male.svg",clientReview:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"},
    
    {clientName:"Ranold Richards",clientImg:"/images/icons/profile-icon-male.svg",clientReview:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"},
    {clientName:"Amelia	Edmunds",clientImg:"/images/icons/profile-icon-female.svg",clientReview:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"},
    {clientName:"John Doe",clientImg:"/images/icons/profile-icon-male.svg",clientReview:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"},
  ]

  const handlePrevButtonClick = () => {
    const swiperInstance = document.querySelector('#testimonial-cards-slider').swiper;
    swiperInstance.slidePrev();
  };
  
  const handleNextButtonClick = () => {
    const swiperInstance = document.querySelector('#testimonial-cards-slider').swiper;
    swiperInstance.slideNext();
  };

  return (
    <div className="Testimonials-container standard-padding-space">
      <div className="w-100 container px-0">
        <div className=" d-flex align-items-center w-100">
          <div className="col-12 text-center px-0">
            <p className="sub-heading mb-2">testimonials</p>
            <h3 className="body-heading">WHAT ARE PEOPLE SAYING ABOUT THE MAC EXPERIENCE</h3>
            {/* <p className="body-paragraph mx-auto py-1 px-3 px-md-2 w-75">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
              </p> */}
              <h3 className="body-heading mt-5 py-5" style={{fontSize:"40px"}}>coming soon</h3>
            {/* <Swiper
              spaceBetween={0}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              pagination={{ clickable: true }}
              observer={true}
              observeParents={true}
              parallax={true}
              breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                1450: {
                  slidesPerView: 3,
                },
              }}
              className="testimonials-list py-5 mt-2"
              id="testimonial-cards-slider"
            >
              {blogData.map((clientData, ind) => {
                return (
                  <>
                    <SwiperSlide key={ind} className="pt-5 px-3 px-sm-0">
                      <TestimonialCard
                        clientImg={clientData.clientImg}
                        clientName={clientData.clientName}
                        clientReview={clientData.clientReview}
                      />
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
            <div className="btn-container mt-5">
              <button
                className="swiper-button-prev swiper-button active"
                onClick={handlePrevButtonClick}
              >
                <img
                  src="/images/icons/btn-arrow-icon-r.svg"
                  className="btn-arrow-icon mb-0"
                />
              </button>
              <button
                className="swiper-button-next swiper-button"
                onClick={handleNextButtonClick}
                onMouseOver={() => {
                  setMouseHoverBtn(true);
                }}
                onMouseOut={() => {
                  setMouseHoverBtn(false);
                }}
              >
                <img
                  src="/images/icons/btn-arrow-icon-l.svg"
                  className={`btn-arrow-icon  mb-0`}
                />
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials