import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import ServiceCoursel from "../component/ServicesCarasoul/ServicesCarasoul"
import LtadBanner from '../component/LtadBanner/LtadBanner'

const serviceData=[
    {
        heading:"Holistic Development",
        content:" At The Multi-Sports Association Clubs of Canada (MAC), we prioritize the holistic development of athletes through our LTAD program, addressing their physical, mental, and emotional needs to foster well-rounded individuals on and off the field.",
    },
    {
        heading:"Age-Appropriate Training",
        content:"At The MAC, age-appropriate training is a key component of Long-term Athlete Development (LTAD). We understand that athletes have changing physical and cognitive abilities as they grow, and we adjust our training programs to accommodate these differences. This approach helps athletes develop their skills in a way that sets them up for long-term success.",
    },
    {
        heading:"Individualized Pathways",
        content:"The MAC emphasizes personalized player pathways through its LTAD program. We understand that each athlete has unique goals, strengths, and aspirations. Our LTAD program allows athletes to progress at their own pace, providing a flexible and personalized journey through the developmental stages.",
    },
    
    {
        heading:"Multi-Sport Exposure:",
        content:"One of the distinct advantages of LTAD at The MAC is the emphasis on multi-sport exposure. By encouraging athletes to participate in various sports, we not only foster a diverse skill set but also reduce the risk of burnout. This approach is a testament to our commitment to developing versatile, adaptable athletes while prioritizing their well-being",
    },
]

function Ltad() {
  return (
    <>
          <HeroBanner 
            postSubHeading=""
            Heading="LTAD"
            nextLineSpanHeading=""
            Paragraph='Long-Term Athlete Development'
            btn={false}
            btnText=""
            bgImgLg="/images/creatives/ltad-hero-bg-lg.jpg"
            bgImg="/images/creatives/ltad-hero-bg-mb.webp"
            btnRef="/"
            mobileText={false}
          />
          <LtadBanner/>
      <div className='right-gradient'>

            <ServiceCoursel
                heading="Key Components of LTAD"
                content=""
                serviceData={serviceData}
                marginTopRemoval={true}
            />


        <Footer/>
        </div>

       
    </>
  )
}

export default Ltad