import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'
import TextContent from '../component/TextContent/TextContent'

function QualityFirst() {
    return (
        <div className="home-page">
             <Helmet>
            <title>
            NextGEN Sports Model: Quality Training for Grassroots Athletes
            </title>
            <meta
              name="description"
              content="Discover our tailored approach to athlete development, fostering social networks and sustained engagement. Explore dynamic stations for versatile activities and creative game formats"
            ></meta>
          </Helmet>
          <HeroBanner 
            postSubHeading=""
            Heading="Quality First"
            nextLineSpanHeading=""
            Paragraph=''
            btn={false}
            btnText=""
            bgImg="/images/creatives/quality-hero-bg.jpg"
            bgImgLg="/images/creatives/quality-hero-bg-lg.jpg"
            btnRef="/"
            largeMobileHeading={true}
          />
          <RedPostBanner 
           heading='DEVELOPMENT MODEL & ATTENTION TO DETAIL'
           subLine={true}
            content={`Implementing the Athlete Development Model offers several benefits. It allows for training in smaller groups, which helps in developing a child's focus. Our sessions follow a structured format in which young athletes engage in various activities, games, and drills. This approach offers a holistic and engaging way to promote multi-sport development, making the learning process fun and exciting for young athletes.`}
          />
      <div className='right-gradient'>

        {/* <div className='quality-centered-text'>
            <div className=' container standard-padding-space'>
                <div className='text-content text-center d-flex flex-column justify-content-center align-items-center my-3'>
                    
                    
                    <h3 className='body-heading px-3'>HOW IT WORKS</h3>
                    <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"4px",marginBottom:"4px"}}/>
                    <h3 className='body-heading px-3'>TRAINING PODS</h3>
                    <p className='body-paragraph w-75 my-2'>This model facilitates training in smaller groups, fostering the development of a child's social network. When implementing the Athlete Development Model, our sessions follow a structured format where young athletes move as a group between activities, games, and drills</p>

                    <h3 className='body-heading px-3'>STATIONS</h3>
                    <p className='body-paragraph w-75 my-2'> Using the station approach, we customize each session to meet the unique needs of every child, ensuring continued engagement and motivation. In this approach, children move through various stations, spending a set amount of time on each activity. They take a brief break for hydration, and then transition promptly to the next activity.</p>

                    <h3 className='body-heading px-3'>LARGE GROUPS</h3>
                    <p className='body-paragraph w-75 my-2'>In situations involving large groups, multiple stations operate at the same time. All stations start and finish together, creating a dynamic and enjoyable environment for athletes and coaches. Stations are versatile and can be customized for various activities, including physical literacy, individual work, body awareness, and position play. Game and sports stations, in particular, provide coaches with the opportunity to introduce creative formats such as:</p>
                    <p className='body-paragraph w-75 my-2'>Three versus three basketball games, 4-goal soccer, pitch, hit, pitch & run baseball, run , jump throw in track and field, creative show-and-tell games for artistic sports, end zone games, or possession-based challenges.</p>


                    <p className='body-paragraph w-75 my-2'>Ultimately offering an engaging and holistic approach to multi-sport development.</p>
                         
                </div>
            </div>
        </div> */}

<div className='about-text-content standard-padding-space' >
                              <div className='container'>
                                  <div className='center-text text-center d-flex flex-column justify-content-center align-items-center my-3'>
                                  
                                      <h3 className='body-heading '>HOW IT WORKS</h3>
                                      <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"2px",marginBottom:"2px"}}/>
                                      {/* <p className='body-paragraph w-75'>The MAC's athlete development model for children under ten is a holistic approach that offers numerous benefits for nurturing young athletes. Structured around four pillars, the model contributes to well-rounded development: talent identification through multi-sport development, long-term athlete development, sports nutrition for kids, and athlete intelligence.</p> */}

                                  </div>
                                

                                  <TextContent
                                      heading= "TRAINING PODS"
                                      subHeading=""
                                      content={"This model facilitates training in smaller groups, fostering the development of a child's social network. When implementing the Athlete Development Model, our sessions follow a structured format where young athletes move as a group between activities, games, and drills"}
                                      btn={false}
                                      btnText=""
                                      btnRef='/'
                                      shiftRow={true}
                                      // img="/images/creatives/about-text-4.webp"
                                      img="/images/creatives/qf-text-1.jpg"
                                      reviewContent={false}
                                      sublineIcon={false}
                                  />
                                  <TextContent
                                      heading= "STATIONS"
                                      subHeading=""
                                      content={"Using the station approach, we customize each session to meet the unique needs of every child, ensuring continued engagement and motivation. In this approach, children move through various stations, spending a set amount of time on each activity. They take a brief break for hydration, and then transition promptly to the next activity."}
                                      btn={false}
                                      btnText=""
                                      btnRef='/'
                                      shiftRow={false}
                                      // img="/images/creatives/about-text-3.webp"
                                      img="/images/creatives/qf-text-2.jpg"
                                      reviewContent={false}
                                      sublineIcon={false}
                                  />
                                  <TextContent
                                      heading= "LARGE GROUPS"
                                      subHeading=""
                                      content={"In situations involving large groups, multiple stations operate at the same time. All stations start and finish together, creating a dynamic and enjoyable environment for athletes and coaches. Stations are versatile and can be customized for various activities, including physical literacy, individual work, body awareness, and position play. Game and sports stations, in particular, provide coaches with the opportunity to introduce creative formats such as:\n Three versus three basketball games, 4-goal soccer, pitch, hit, pitch & run baseball, run , jump throw in track and field, creative show-and-tell games for artistic sports, end zone games, or possession-based challenges."}
                                      btn={false}
                                      btnText=""
                                      btnRef='/'
                                      shiftRow={true}
                                      // img="/images/creatives/about-text-2.webp"
                                      img="/images/creatives/qf-text-3.jpg"
                                      reviewContent={false}
                                      sublineIcon={false}
                                  />
                                  

                                  
                              </div>
                      </div>
         
        </div>

       
        <Footer/>
    </div>
    )
}

export default QualityFirst