import React, { useState } from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import IconsText from '../component/IconsText/IconsText'
import { Helmet } from 'react-helmet'

function Home() {
  return (
    <div className="home-page">
      <Helmet>
        <title>
        NexGen Multi-Sports Association (NXGMSA) - Athletic Development
        </title>
        <meta
          name="description"
          content="Unlock your child's potential with NexGen Multi-Sports Association. Experience diverse programs, expert guidance, and values-driven approach for ages 12 and under."
        ></meta>
      </Helmet>
      <HeroBanner 
        subHeading=""
        Heading="Multi-Sports Association Clubs Of Canada"
        nextLineSpanHeading=""
        // Paragraph="Where Sports Dreams Come to Life"
        btnText="LEARN MORE"
        // bgImg="/images/creatives/home-hero-bg.jpg"
        btn={true}
        btnRef="/about"
        bgVideo="/images/mac-home-video.mp4"
      />
      <IconsText/>
      
<div className='right-gradient'>
  <div className='left-gradient'>
      
      <TextContent
         heading= "Multi-Sports Association Clubs of Canada"
         subHeading="Welcome to the MAC"
         content={"Welcome to the Multi-Sports Association Clubs of Canada (The MAC), your family's premier destination for a holistic and innovative approach to your child's athletic development. The MAC stands out from traditional one-sport associations by offering a wide range of programs, including house leagues, skill development, sports events, and camps designed for children aged 10 and under. The association is dedicated to  Long-Term Athlete Development, providing guidance on proper nutrition, optimizing your child's performance, and ensuring long-term health.\n The MAC provides a transformative experience focused on developing future scholarship athletes, national team members, professional athletes, and Olympians. Our programs are designed to unlock the inherent potential in every child, with an emphasis on instilling values of discipline, teamwork, and determination. This sets the stage for a lifetime of achievement, making The MAC the ideal choice for a holistic and enriching athletic journey. Join us at The Multi-Sports Association Clubs of Canada (MAC) for an unparalleled experience that shapes your child's athletic prowess and instills lasting values."}
         btn={true}
         btnText="ABOUT US"
         btnRef='/about'
         shiftRow={true}
         marginTop={true}
        //  img="/images/creatives/home-text-1.webp"
         img="/images/creatives/home-text-0.1.jpg"
         reviewContent={false}
      />
</div>
</div>
<div className='left-gradient'>
      
      <CardCarousel
        subHeading="NEWS & FEEDS"
        heading="OUR POPULAR NEWS & BLOGS"
        content={""}
        />


        <Testimonials/>

      <Footer
        newsletterSection={true}
      />
      </div>
</div>
  )
}

export default Home