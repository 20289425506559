import React from 'react';
import './SpeedTest.css';

const SpeedTest = () => {
    return (
        <div className='speed_test' style={{backgroundImage: `url('/images/creatives/speed-test-bg.jpg')`}}> 
            <div className='container'>
                <div className='speed_details'> 
                    <h2 className='body-heading ' style={{color:"white"}}>SPEED KILLS</h2>
                </div>
              
                <div className='test_wrapper'>
                    <div className='row'> 
                        <div className='col-lg-4 col-md-6 col-12 mb-4'>
                            <div className='speed_card'>
                                <img className='img-fluid' src='/images/icons/speed-20.svg' />
                                <h3>20 Meter Fly</h3>
                                <p>Discover the true extent of your sprinting prowess with our 20-meter fly test. This assessment determines your acceleration, maximum velocity, stride length, and stride frequency. By running a single 20-meter distance, with split times recorded every 5 meters, we capture essential data using our advanced technology. Through measurements such as total time, split times, velocity, relative power, RFD, and acceleration, we uncover key factors influencing your speed performance.</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-4'>
                            <div className='speed_card'>
                               <img className='img-fluid' src='/images/icons/speed-10.svg' />
                               <h3>10 Meter Fly</h3>
                               <p>The 10-meter fly test is a popular and straightforward assessment that measures your ability to accelerate. In this test, you'll sprint a single 10-meter distance, with split times recorded at 5 and 10 meters. Equipped with our state-of-the-art 1080 Sprint technology, we measure your total time, split times, velocity, relative power, rate of force development (RFD), and acceleration. These measurements provide invaluable insights into your acceleration capabilities, helping us tailor training programs to optimize your child’s performance.</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-4'>
                            <div className='speed_card'>
                                <img className='img-fluid' src='/images/icons/speed-60.svg' />
                                <h3>60 Meter Sprint</h3>
                                <p>Measure how fast an athletes’ body can unleash its full potential over a slightly longer distance with our 60-meter sprint test. In this assessment, the athlete sprints for 60 meters, with split times recorded every 5 meters. Our precision measurements, powered by cutting-edge technology, allow us to capture vital data and insights into your sprinting abilities.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SpeedTest;