import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import TextContent from '../component/TextContent/TextContent'
import ServiceCoursel from "../component/ServicesCarasoul/ServicesCarasoul"
import { Helmet } from 'react-helmet'
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'
import TextCard from '../component/TextCard/TextCard'

const serviceData=[
    {
      "title": "Advanced Skill Introduction",
      "actualPara": "The 7U program focuses on developing advanced skills relevant to the chosen sports within the association. Coaches concentrate on refining motor skills, agility, and introducing sport-specific techniques to prepare athletes for a more specialized training approach."
    },
    {
      "title": "Position-Specific Training",
      "actualPara": "Athletes start to explore position-specific training, which helps them understand the responsibilities and roles associated with different positions in their chosen sports. This approach fosters a deeper appreciation for team strategy and individual contributions."
    },
    {
      "title": "Structured Game Play",
      "actualPara": "The program introduces a more structured gameplay by incorporating age-appropriate rules to provide a more authentic sports experience. Athletes participate in modified game formats that encourage strategic thinking, teamwork, and the application of learned skills in a dynamic setting."
    },
    {
      "title": "Individualized Development Plans",
      "actualPara": "Coaches create individualized development plans for each athlete, recognizing their unique strengths, areas for improvement, and specific interests within the sports offered. This personalized approach ensures that every athlete progresses according to their abilities and aspirations."
    },
    {
      "title": "Team Building & Communication",
      "actualPara": "Team building activities and communication exercises are essential components of the 7U program. Athletes are taught the significance of effective communication on the field, which fosters a sense of camaraderie and collective responsibility within the team."
    },
    {
      "title": "Introduction to Competitive Spirit",
      "actualPara": "The program gently introduces a sense of healthy competition, emphasizes the value of good sportsmanship, and stresses respect for opponents. Athletes learn to embrace both victories and challenges as opportunities for growth and learning."
    },
    {
      "title": "Family Engagement & Support",
      "actualPara": "MAC continues to actively encourage family engagement during the 7U developmental stage. Parents play a crucial role in supporting their child's evolving journey, fostering a positive and collaborative atmosphere within the sports community."
    }
  ]
  

function SevenU() {
    return (
        <div className="home-page">
             <Helmet>
            <title> 
            7U Team & Sport Development at NXGMSA | Youth Sports Progression
            </title>
            <meta
              name="description"
              content="Discover how 7U athletes evolve in NXGMSA with structured training, skill refinement, and team dynamics. Lay the foundation for a fulfilling sports journey."
            ></meta>
          </Helmet>
          <HeroBanner 
            postSubHeading=""
            Heading="7U TSD"
            nextLineSpanHeading=""
            Paragraph=''
            btn={false}
            btnText=""
            bgImgLg="/images/creatives/sevenu-hero-bg-lg.jpg"
            bgImg="/images/creatives/sevenu-hero-bg-mb.webp"
            btnRef="/"
            mobileText={false}
          />
          <RedPostBanner
            heading='DELIVERING A  COMPREHENSIVE SPORTS EXPERIENCE'
            subLine={true}
            content='The transition to the 7U age group is for children 5 to under 7 years old . Young athletes within MAC will experience a more structured approach to sports, which will help them build on their foundational skills, learn advanced techniques, and understand team dynamics more deeply. This phase aims to set the stage for a more detailed and comprehensive sports experience.'
          />
      <div className='right-gradient'>
            <TextContent
                heading= "7U SPORTS DEVELOPMENT"
                subHeading=""
                content={"In the 7U developmental stage within The Multi-Sports Association of Canada (MAC), there is a heightened focus on the beginnings of skill refinement, position-specific training, and the introduction of structured game play. As athletes progress through this stage, they not only advance their individual skills but also develop a deeper understanding of teamwork, strategy, and the competitive spirit inherent in sports.\n The MAC is committed to providing a supportive and enriching environment where young athletes can thrive, grow, and derive joy from their sports experience. The transition from 7U sets the foundation for more specialized training and a continued journey toward excellence in sports and personal development."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                img="/images/creatives/7u-text-1.webp"
                // img="/images/creatives/7u-text-0.1.jpg"
                reviewContent={false}
                sublineIcon={true}
            />

            {/* <ServiceCoursel
                heading=""
                // content={"In the 7U developmental stage within The Multi-Sports Association of Canada (MAC), there is a heightened focus on the beginnings of skill refinement, position-specific training, and the introduction of structured game play. As athletes progress through this stage, they not only advance their individual skills but also develop a deeper understanding of teamwork, strategy, and the competitive spirit inherent in sports.\n The MAC is committed to providing a supportive and enriching environment where young athletes can thrive, grow, and derive joy from their sports experience. The transition from 7U sets the foundation for more specialized training and a continued journey toward excellence in sports and personal development."}
                content={""}
                subline={false}
                serviceData={serviceData}
            /> */}

            <TextCard
                heading=""
                content=""
                dropDown={false}
                cardContent={serviceData}
                subLine={false}
                marginTop={true}title
                isBottomSection={false}
                // divisonSize={6}
            />
            
         
        </div>

       
        <Footer/>
    </div>
    )
}

export default SevenU