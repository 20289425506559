import React, { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import styles from './BodyTest.module.css';

const BodyTest = ({ arrayData }) => {
    const [id, setId] = useState(arrayData[0].id);
  
    const ImgColumn = () => {
        return (
            <div className="col-lg-6 col-12 mt-lg-0 mt-5 d-flex align-items-center justify-content-center px-5">
                <img className="img-fluid w-auto" src="/images/creatives/body-test-img.jpg" style={{height:"400px"}}/>
            </div>
        );
    };
  
    return (
        <div className={styles.main}>
            <Container>
                <div className={styles.body_test_details}> 
                    <h1>Powerful Legs Powerful Performance</h1>
                    <div className='text-horizontal-line'></div>
                </div>
                <Row className="gy-4 mt-5">
                    <Col lg={6}>
                        <div>
                            {arrayData.map((item) => (
                                <div className={styles.box}>
                                    <Col
                                        lg={1}
                                        style={{
                                            borderLeft: item.lastDiv && "6px solid transparent",
                                        }}
                                        className={
                                            item.id === id ? styles.divColActive : styles.divCol
                                        }
                                    >
                                        <div onClick={() => setId(item.id)}></div>
                                    </Col>
                                    <Col lg={11} className={styles.contentCol}>
                                        <h5 onClick={() => setId(item.id)} className='font-mohave' style={{color:`${item.id === id ? "#d00100":"#818181"}`}} >
                                            {item.heading}
                                        </h5>
                                        {item.id === id && <p>{item.para}</p>}
                                    </Col>
                                </div>
                            ))}
                        </div>
                    </Col>

                    { ImgColumn() }
                </Row>
            </Container>
        </div>
    );
};
  
export default BodyTest;