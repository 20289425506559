import React,{useState,useEffect} from "react";
import "./CardBlog.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import CardBox from "./CardBox";
import axios from "axios"
import {useNavigate} from "react-router-dom"
import {Spinner} from "react-bootstrap"

const blogData = [
  {
    blog_creative: "./images/creatives/card_blog_1.png",
    main_heading: "The Lucas Vikings played the Saunders Sabres in TVRA senior boys basketball",
    author: "Admin",
    date: "DECEMBER 20, 2023",
    slug_url: "/",
  },
  {
    blog_creative: "./images/creatives/card_blog_2.png",
    main_heading: "The Lucas Vikings played the Saunders Sabres in TVRA senior boys basketball",
    author: "Admin",
    date: "DECEMBER 20, 2023",
    slug_url: "/",
  },
  {
    blog_creative: "./images/creatives/card_blog_3.png",
    main_heading: "The Lucas Vikings played the Saunders Sabres in TVRA senior boys basketball",
    author: "Admin",
    date: "DECEMBER 20, 2023",
    slug_url: "/",
  },
  {
    blog_creative: "./images/creatives/card_blog_1.png",
    main_heading: "The Lucas Vikings played the Saunders Sabres in TVRA senior boys basketball",
    author: "Admin",
    date: "DECEMBER 20, 2023",
    slug_url: "/",
  },

];

let defaultBlogObj = []

function CardTestimonial({
  subHeading,
  heading,
  content,
  spanHeading
}) {

  const [blogs, setBlogs] = useState([]);
  const [recentBlogs, setRecentBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  let blogAPICalled = false;

  useEffect(() => {
    getAllBlogsListing();
  }, []);

  const getAllBlogsListing = () => {
    if (blogAPICalled) return;
    blogAPICalled = true;

    setLoading(true);
    axios
      .get("http://localhost:5000/blogs", {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_data_Blogs = [];
          let Updated_recent_blogs = [];
          res.data.data.forEach((item) => {
            Updated_data_Blogs.push({
              id: item.blog_id,
              main_heading: item.title,
              paragraph: item.brief_paragraph,
              logo: "/favicon.png",
              entity: "MAC",
              date: item.published_date,
              slug_url: item.slug_url,
              category: item.category,
              blog_creative: item.blog_image,
            });
          });
          setBlogs(Updated_data_Blogs);
          if(blogs.length <= 3)
          {
            setBlogs((prev)=>[...prev,...prev])
          }
          defaultBlogObj = Updated_data_Blogs;
          setLoading(false);
        }
          
      })
      .catch((err) => {
        console.log("eror =>",err);
        setLoading(false);
      });
  };

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);

    navigate(`/blog/${slug}`);
  };

  return (
    <>
    {
      blogs.length > 0 &&
        <div className='blog-container  standard-padding-space my-lg-4 my-1 px-1'>
          <div className="container">
          <div className="blog-upper-content mb-5">
            <div className="container d-flex flex-column flex-md-row align-items-start align-items-md-end gap-3 gap-md-0 justify-content-between">
                <div className="blog-text-content text-lg-start text-center">
                  <p className="sub-heading mb-1">{subHeading}</p>
                  <h3 className="body-heading mb-0">{heading}<span className="color-yellow">{spanHeading}</span></h3>   
                  {content && <p className="body-paragraph my-3 px-2 px-md-0">{content}</p>}
                  <img src="/images/icons/subline.svg" alt="icon"/>
                </div>
                <div className="btn-container text-lg-end text-center ">
                  <button className="genral-btn" onClick={()=>{navigate("/blog")}}>VIEW ALL</button>
                </div>
            </div>
          </div>
          {loading ? 
            (<div className="w-100 text-center mb-5">
              <Spinner
                style={{ color: "white", width: "120px", height: "120px" }}
              />
            </div>):(
            <Swiper
                spaceBetween={30}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }}
                loop={true}
                autoplay={
                  {
                    delay:1500,
                    disableOnInteraction:false
                  }
                }
                modules={[Autoplay]}
                pagination={{ clickable: true }}
                observer ={true}
                observeParents={true}
                parallax={true}
                breakpoints={{
                    280: {
                      slidesPerView: 1,
                    },
                    500:{
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 3,
                    }
                  }}
                className="card-list py-4 px-3"
                id="articles-cards-slider"
                >
                {blogs.map((blogData,ind)=>{
                  return (<>
                    <SwiperSlide key={ind}>
                      <CardBox  

                        cardImg={blogData.blog_creative} 
                        title={blogData.main_heading} 
                        author={"Admin"} 
                        // content={blogData.paragraph} 
                        anchor={blogData.slug_url}
                        redirectionFunction={redirectUserToBlog}
                        publishDate={blogData.date}
                      />
                    </SwiperSlide>
                  </>)
                })}
                
            </Swiper>)}
            </div>
        </div>
    }
    </>
    
  )
}

export default CardTestimonial