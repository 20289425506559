import React from 'react'
import "./LatestNews.css"

function LatestNews({firstBlog,redirectionFunction}) {
  return (
    <div className="news_container standard-margin-space ">
        <div className='container' onClick={()=>{redirectionFunction(firstBlog.slug_url)}}>
            <div className='row align-items-center'>
                <div className='col-md-6'>
                    <div className="news_img_container">
                        <img className="latest_news_img img-fluid" src={firstBlog.blog_image} />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="text_content">
                        <h3 className='h3_main mb-2'><span className='sub-heading'>Latest News</span></h3>
                        <h2 className='h1_main mb-2 body-heading'>{firstBlog.title}</h2>
                        <p className='para_main mb-3 body-paragraph'>{firstBlog.brief_paragraph}</p>
                        <div className="blog_news_logo">
                            <img src="/images/icons/mac-blog-logo.png" />
                            <div className="blog_news_logo_text">
                                <h6 className='.h5_main m-0' style={{color:"#2D2D2D"}}>MAC</h6>
                                <p className='m-0 para_main'>{firstBlog.published_date}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LatestNews