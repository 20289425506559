import React,{useState} from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import EventsContent from '../component/EventsContent/EventsContent'
import Sponsor from '../component/Sponsor/Sponsor'
import { Helmet } from 'react-helmet'
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'
import KeyFeatureText from "../component/KeyFeatureText/KeyFeatureText"

const catagory = [
    {
        name:"SPORT TALKS",
        title:"SPORT TALKS",
        num:0,
        content:"The Multi-Sport Association Clubs of Canada (MAC) hosts SPORTS TALKS for families covering sports development, elite athlete support, sports nutrition, and faith-centered discussions. Sport Workshops provide guidance on nutrition, training, mental health, injury prevention, balancing academics and athletics, parental support, and understanding sports development stages for young athletes. These aim to equip parents with knowledge and tools to foster a supportive environment for their children's athletic development."
    },
    {
        name:"WORKSHOPS",
        title:"WORKSHOPS",
        num:1,
        content:"Sports workshops  will guide families in the areas of nutrition, training, mental health, injury prevention, balancing academics and athletics, parental support, and understanding sports development stages for young athletes. These aim to equip parents with knowledge and tools to foster a supportive environment for their children's athletic development."
    },
    {
        name:"Skill Development",
        title:"Skill Development Clinics & Camps",
        num:2,
        content:" Complementing the competitive aspect of the leagues, the Association offers skill development clinics and camps. Clinics and camps provide participants with additional opportunities to enhance their abilities, receive coaching, and further develop their understanding of the sport."
    },
    {
        name:"Showcases",
        title:"Showcases",
        num:3,
        content:"MAC Showcase Events are hosted at various times throughout the year where young athletes showcase their talents to family and friends. Young children always get excited to show-and-tell their skills they have learned through the MAC programs."
    },
    {
        name:"Games ",
        title:"Summer & Winter Games ",
        num:4,
        content:"Twice a year, the MAC hosts the NexGEN Games, an event that emulates the spirit of the Winter and Summer Olympic Games. The NexGEN Games serve as a platform for kids to exhibit progress and compete in various sports. We invite you to an unforgettable experience featuring opening and closing ceremonies, multi-sports events, awards ceremonies, ancillary events, raffles, and more."
    },
]

const cardData = [
  {
    "id": 1,
    "heading": "Games",
    "content": {
      "img": "/images/creatives/key-feature-img-serveve-5-new-n.webp",
      "title": "Summer & Winter Games",
      "para": "Twice a year, the MAC hosts the NexGEN Games, an event that emulates the spirit of the Winter and Summer Olympic Games. The NexGEN Games serve as a platform for kids to exhibit progress and compete in various sports. We invite you to an unforgettable experience featuring opening and closing ceremonies, multi-sports events, awards ceremonies, ancillary events, raffles, and more."
    }
  },
  {
    "id": 2,
    "heading": "Showcases",
    "content": {
      // "img": "/images/creatives/key-feature-img-serveve-4-new.webp",
      "img": "/images/creatives/key-feature-img-serveve-5-new.webp",
      "title": "Showcases",
      "para": "MAC Showcase Events are hosted at various times throughout the year where young athletes showcase their talents to family and friends. Young children always get excited to show-and-tell their skills they have learned through the MAC programs."
    }
  },
  {
    "id": 3,
    "heading": "Skill Development",
    "content": {
      "img": "/images/creatives/key-feature-img-serveve-3.webp",
      "title": "Skill Development Clinics & Camps",
      "para": "Complementing the competitive aspect of the leagues, the Association offers skill development clinics and camps. Clinics and camps provide participants with additional opportunities to enhance their abilities, receive coaching, and further develop their understanding of the sport."
    }
  },
  {
    "id": 4,
    "heading": "WORKSHOPS",
    "content": {
      "img": "/images/creatives/key-feature-img-serveve-2-new.webp",
      "title": "WORKSHOPS",
      "para": "Sports workshops will guide families in the areas of nutrition, training, mental health, injury prevention, balancing academics and athletics, parental support, and understanding sports development stages for young athletes. These aim to equip parents with knowledge and tools to foster a supportive environment for their children's athletic development."
    }
  },
    {
      "id": 5,
      "heading": "SPORT TALKS",
      "content": {
        "img": "/images/creatives/key-feature-img-serveve-1-new-n.webp",
        "title": "SPORT TALKS",
        "para": "The Multi-Sport Association Clubs of Canada (MAC) hosts SPORTS TALKS for families covering sports development, elite athlete support, sports nutrition, and faith-centered discussions. Sport Workshops provide guidance on nutrition, training, mental health, injury prevention, balancing academics and athletics, parental support, and understanding sports development stages for young athletes. These aim to equip parents with knowledge and tools to foster a supportive environment for their children's athletic development."
      }
    },
    {
      "id": 6,
      "heading": "Competitions",
      "content": {
        "img": "/images/creatives/key-feature-img-serveve-8-new-n.jpg",
        "title": "Push Your Limits At Splash Multi-Sports Events",
        "para": "Push Your Limits at Splash MultiSports Events. Join MAC Competitive Multi-Sports for thrilling events designed exclusively for young athletes. Experience the thrill of victory, competition, and sportsmanship. Register now to showcase your child's talent on the grand stage! Single Sports, Multi Sports, Age-Appropriate Categories, Fun Competition, Prizes."
      }
    }
  ]
  

function Events() {
    const [selCatagory,setSelCatagory] = useState({
        name:"All",
        num:0
    })


    
    return (
        <>
             <Helmet>
            <title>
            Discover Current News | Events | Championships
            </title>
            <meta
              name="description"
              content="Stay updated with the latest track and field events at NexGen Multi-Sport Association. Find sanctioned players, competitions and many more."
            ></meta>
          </Helmet>
            <HeroBanner 
            subHeading=""
            Heading="EVENTS"
            nextLineSpanHeading=""
            Paragraph=""
            bgImg="/images/creatives/event-hero-bg-mb.webp"
            bgImgLg="/images/creatives/event-hero-bg-lg.jpg"
            btn={false}
            mobileText={false}
            customHeight={"h-65"}
          />
          <RedPostBanner
            heading='A VARIETY OF SPORTS COMPETITIONS FOR KIDS'
            subLine={true}
            content={`Prepare for exciting children's sports events at MAC! We organize a variety of sports competitions for kids, including physical challenges and sports-specific events like track and field and basketball tournaments. Our approach guarantees a fun and comprehensive athletic experience for every child, with support and inclusion for all. Join us for diverse multi-sport events, including soccer, basketball, swimming, and gymnastics. Come and cheer on talented young athletes as they compete for glory at MAC Sports Events!`}
            // customTranslate={-50}
          />

    <div className='left-gradient'>
    {/* <div className='EventsContent-container standard-margin-space mb-0'>
        <div className='container'>
            <div className='text-content text-center'>
                <h3 className='body-heading my-3'>Competitions</h3>
                <h5 className='sub-heading my-2'>Push Your Limits At Splash Multi-Sports Events</h5>
                <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"2px"}}/>
                <p className='body-paragraph mt-4 mb-0'>Push Your Limits at Splash MultiSports Events. Join MAC Competitive Multi-Sports for thrilling events designed exclusively for young athletes. Experience the thrill of victory, competition, and sportsmanship. Register now to showcase your child's talent on the grand stage! Single Sports, Multi Sports, Age-Appropriate Categories, Fun Competition, Prizes</p>
                
                
            </div>
        </div>
    </div> */}

    <KeyFeatureText
     heading=''
     dataArray={cardData}
    />



    {/* <div className='event-Catagory standard-padding-space'>
            <div className='container'>
                <div className='main-container' >
                    <div 
                        className='row gy-2 main-catagory-container mx-3 mx-lg-0 justify-content-center' 
                    >
                        {
                            catagory.map((ele,ind)=>{
                                return(
                                <div 
                                    className={` ${ele.num== 0|| ele.num ==2 ? "col-xxl-3 col-lg-3" : "col-xxl-2 col-lg-2"}  catagory-container ${ele.num == selCatagory.num ? "active" : ""} `}
                                    onClick={()=>{setSelCatagory(ele)}}    
                                >
                                    <p className='body-heading mb-0' >{ele.name}</p>
                                </div>
                                )
                            })
                        }
                        
                    </div>

                </div>

            </div>

        </div>
       <EventsContent
            selectedCatagory={selCatagory.num}
            content={selCatagory.content}
            title={selCatagory.title}
       /> */}


                    {/* <Sponsor/> */}
         
          </div>
          
          <div className='bottom-gradient'>
          
          <Footer
            newsletterSection={false}
          />
          </div>
        </>
      )
}

export default Events