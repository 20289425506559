import React from 'react';
import './UnleashPotential.css';

const UnleashPotential = () => {
    return (
        <div className='unleash_potential'>
            <div className='container'>
                <div className='potential_details'>
                    <h1 className='font-mohave'>Peak Performance</h1>
                    <div className='text-horizontal-line'></div>
                </div>

                <div className='potential_boxes_wrapper'>
                    <div className='row'>
                        <div className='col-lg-6 col-12 mb-5'>
                            <div className='potential_boxes_card'>
                                <h4>Movement Screen - Overhead Squat</h4>
                                <p>By identifying compensatory movements and assessing the activation levels of specific muscles, we gain insights into your movement patterns and potential muscle imbalances.</p>
                                <a href='tel:363 653 5869'><button className='genral-btn'>REGISTER NOW</button></a>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12'>
                            <div className='potential_boxes_card'>
                                <h4>Movement Screen - Push-up</h4>
                                <p>By performing three push-ups while lying face down on our dual force plates, we gather valuable data on the left/right force distribution and identify any asymmetries.</p>
                                <a href='tel:363 653 5869'><button className='genral-btn'>REGISTER NOW</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnleashPotential;