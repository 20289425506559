import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import TextContent from '../component/TextContent/TextContent'
import ServiceCoursel from "../component/ServicesCarasoul/ServicesCarasoul"
import { Helmet } from 'react-helmet'
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'
import TextCard from '../component/TextCard/TextCard'

const serviceData=[
    {
        title:"Introduction to Movement and Play",
        actualPara:"The 4U program emphasizes introducing basic movements to children and engages in play activities that build a foundation for future sports involvement. Coaches prioritize creating a fun and interactive environment that promotes the development of motor skills, coordination, and spatial awareness.",
    },
    {
        title:"Parent-Child Engagement",
        actualPara:"Recognizing the essential role of parents in a child's early development, the 4U program promotes active parent-child engagement. Activities are designed to involve parents, fostering a sense of connection and bonding through exploration of sports.",
    },
    {
        title:"Multi-Sensory Approach",
        actualPara:"The 4U team development program incorporates a multi-sensory approach, engaging children through various sensory experiences. - Activities are designed to stimulate visual, auditory, and tactile senses, creating a holistic and enjoyable introduction to sports.",
    },
    {
        title:"Foundational Movement Patterns",
        actualPara:"Coaches teach essential movement patterns like crawling, walking, running, jumping, and throwing, along with basic coordination exercises, to help children build a solid physical base. The activities are carefully chosen to align with the developmental milestones of children under 4.",
    },
    {
        title:"Sport Exposure & Exploration",
        actualPara:"The 4U program focuses on general movement while also introducing young child athletes to the concept of different sports in a playful and exploratory manner. Exposure to various sports elements helps to foster familiarity with and curiosity about sports from an early age.",
    },
    {
        title:"Safety & Enjoyment",
        actualPara:"Ensuring safety is the top priority in the 4U program. Coaches make sure that activities are suitable for each age group and take place in a supervised and secure setting. The main objective is to establish an environment where children can learn and take pleasure in their initial experiences with sports.",
    },
]

function FourU() {
    return (
        <div className="home-page">
             <Helmet>
            <title>
            4U Team & Sport Development for Ages 4 and Under
            </title>
            <meta
              name="description"
              content="At NexGen Sports Association, our 4U team focuses on fostering early sports skills in children under 4. Start their journey with structured play and skill introduction."
            ></meta>
          </Helmet>
          <HeroBanner 
            postSubHeading=""
            Heading="4U TSD"
            nextLineSpanHeading=""
            Paragraph=''
            btn={false}
            btnText=""
            bgImgLg="/images/creatives/fouru-hero-bg-lg.jpg"
            bgImg="/images/creatives/fouru-hero-bg-mb.webp"
            btnRef="/"
            mobileText={false}
            bgSmallScreenWidth={true}
          />
          <RedPostBanner
            heading='WE ARE COMMITTED TO CULTIVATING A LOVE FOR SPORTS'
            subLine={true}
            content='At the Multi-Sports Association of Canada (MAC), we are committed to cultivating a love for sports right from the early stages of development. The 4U programs are designed for athletes aged 2.5 to under 4 years old, representing a vital phase in our efforts to nurture young talent. At this level, our approach to team and sports development is centered on a well-rounded blend of exploration, introduction to fundamental skills, and ensuring a positive introduction to the world of sports.'
          />
      <div className='right-gradient'>

            {/* <ServiceCoursel
                heading="4U Team & Sport Development"
                content="At NexGen Sports Association, we believe in fostering a love for sports from the earliest stages of development. The 4U team, focusing on athletes under the age of 4, represents a critical phase in our commitment to nurturing young talents. The approach to team and sport development at this level is characterized by a careful blend of exploration, fundamental skill introduction, and creating a positive introduction to the world of sports."
                serviceData={serviceData}
            /> */}
            <TextContent
                heading= "4U SPORTS DEVELOPMENT "
                subHeading=""
                // content={"Sports development at the 4U level focuses on gradually introducing children to physical activity in age-appropriate ways. The aim is to build a positive association with sports and lay the groundwork for future engagement. The program helps children develop motor skills, social interactions, and a love for sports. The goal is to provide a supportive and enriching environment to ensure that children's early experiences with sports are joyful and formative, setting the stage for a lifelong love of sports. The grassroots development journey aims to instill a lifelong passion for sports, nurture talent, and lay the foundation for future champions."}
                content={"The 4 U Sports development program focuses on gradually introducing children to physical activity in age-appropriate ways. The aim is to build a positive association with sports and lay the groundwork for future engagement. The program helps children develop motor skills, social interactions, and a love for sports. The goal is to provide a supportive and enriching environment to ensure that children's early experiences with sports are joyful and formative, setting the stage for a lifelong love of sports. The grassroots development journey aims to instill a lifelong passion for sports, nurture talent, and lay the foundation for future champions."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                img="/images/creatives/4u-text-1.webp"
                reviewContent={false}
                sublineIcon={true}
            />
            {/* <ServiceCoursel
                heading=""
                content=""
                subline={false}
                serviceData={serviceData}
            /> */}
            <TextCard
                heading=""
                content=""
                dropDown={false}
                cardContent={serviceData}
                subLine={false}
                marginTop={true}title
                isBottomSection={false}
                // divisonSize={6}
            />


            
         
        </div>

       
        <Footer/>
    </div>
    )
}

export default FourU