import React from 'react'
import "./RedPostBanner.css"

function RedPostBanner({
  subHeading="",
  heading='',
  content="",
  subLine=true,
  customTranslate=-80
}) {
  return (
    <div className='RedPostBanner-container '
      style={{transform:`translateY(${customTranslate}px)`}}
    >
        <div className='container'>
            <div className='box center-text text-center d-flex flex-column justify-content-center align-items-center py-5'>
                
                <h5 className='sub-heading mb-3' style={{color:"white"}}>{subHeading}</h5>
                <h3 className='body-heading ' style={{color:"white"}}>{heading}</h3>
                {subLine && <img src='/images/icons/white-double-subline.png' style={{maxWidth:"90%",marginTop:"2px",marginBottom:"2px"}}/>}
                <p className='body-paragraph w-75 mb-0' style={{color:"white"}}>{content}</p>
      
            </div>
        </div>
    </div>
  )
}

export default RedPostBanner