import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import NewsList from "../component/NewsList/NewsList"
import Footer from '../component/Footer/Footer'

function news() {
  return (
    <>
    
    <HeroBanner
        preSpanHeading=""
        Heading="LATEST NEWS & Updates"
        spanHeading=""
        subHeading=""
        Paragraph=""
        btnText=""
        bgImgLg="/images/creatives/blog-bg.jpg"
        bgImg="/images/creatives/blog-bg-mb.jpg"
        mobileText={false}
        darkBg={true}
        headingCenter={true}
        btn={false}
        socialLinks={false}
        serviceForm={false}
        isChevron={true}
        bannerMarginBottom={true}
    />
    
    <NewsList/>
  
    <Footer
      newsletterSection={false}
    />
    </>
  )
}

export default news