import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'

function Contact() {
    return (
        <div className="home-page">
          <HeroBanner 
            subHeading=""
            Heading="Get in touch with us!"
            nextLineSpanHeading=""
            paraWhite={true}
            // postSubHeadingRed={"Lorem Ipsum is simply dummy text of the printing"}
            // Paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining"
            btn={false}
            socialLinks={true}
            serviceForm={true}
            btnText=""
            bgImg="/images/creatives/contact-hero-bg.jpg"
            btnRef="/"
            bannerMarginBottom={true}
          />
          <Footer
            newsletterSection={false}
          />
    
    </div>
      )
}

export default Contact