import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import TextContent from '../component/TextContent/TextContent'
import ServiceCoursel from "../component/ServicesCarasoul/ServicesCarasoul"
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'
import TextCard from '../component/TextCard/TextCard'
const serviceData=[
    {
      "title": "Foundational Skill Refinement",
      "actualPara": "The 5U program's emphasis is on refining and expanding foundational motor skills introduced in earlier stages. Coaches facilitate activities that enhance agility, coordination, and basic sports-related movements, setting the stage for more structured play."
    },
    {
      "title": "Structured Play & Game Introduction",
      "actualPara": "The program introduces more structured play and simplified game formats suitable for the athletes' developmental stage. Young players participate in age-appropriate games that emphasize teamwork, spatial awareness, and a grasp of basic game rules."
    },
    {
      "title": "Sport-Specific Fundamentals",
      "actualPara": "The 5U program promotes a multi-sport approach while introducing specific sport fundamentals related to the chosen sports within the association. Child athletes start to explore the unique aspects of different sports, establishing a foundation of knowledge and skills that align with their interests."
    },
    {
      "title": "Team Dynamics & Social Development",
      "actualPara": "The emphasis on collaboration, communication, and sportsmanship becomes more obvious as team activities take center stage. Athletes develop essential social skills that extend beyond the sports arena by working together as a team."
    },
    {
      "title": "Individual Skill Progression",
      "actualPara": "Coaches closely monitor and encourage the progression of individual skills, recognizing the unique strengths and areas for improvement in each young athlete. Tailored skill development plans ensure that athletes progress at a pace aligned with their developmental needs."
    },
    {
      "title": "Positive Reinforcement & Encouragement",
      "actualPara": "Positive reinforcement is a crucial part of the 5U program, where coaches offer constructive feedback and support. A nurturing atmosphere is fostered to promote a positive attitude towards sports and personal development."
    },
    {
      "title": "Family Involvement",
      "actualPara": "We actively support family involvement during the 5U developmental stage, emphasizing the integral role of parents in fostering a collaborative atmosphere that extends beyond the field."
    }
  ]
  

function FiveU() {
    return (
        <div className="home-page">
          <HeroBanner 
            postSubHeading=""
            Heading="5U TSD"
            nextLineSpanHeading=""
            Paragraph=''
            btn={false}
            btnText=""
            bgImgLg="/images/creatives/fiveu-hero-bg-lg.jpg"
            bgImg="/images/creatives/fiveu-hero-bg-mb.webp"
            btnRef="/"
            mobileText={false}
          />
          <RedPostBanner
            heading='WE ARE COMMITTED TO PROGRESSIVE DEVELOPMENT'
            subLine={true}
            content='The transition to the 5U age group is children 3.5 to under 5  years old MAC marks a crucial phase in our commitment to the progressive development of young athletes. This developmental stage focuses on refining foundational skills, introducing structured play, and fostering a deeper understanding of teamwork within the context of sports.'
          />
      <div className='right-gradient'>
            <TextContent
                heading= "5U SPORTS DEVELOPMENT"
                subHeading=""
                content={"As child athletes progress through the 5U programs, they develop essential athletic skills and cultivate a love for sports, teamwork, and personal growth. The Multi-Sports Association of Canada (MAC) remains dedicated to providing a nurturing environment that sets the stage for a lifelong engagement with sports."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                img="/images/creatives/5u-text-1.webp"
                // img="/images/creatives/5u-text-0.1.jpg"
                reviewContent={false}
                sublineIcon={true}
            />

            {/* <ServiceCoursel
                heading=""
                content=""
                subline={false}
                serviceData={serviceData}
            /> */}

            <TextCard
                heading=""
                content=""
                dropDown={false}
                cardContent={serviceData}
                subLine={false}
                marginTop={true}title
                isBottomSection={false}
                // divisonSize={6}
            />
            
         
        </div>

       
        <Footer/>
    </div>
    )
}

export default FiveU