import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import TextContent from '../component/TextContent/TextContent'
import SeasonalLeagueText from '../component/SeasonalLeagueText/SeasonalLeagueText'
import EventText from '../component/EventText/EventText'
import { Helmet } from 'react-helmet'
import KeyFeatureText from "../component/KeyFeatureText/KeyFeatureText"
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'



const changingTabData = [
  {
      id:1,
      heading:"Duathlon",
      content:{
          img:"/images/creatives/key-feature-img-serv-0.1.jpg",
          title:"Duathlon Adventure for Kids!",
          para:"Join us for 8 weeks of fun and excitement as your child explores two awesome sports in our Duathlon program. This diverse experience is perfect for kids 10 and under who love to stay active and make new friends.",
          simpleTitleList:[
            {
                title:"Two Sports:",
                content:"Learn and play two different sports."
            },
            {
                title:"Eight Weeks:",
                content:"Enjoy two months of sporty fun!"
            },
          ]
      }
  },
  {
      id:2,
      heading:"Triathlon",
      content:{
          img:"/images/creatives/key-feature-img-serv-0.2.jpg",
          title:"Triathlon Fun for Young Athletes!",
          para:"Get ready for an action-packed 12 weeks with our TRIATHLON program perfect for kids 10 and under! This program offers a mix of three exciting sports, strength and conditioning, and agility training.",
          simpleTitleList:[
            {
              "title": "3 Sports",
              "content": "Discover three amazing sports."
            },
            {
              "title": "Speed",
              "content": "Get faster with proper technique."
            },
            {
              "title": "Agility Training",
              "content": "Improve your quickness and coordination."
            },
            {
              "title": "12 Weeks",
              "content": "Three months of non-stop fun!"
            }
          ]
          
      }
  },
  {
      id:3,
      heading:"Pentathlon",
      content:{
          img:"/images/creatives/key-feature-img-serv-0.3.jpg",
          title:"Pentathlon Extravaganza for Kids!",
          para:"Join our PENTATHLON program and experience 24 weeks of excitement with 5 different sports. This program is designed for kids 10 and under to improve strength, speed, and agility.",
          simpleTitleList:[
            {
              "title": "5 Sports",
              "content": "Experience five fantastic sports."
            },
            {
              "title": "Strength",
              "content": "Enhance your strength and fitness."
            },
            {
              "title": "Speed Development",
              "content": "Become faster and more agile."
            },
            {
              "title": "Agility Training",
              "content": "Improve your movements and reactions."
            },
            {
              "title": "24 Weeks",
              "content": "Six months of sporty adventures!"
            }
          ]
          

      }
  },
  {
      id:4,
      heading:"Heptathlon",
      content:{
          img:"/images/creatives/key-feature-img-serv-0.4.jpg",
          title:"Heptathlon Challenge for Kids!",
          para:"Our HEPTATHLON program spans over 32 weeks and is designed to introduce children aged 10 and under to seven exciting sports. The program focuses on developing strength, speed, and agility to provide a comprehensive fitness experience.",
          simpleTitleList:[
            {
              "title": "7 Sports",
              "content": "To enhance your child's athleticism."
            },
            {
              "title": "Strength and Conditioning",
              "content": "Improve your child's fitness and strength."
            },
            {
              "title": "Speed Development",
              "content": "Enhance your child's running speed."
            },
            {
              "title": "Agility Training",
              "content": "Help your child become quicker and more agile."
            },
            {
              "title": "The Program",
              "content": "Lasts for 32 weeks, offering eight months of fun and fitness!"
            }
          ]
          
      }
  },
  {
      id:5,
      heading:"Decathlon",
      content:{
          img:"/images/creatives/key-feature-img-serv-0.5.jpg",
          title:"Discover Decathlon Options and Adventures for Young Champions!",
          para:"The DECATHLON program is the Ultimate Sports Experience! Join our year-long DECATHLON program to master 10 sports while building strength, speed, and agility. Perfect for kids 10 and under, this program promises 40 weeks of fun and fitness.",
          simpleTitleList:[
            {
              "title": "10 Sports",
              "content": "Experience ten different sports."
            },
            {
              "title": "Strength and Conditioning",
              "content": "Get fit and strong."
            },
            {
              "title": "Speed Development",
              "content": "Run faster and better."
            },
            {
              "title": "Agility Training",
              "content": "Improve your quickness and coordination."
            },
            {
              "title": "Over 40 Weeks",
              "content": "One year of amazing sports adventures!"
            }
          ],
          titleList:true,
          titleListContent:[
            {
              "title": "School Program",
              "content": "Participate during school hours."
            },
            {
              "title": "After School Program",
              "content": "Join in after school."
            }
          ]
          
          
      }
  },
 

]
const changingTabDataEvents = [
  {
    id:1,
    heading:"GAMES",
    content:{
        img:"/images/creatives/key-feature-img-serveve-5-new-n.webp",
        title:"SUMMER & WINTER GAMES",
        para:"Twice a year, the MAC hosts the NexGEN Games, an event that emulates the spirit of the Winter and Summer Olympic Games. The NexGEN Games serve as a platform for kids to exhibit progress and compete in various sports. We invite you to an unforgettable experience featuring opening and closing ceremonies, multi-sports events, awards ceremonies, ancillary events, raffles, and more.",
        
    }
},
{
  id:2,
  heading:"SHOWCASES",
  content:{
      img:"/images/creatives/key-feature-img-serveve-5-new.webp",
      title:"SHOWCASES",
      para:"MAC Showcase Events are hosted at various times throughout the year where young athletes showcase their talents to family and friends. Young children always get excited to show-and-tell their skills they have learned through the MAC programs.",
     
  }
},
{
  id:3,
  heading:"SKILL DEVELOPMENT",
  content:{
      img:"/images/creatives/key-feature-img-serveve-3.webp",
      title:"SKILL DEVELOPMENT CLINICS",
      para:"Complementing the competitive aspect of the leagues, the Association offers skill development clinics. The clinics provide participants with additional opportunities to enhance their abilities, receive coaching, and further develop their understanding of the sport.",
    

  }
},
{
  id:4,
  heading:"WORKSHOPS",
  content:{
      img:"/images/creatives/key-feature-img-serveve-2-new.webp",
      title:"WORKSHOPS",
      para:"Sports workshops  will guide families in the areas of nutrition, training, mental health, injury prevention, balancing academics and athletics, parental support, and understanding sports development stages for young athletes. These aim to equip parents with knowledge and tools to foster a supportive environment for their children's athletic development.",
      
  }
},
  {
      id:5,
      heading:"SPORT TALKS",
      content:{
          img:"/images/creatives/key-feature-img-serveve-1-new-n.webp",
          title:"SPORT TALKS",
          para:"The MAC hosts SPORTS TALKS for families, covering a wide range of topics, from sports development, elite athlete support, sports nutrition to faith-centered discussions. ",
          
      }
  },
 
  
  
 
 

]
const changingTabDataHouseLeague = [
  {
      "id": 1,
      "heading": "Sports",
      "content": {
          "img": "/images/creatives/key-feature-img-servhl-1.webp",
          "title": "Sports",
          "para": "The House League covers various sports, allowing participants to explore and engage in multiple athletic activities. This diversity caters to different interests and encourages cross-sport participation, promoting a well-rounded approach to physical fitness."
      }
  },
  {
      "id": 2,
      "heading": "Seasons",
      "content": {
          "img": "/images/creatives/key-feature-img-servhl-2.webp",
          "title": "Seasons",
          "para": "Each sport within the MAC follows a structured seasonal format. This typically includes regular-season games, playoffs, and championship events. The structured nature of the seasons adds an element of excitement and progression for participants."
      }
  },
  {
      "id": 3,
      "heading": "Divisions",
      "content": {
          "img": "/images/creatives/key-feature-img-servhl-3.webp",
          "title": "Divisions",
          "para": "The leagues often feature age and skill-based divisions to ensure fair competition and a positive participant experience. This approach allows individuals to compete against others with similar abilities, promoting a balanced and competitive environment."
      }
  },
  {
      "id": 4,
      "heading": "Inclusive",
      "content": {
          "img": "/images/creatives/key-feature-img-servhl-4.webp",
          "title": "Inclusive",
          "para": "The seasonal leagues prioritize inclusivity, welcoming participants of all skill levels and backgrounds. Whether someone is a seasoned athlete or a beginner, the leagues provide an opportunity for everyone to join, fostering a sense of belonging within the sports community."
      }
  },
  {
      "id": 5,
      "heading": "Sportsmanship",
      "content": {
          "img": "/images/creatives/key-feature-img-servhl-5.webp",
          "title": "Sportsmanship",
          "para": "Beyond the competitive aspect, the Association strongly emphasizes sportsmanship within the seasonal leagues. Participants are encouraged to uphold ethical behavior, respect opponents, and demonstrate integrity both on and off the field, aligning with the Association's overall values."
      }
  },
  {
      "id": 6,
      "heading": "Community",
      "content": {
          "img": "/images/creatives/key-feature-img-servhl-6.webp",
          "title": "Community",
          "para": "Throughout the seasons, the Association organizes community-building events related to the leagues. This may include team-building activities, social gatherings, and awards ceremonies, creating opportunities for participants to connect beyond the confines of the sports competitions."
      }
  },
  {
      "id": 7,
      "heading": "Spectators",
      "content": {
          "img": "/images/creatives/key-feature-img-serveve-4-new.webp",
          "title": "Spectators",
          "para": "The MAC recognizes the importance of involving families and spectators in the seasonal leagues. Events may be organized to encourage family participation, and facilities are often designed to accommodate a supportive and enthusiastic audience."
      }
  }
]

function What() {
    return (
        <div className="home-page">
           <Helmet>
            <title>
            Fostering Champions: Comprehensive Athletic Journey for Tomorrow's Stars
            </title>
            <meta
              name="description"
              content="Unlock young athletes' potential with our programs, offering skill development, game-time experience, and seasonal leagues. Join us for 2 sessions per week!"
            ></meta>
          </Helmet>
          <HeroBanner 
            subHeading=""
            Heading="WHAT WE OFFER"
            nextLineSpanHeading=""
            Paragraph="Fostering Champions"
            btn={false}
            btnText=""
            bgImg="/images/creatives/what-hero-bg-mb.webp"
            bgImgLg={"/images/creatives/what-hero-bg.jpg"}
            btnRef="/"
            mobileText={false}
            bgSmallScreenWidth={true}
          />
          <RedPostBanner
            heading='A COMPREHENSIVE ATHLETIC JOURNEY '
            content="Our programs are designed to catalyze young athletes' journey to success. The House League offers two weekly sessions, balancing skill development and game-time experience. With one practice session and one competitive play session, athletes can hone their skills under the guidance of experienced coaches and put them to the test in a competitive yet supportive environment."
          />
      <div className='right-gradient'>
            {/* <TextContent
                heading= "A Comprehensive Athletic Journey for Tomorrow's Stars!"
                subHeading=""
                content={"Our programs are designed to be the catalyst for young athletes' journeys to success. The House League offers two sessions per week, providing a balance of skill development and game-time experience. With one practice session and one competitive play session, athletes have the opportunity to hone their skills under the guidance of experienced coaches and put them to the test in a competitive yet supportive environment. 2 Sessions Per Week, 1 Practice, 1 Competitive Play"}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={true}
                img="/images/creatives/wwo-text-1.webp"
                reviewContent={false}
                sublineIcon={true}
            /> */}
             <KeyFeatureText
              heading='Programs'
              dataArray={changingTabData}
              marginBottom={true}
             />

            <TextContent
                heading= "Fast Tracking Classes"
                subHeading=""
                content={"The  MAC recognizes that nurturing exceptional young athletes requires extra time and effort. The MAC offers a supportive environment for children who aspire to excel and improve significantly. An all-encompassing approach to training, development, and support is vital to accelerate their progress. Evaluating young athletes is essential for creating customized training programs, monitoring progress, preventing injuries, building mental resilience, and optimizing performance. At The MAC Fast-tracking athletic development through customized programs offers numerous benefits for children aiming to enhance their skills rapidly. The combination of, personalized coaching, and focused skill development enables young athletes to progress while receiving the necessary attention to detail from coaches."}
                btn={false}
                btnRefPhone={true}
                btnText="LET'S CONNECT"
                btnRef='/'
                shiftRow={false}
                // img="/images/creatives/what-text-0.1.png"
                img="/images/creatives/what-text-0.3.png"
                reviewContent={false}
                sublineIcon={true}
            />
            <KeyFeatureText
            heading="EVENTS "
              dataArray={changingTabDataEvents}
              marginBottom={true}
             />
            {/* <TextContent
                heading= "Events"
                subHeading=""
                content={"Our programs are designed to be the catalyst for young athletes' journeys to success. The House League offers two sessions per week, providing a balance of skill development and game-time experience. With one practice session and one competitive play session, athletes have the opportunity to hone their skills under the guidance of experienced coaches and put them to the test in a competitive yet supportive environment. 2 Sessions Per Week, 1 Practice, 1 Competitive Play"}
                btn={false}
                btnRefPhone={true}
                btnText="LET'S CONNECT"
                btnRef='/'
                shiftRow={true}
                img="/images/creatives/what-text-0.2.png"
                reviewContent={false}
                sublineIcon={true}
            /> */}
            <TextContent
                heading= "SPORTS TESTING"
                subHeading=""
                content={"Testing young athletes provides valuable insights into their abilities and potential. It helps identify talent, customize training programs, monitor progress, and develop mental toughness.\n At The  MAC, we understand the importance of a comprehensive approach to sports development. Our programs go beyond the conventional, instilling a love for the game, fostering camaraderie, and laying the groundwork for future achievements. Join us at MAC and embark on a journey where every child is a champion in the making!"}
                btn={false}
                btnRefPhone={true}
                btnText="LET'S CONNECT"
                btnRef='/'
                shiftRow={false}
                img="/images/creatives/what-text-0.5.png"
                reviewContent={false}
                sublineIcon={true}
            />

        {/* <SeasonalLeagueText/> */}
         <div className='container pt-5 pb-0'>
            <div className='text-content text-center d-flex flex-column align-items-center justify-content-center'>
                <h3 className='body-heading'>House Leagues</h3>
                <p className='body-paragraph mb-0'>The Association introduces a dynamic and engaging aspect to its sports offerings by implementing seasonal leagues. These leagues are designed to provide participants with a structured and competitive platform, fostering a sense of community, sportsmanship, and skill development. Here are key features of the seasonal leagues within the Association.</p>
            </div>
        </div>
        <KeyFeatureText
          heading=""
          dataArray={changingTabDataHouseLeague}
        />
        </div>
       
        <Footer/>
    </div>
  )
}

export default What