import React from 'react'
import "./VisionMission.css"

function VisionMission() {
  return (
    <div className='VisionMission-container standard-padding-space'>
        <div className='container'>
            <div className='row gy-5'>
                <div className='col-lg-6'>
                    <div className='row h-100 '>
                        <div className='col-12' style={{paddingBottom:"35px"}}>
                            <div className='box'>
                                <div className='heading d-flex align-items-center gap-3'>
                                    <img src='/images/icons/mission_icon.svg' />
                                    <h3 className='body-heading mb-0'>Mission</h3>
                                </div>
                                <p className='body-paragraph my-4'>
                                Our mission is to empower young athletes with the skills, knowledge, and character needed to excel in sports and life. We do this in an inclusive environment that welcomes all and fosters holistic development and a passion for sports.
                                </p>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='box'>
                                <div className='heading d-flex align-items-center gap-3'>
                                    <img src='/images/icons/vission_icon.svg' />
                                    <h3 className='body-heading mb-0'>Vision</h3>
                                </div>
                                <p className='body-paragraph my-4'>
                                We are striving for excellence in coaching, training, and program development. We inspire our athletes to achievable goals and pursue continuous improvement.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='box'>
                        <div className='heading d-flex align-items-center gap-3'>
                            <img src='/images/icons/value_icon.svg' />
                            <h3 className='body-heading mb-0'>Values</h3>
                        </div>
                        <p className='body-paragraph my-3'>
                            <sapn className="emp-text">Excellence:</sapn> We are striving for excellence in coaching, training, and program development. 
                        </p>

                        <p className='body-paragraph my-3'>
                            <sapn className="emp-text">Diversity and Inclusion:</sapn> The diversity of our athletes and coaches enriches our community. We promote an inclusive environment with equal opportunities for all.
                        </p>

                        <p className='body-paragraph my-3'>
                            <sapn className="emp-text">Integrity:</sapn> We believe in always being honest, fair, and doing the right thing. We know that playing sports helps build good character and strong values, and that's what guides everything we do.
                        </p>

                        <p className='body-paragraph my-3'>
                            <sapn className="emp-text">Teamwork:</sapn> Our philosophy emphasizes collaboration, communication, and mutual support among coaches, parents, and athletes. Together, we help each child achieve success as individuals and as a team
                        </p>

                        <p className='body-paragraph my-3'>
                            <sapn className="emp-text">Lifelong Learning:</sapn>  We are dedicated to promoting a passion for learning among our athletes. In addition to sports skills, we foster intellectual curiosity, adaptability, and a growth mindset. The journey of self-improvement extends well beyond their playing years.
                        </p>

                        <p className='body-paragraph my-3'>
                            <sapn className="emp-text">Community Engagement:</sapn> We are more than an association; we are a community actively engaging and supporting initiatives for sports, education, and well-being, aiming to make a positive impact on society.
                        </p>
                        <p className='body-paragraph my-3'>
                            Join us at <b>The MAC</b> where our mission, vision, and values shape a vibrant community dedicated to nurturing the champions of tomorrow!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default VisionMission