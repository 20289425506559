import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import ServiceCoursel from "../component/ServicesCarasoul/ServicesCarasoul"
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'
import TextCard from '../component/TextCard/TextCard'
const serviceData=[
    {
        title:"Foundational Skill Emphasis",
        actualPara:"We prioritize establishing strong foundational skills early on. The Spiral Approach enables athletes to revisit and reinforce these fundamentals regularly, building a solid base for advanced skill acquisition.",
    },
    {
        title:"Age-Appropriate Challenges",
        actualPara:"The association's approach considers the developmental needs of young athletes, with training programs tailored to each child's age and cognitive abilities to optimize learning and growth.",
    },
    {
        title:"Multi-Sport Exposure",
        actualPara:"Our programs promote participation in multiple sports, fostering versatility and reinforcing skills through the Spiral Approach.",
    },
    

    {
        title:"Individualized Progression",
        actualPara:"At MAC, we understand that each young athlete is unique. That's why we ensure that every child progresses at their own pace within the Spiral Approach. This individualized progression guarantees that no child is left behind, and every participant receives the attention and support necessary for their unique developmental journey.",
    },
    {
        title:"Incorporation of Play & Fun",
        actualPara:"At the Heart of The MAC's approach is the integration of play and enjoyment in the learning process. A positive and fun environment enhances the learning experience, motivating young athletes to actively engage in their development.",
    },
]

function UniqueApproach() {
    return (
        <div className="home-page">
          <HeroBanner 
            postSubHeading=""
            Heading="Unique Approach"
            nextLineSpanHeading=""
            Paragraph=''
            btn={false}
            btnText=""
            bgImgLg="/images/creatives/unique-hero-bg-lg.jpg"
            bgImg="/images/creatives/unique-hero-bg-mb.webp"
            btnRef="/"
            mobileText={false}
            customHeight={"h-55"}
          />
          <RedPostBanner
           heading='BUILDING A FOUNDATION FOR A LIFETIME '
           subLine={true}
           content={`The Multi-Sport Association Clubs of Canada (MAC) uses a unique Spiral Approach to Learning to instill a love for sports and prepare children aged 12 and under for future success. The "Spiral Approach to Learning" represents MAC's commitment to ongoing improvement and growth. This model acknowledges the cyclical nature of skill development, moving away from the typical linear progressions seen in traditional approaches. It ensures a well-rounded sports development journey, establishing the foundation for a lifetime of athletic accomplishments and personal growth. MAC's dedication to the Spiral Approach demonstrates its commitment to nurturing well-rounded athletes through a structured yet adaptable framework, catering to individual learning curves and encouraging a lifelong passion for sports.`}
            />
      <div className='right-gradient'>

        <div className='unique-centered-text'>
            <div className=' container standard-padding-space pt-0 pt-lg-auto'>
                <div className='text-content text-center d-flex flex-column justify-content-center align-items-center mt-3'>
                    
                    <h3 className='body-heading px-3'>Critical Elements of the Spiral Approach</h3>
                    <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"4px",marginBottom:"4px"}}/>
                    {/* <p className='body-paragraph w-75 my-2'>The Multi-Sport Association Clubs of Canada (MAC) uses a unique Spiral Approach to Learning to instill a love for sports and prepare children aged 12 and under for future success. The "Spiral Approach to Learning" represents MAC's commitment to ongoing improvement and growth. This model acknowledges the cyclical nature of skill development, moving away from the typical linear progressions seen in traditional approaches. It ensures a well-rounded sports development journey, establishing the foundation for a lifetime of athletic accomplishments and personal growth. MAC's dedication to the Spiral Approach demonstrates its commitment to nurturing well-rounded athletes through a structured yet adaptable framework, catering to individual learning curves and encouraging a lifelong passion for sports.</p> */}
                    

                </div>
            </div>
        </div>

            {/* <ServiceCoursel
                heading="Critical Elements of the Spiral Approach"
                content=""
                serviceData={serviceData}
            /> */}
            <TextCard
             heading=""
             content=""
             dropDown={false}
             cardContent={serviceData}
             subLine={false}
             marginTop={true}
             removeUpperSpace={true}
             isBottomSection={false}
             divisonSize={6}
          />

        {/* <div className='unique-centered-text'>
            <div className=' container standard-padding-space'>
                <div className='text-content text-center d-flex flex-column justify-content-center align-items-center my-3'>
                    
                    <h3 className='body-heading px-3'>Outcome</h3>
                    <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"4px",marginBottom:"4px"}}/>
                    <p className='body-paragraph w-75 my-2'>The Multi-Sports Association of Canada (MAC), unique approach to developing young athletes not only instills a love for sports but also lays the groundwork for future success. The Spiral Approach to Learning ensures that every child, aged 12 and under, experiences a dynamic and well-rounded development journey, setting the stage for a lifetime of athletic achievement and personal growth.</p>
                    
                </div>
            </div>
        </div> */}
         
        </div>

       
        <Footer/>
    </div>
    )
}

export default UniqueApproach