import React from 'react'
import "./LtadBanner.css"

function LtadBanner() {
  return (
    <div className='LtadBanner-container '>
        <div className='container'>
            <div className='box center-text text-center d-flex flex-column justify-content-center align-items-center py-5'>
                
                {/* <h5 className='sub-heading mb-3' style={{color:"white"}}>UNIQUE APPROACH TO DEVELOPING YOUNG TALENT</h5> */}
                <h3 className='body-heading ' style={{color:"white"}}>Unique Approach to Developing Young Athletes</h3>
                <img src='/images/icons/white-double-subline.png' style={{maxWidth:"90%",marginTop:"2px",marginBottom:"2px"}}/>
                <p className='body-paragraph w-75 mb-0' style={{color:"white"}}>The Multi-Sport Association Clubs of Canada (MAC) adopts the Long-Term Athlete Development (LTAD) framework. LTAD is aimed at nurturing the potential of multi-sport athletes from an early age to elite levels in a holistic manner. It is a guiding principle that acknowledges the importance of careful planning and support to achieve athletic excellence.</p>
      
            </div>
        </div>
    </div>
  )
}

export default LtadBanner