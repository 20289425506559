import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import TextContent from "../component/TextContent/TextContent"
import KeyFeatureText from "../component/KeyFeatureText/KeyFeatureText"
import DropDownContent from '../component/DropDownContent/DropDownContent'
import { Helmet } from 'react-helmet'
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'
import TextCard from '../component/TextCard/TextCard'

const changingTabData = [
  {
      id:1,
      heading:"Inclusive Participation",
      content:{
          img:"/images/creatives/key-feature-img-hl-0.2.jpg",
         "title": "Inclusive Participation",
        "para": "House Leagues at MAC are designed to be inclusive and welcome children of all skill levels. Whether a beginner or a budding athlete, every child has the opportunity to participate, fostering a sense of belonging and camaraderie."
      }
  },
  {
      id:2,
      heading:"Skill Development Sessions",
      content:{
          img:"/images/creatives/key-feature-img-hl-0.1.jpg",
           "title": "Skill Development Sessions",
      "para": "The House Leagues integrate skill development sessions into the program. Before games, participants engage in age-appropriate training sessions, aligning with The MAC Spiral Approach to Learning. This approach ensures that foundational skills are continually reinforced and built upon."
      }
  },
  {
      id:3,
      heading:"Rotational Multi-Sport Experience",
      content:{
          img:"/images/creatives/key-feature-img-hl-0.3.jpg",
          "title": "Rotational Multi-Sport Experience",
        "para": "MAC's unique approach to House Leagues involves a rotational multi-sport experience. Participants can explore different sports throughout the season, contributing to a well-rounded skill set and promoting versatility."
      }
  },
  {
      id:4,
      heading:"Balanced Competition",
      content:{
          img:"/images/creatives/key-feature-img-hl-0.4.jpg",
           "title": "Balanced Competition",
      "para": "House Leagues prioritize balanced competition, emphasizing the spirit of teamwork and fair play. They focus on creating an environment where young athletes can test their skills, learn from victories and challenges, and develop a healthy competitive mindset."
      }
  },
  {
      id:5,
      heading:"Emphasis on Fun & Enjoyment",
      content:{
          img:"/images/creatives/key-feature-img-hl-0.5.jpg",
"title": "Emphasis on Fun & Enjoyment",
      "para": "At The MAC, we understand the importance of fostering a positive relationship with sports at a young age. House Leagues are infused with fun and enjoyment, ensuring that kids develop their athletic abilities and build a lifelong love for sports."
      }
  },
 

]


const cardContent = [
  {
    "title": "Spiral Skill Integration",
    "actualPara": "The Spiral Approach to Learning is seamlessly integrated into Skill building sessions. Each session revisits and reinforces fundamental skills, ensuring a continuous and progressive development journey for every participant."
  },
  {
    "title": "Personal Attention Coaching",
    "actualPara": "Our coaches adopt an individualized coaching approach within the skill-building sessions, recognizing the diverse needs of young athletes. This personalized attention empowers each child to thrive at their own pace, fostering confidence and a sense of accomplishment."
  },
  {
    "title": "Skill Application in Game Settings",
    "actualPara": "House League games are platforms for applying learned skills in real-game settings. This practical application enhances the learning experience, allowing athletes to understand the relevance of their training and reinforcing the joy of competition."
  },
  {
    "title": "Parental Involvement",
    "actualPara": "The MAC encourages parental involvement in House Leagues. Parents play a vital role in supporting their child's journey, creating a collaborative and nurturing community beyond the playing field."
  }
]
  

function HouseLeagues() {
    return (
        <div className="home-page">
           <Helmet>
            <title>
            NexGen Multi Sports: House Leagues for Kids | NXGMSA
            </title>
            <meta
              name="description"
              content="Join NexGen Multi Sports inclusive House Leagues for kids aged 12 and under. Fostering competition and skill mastery in a supportive environment."
            ></meta>
          </Helmet>
          <HeroBanner 
            subHeading=""
            Heading="HOUSE LEAGUE"
            nextLineSpanHeading=""
            Paragraph="Fusing Competition & Skill Mastery in the Sports Arena"
            btn={false}
            btnText=""
            bgImgLg="/images/creatives/house-league-bg-lg.jpg"
            bgImg="/images/creatives/house-league-bg-mb.webp"
            btnRef="/"
            mobileText={false}
            bgSmallScreenWidth={true}
          />
          <RedPostBanner
            heading='Competition & Skill Mastery'
            content='The  Multi-Sports Association of Canada (MAC) House Leagues for MAC Members only represent the pinnacle of our commitment to fostering a love for sports while honing fundamental skills. With our unique development approach, the MAC House League provides an engaging and supportive environment where young athletes compete and grow as individuals.'
          />
        <div className='right-gradient'>
            {/* <TextContent
                heading= "Fusing Competition & Skill Mastery in the Sports Arena"
                subHeading=""
                content={"The NexGen Multi Sports Association’s (NXGMSA) House Leagues for kids aged 12 and under represent the pinnacle of our commitment to fostering a love for sports while honing fundamental skills. With our unique approach, the House Leagues provide an engaging and supportive environment where young athletes not only compete but also grow as individuals."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                // img="/images/creatives/hl-text-1.webp"
                img="/images/creatives/hl-text-0.1.jpg"
                reviewContent={false}
            /> */}

            <KeyFeatureText
              heading='Key Features of The MAC House Leagues'
              dataArray={changingTabData}
              removeUpperSpace={true}
            />
        </div>
        {/* <DropDownContent
            img="/images/creatives/dropDownImage-h1-0.3.jpg"
            mobileTextCenter={false}
        /> */}
         <TextCard
             heading="How The MAC Unique Approach Works in House League"
             content=""
             dropDown={false}
             cardContent={cardContent}
             subLine={true}
             marginTop={true}
             removeUpperSpace={true}
             isBottomSection={false}
             divisonSize={6}
          />

        <Footer/>
    </div>
      )
}

export default HouseLeagues