import React, { useState } from 'react'
import "./KeyFeatureText.css"
import TextContent from '../TextContent/TextContent'



function SeasonalLeagueText({
    heading="Key Features of The NexGen Multi Sports Association House Leagues",
    dataArray,
    removeUpperSpace=false,
    marginBottom=false
}) {
    const [curElement , setCurElement] = useState(0)
  return (
    <div className={`KeyFeatureText-container standard-padding-space ${removeUpperSpace && "pt-0"} ${marginBottom && "pb-0"}`}>
        <div className='container'>
            <div className='text-content text-center d-flex flex-column align-items-center justify-content-center'>
                <h3 className='body-heading mx-auto' style={{width:"80%"}}>{heading}</h3>
                
            </div>
            <div className='d-flex flex-column flex-xxl-row main-box my-4 justify-content-center'>

                    {
                        dataArray.map((ele,ind)=>{
                            return(
                                <div 
                                    className={`w-100 tab-box ${curElement === ind && "active-tab"} `}
                                    key={ele.id}
                                    onClick={()=>{setCurElement(ind)}}
                                >
                                    <h4 
                                        className={`body-heading mb-0 mx-auto ${curElement === ind && "active-title"} `} 
                                        >{ele.heading}</h4>
                                </div>
                            )
                        })
                    }
                </div>
                    

                    <TextContent
                         heading={dataArray[curElement].content.title}
                         subHeading=""
                         content={dataArray[curElement].content.para}
                         titleList={dataArray[curElement].content?.titleList}
                         titleListContent={dataArray[curElement].content.titleListContent}
                         simpleTitleList={dataArray[curElement].content?.simpleTitleList}
                         btn={false}
                         btnText=""
                         btnRef='/'
                         shiftRow={true}
                         sublineIcon={false}
                         img={dataArray[curElement].content.img}
                         reviewContent={false}
                         spaces={false}
                    />
                   
        </div>
    </div>
  )
}

export default SeasonalLeagueText