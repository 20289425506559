import React from 'react'
import "./BlogDetails.css"
import PopularBlogCard from "./PopularBlogCard"
import { useNavigate } from 'react-router-dom'
const popularBlogDate = [
    {img:'/images/creatives/blog-thumb-1.png', title:"Lorem Ipsum is simply dummy text lorem",date:"Apr 10, 2023",anchor:"/newsinner"},
    {img:'/images/creatives/blog-thumb-2.png', title:"Lorem Ipsum is simply dummy text lorem",date:"Apr 10, 2023",anchor:"/newsinner"},
    {img:'/images/creatives/blog-thumb-3.png', title:"Lorem Ipsum is simply dummy text lorem",date:"Apr 10, 2023",anchor:"/newsinner"},
]

function BlogDetails({ text, blogsData, datePublished,recentBlogs }) {
    const navigate= useNavigate()


    const convertToSlug = (str) => {
        return str.toLowerCase().replace(/\s+/g, "-");
      };

    const redirectUserToBlog = (id) => {
        if (!id) return;
        let slug = convertToSlug(id);
    
        navigate(`/blog/${slug}`);
      };


  return (
    <div className='blog-details-container pb-5'>
        <div className='container py-5 '>
            <div className='row d-flex flex-row align-items-start '>
                
            <div className='col-md-7'>
            <div className='text-content'>
                {/* <h4 className='main-content-title content-title my-2'>Changes in Express Entry: <span className='color-yellow'>No Upfront Medical Examination Required</span></h4> */}
                {/* <div className='blog-info py-4'>
                    <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-start gap-3'>
                        <div className='blog-info-field d-flex gap-1'>
                            <img src='/images/icons/blog-mark.svg'/>
                            <p className='mb-0'>Lorem Ipsum</p>
                        </div>
                        <div className='blog-info-field d-flex gap-1'>
                            <img src='/images/icons/blog-comment.svg'/>
                            <p className='mb-0'>02 Comment</p>
                        </div>
                        <div className='blog-info-field d-flex gap-1'>
                            <img src='/images/icons/blog-like.svg'/>
                            <p className='mb-0'>12k Likes</p>
                        </div>

                    </div>

                </div> */}
                <p className='body-paragraph '>{text}</p>
                {blogsData.map((item) => (
                <>
                    <h2 className="content-title my-3 mt-4" style={{textTransform:"uppercase"}}>{item.heading}</h2>
                    <div className="body-paragraph" dangerouslySetInnerHTML={{ __html: item.paragraph }}></div>
                </>
                ))}
               
           </div>
           <div className='bottom d-flex flex-column gap-4 flex-md-row align-items-center align-items-md-end justify-content-between w-100 py-4'>
                <div className='social'>
                    <h5 className='' style={{fontWeight:"bold",color:"white"}}>Share:</h5>
                    {/* <div className='social-icon-box d-flex align-items-center justify-content-start'>
                    <a ><img src='/images/icons/facebook-inner-icon.svg' className='social-icon'/></a>
                        <img src='/images/icons/linked-inner-icon.svg' className='social-icon'/>
                        <img src='/images/icons/twitter-inner-icon.svg' className='social-icon'/>
                        <img src='/images/icons/mail-inner-icon.svg' className='social-icon'/>
                        <img src='/images/icons/pintrest-inner-icon.svg' className='social-icon'/>
                    </div> */}
                </div>
                <div className='date-container'>
                    <p className='m-0' style={{color:"#9F9F9F"}}><span style={{color:"dark-grey",fontSize:"1.1rem"}}>Published Date:</span > {datePublished}</p>
                </div>
           </div>
        <div className='line-seprator'></div>
        </div>
        <div className='col-md-5 pt-md-0 pt-5'>
                    <h3 className='body-heading text-md-start text-center mb-4'>Most Popular</h3>

                    <div className='popular-blog-container p-3 px-4'>
                    {
                        recentBlogs.map((blog,ind)=>{
                            return(
                                <>
                                    <PopularBlogCard
                                        img={blog.blog_creative}
                                        title={blog.main_heading}
                                        date={blog.date}
                                        anchor={blog.slug_url}
                                        redirectionFunction={redirectUserToBlog}
                                    />
                                    {ind!==recentBlogs.length-1 && <div className='line-seprator'></div>}
                                </>
                            )
                        })
                    }
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default BlogDetails