import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import TextContent from '../component/TextContent/TextContent'
import GrassRootText from '../component/GrassRootText/GrassRootText'
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'
import TextCard from '../component/TextCard/TextCard'

const listData = [
  "Expanding our outreach efforts",
"Introducing new training methods",
"Incorporating technology to enhance the overall development experience for young athletes."
]

const cardData = [
    {
        title:"Early Engagement and Exposure",
        actualPara:"Our grassroots development program starts with early engagement, introducing children to various sports. This early exposure is crucial for discovering and nurturing inherent talents, allowing each child to explore and find their passion within the sporting world.",
        icon:"/images/icons/num-1.svg"
    },
    
    {
        title:"Qualified Coaching Expertise",
        actualPara:"The success of our grassroots development program is fueled by the expertise of our qualified coaches. These mentors are passionate advocates for youth development. They employ age-appropriate coaching methodologies and embrace a player-centric approach to ensure the holistic growth of each participant.",
        icon:"/images/icons/num-3.svg"
    },
    {
        title:"Multi-Sport Exposure",
        actualPara:"We believe in the benefits of exposing children to multiple sports at the grassroots level. Our programs encourage participation in various sports, allowing children to discover their interests and develop a diverse skill set. This approach enhances physical literacy and contributes to overall athletic versatility.",
        icon:"/images/icons/num-5.svg"
    },
    {
        title:"Inclusive Participation",
        actualPara:"The Association emphasizes inclusivity, making sports accessible to all children regardless of background or skill level. Everyone deserves the opportunity to experience the transformative power of sports.",
        icon:"/images/icons/num-2.png"
    },
    {
        title:"Structured Skill Development",
        actualPara:"The program prioritizes structured skill development. Through carefully designed training modules, young athletes undergo a progression of skill acquisition aligned with their age and developmental stage. This structured approach, which incorporates the Spiral Approach to Learning, ensures a comprehensive and sustainable development journey.",
        icon:"/images/icons/num-4.svg"
    },
   
    {
            title:"Parental Involvement",
            actualPara:"Recognizing parents' pivotal role in a child's development, the Multi-Sports Association of Canada (MAC) encourages active parental involvement in grassroots programs. Workshops, informational sessions, and collaborative events create a supportive ecosystem where families contribute to the growth and success of young athletes.",
        icon:"/images/icons/num-6.svg"
    },
]

function GrassRootDevMain() {
    return (
        <div className="home-page">
          <HeroBanner 
            postSubHeading="Nurturing Tomorrow's Champions!"
            Heading="GRASSROOTS DEVELOPMENT"
            nextLineSpanHeading=""
            Paragraph='Our grassroots development program stands as the bedrock of our commitment to fostering a passion for sports and cultivating the champions of tomorrow. The term "grassroots" reflects our dedication to building a strong foundation at the grassroots level, ensuring that young athletes receive the support and resources needed to embark on a lifelong journey of athletic achievement.'
            btn={false}
            btnText=""
            bgImgLg="/images/creatives/grassDev-hero-bg-lg.jpg"
            bgImg="/images/creatives/grassDev-hero-bg-mb.webp"
            btnRef="/"
            mobileText={false}
            customHeight={"h-58"}
          />
          <RedPostBanner
          heading='WE ARE COMMITTED TO BUILDING A SOLID FOUNDATION '
          subLine={true}
            content='Our grassroots development program is the foundation of our commitment to nurturing a love for sports and developing the champions of tomorrow. The term "grassroots" reflects our dedication to building a solid foundation at the younger stages of development-- ensuring that young athletes receive the support and resources they need to embark on a lifelong journey of athletic achievement.'
          />
      <div className='right-gradient'>

            {/* <GrassRootText/> */}
            <TextCard
             heading=""
             content=""
             dropDown={false}
             cardContent={cardData}
             subLine={false}
             marginTop={true}
             removeUpperSpace={true}
             isBottomSection={false}
            //  divisonSize={6}
          />

            <TextContent
                heading= "Impact & Future Goals"
                subHeading=""
                content={"The MAC bases the success of our grassroots development program on the participants' athletic abilities and their positive influence on personal growth. \n Our future objectives include:"}
                simpleList={listData}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                img="/images/creatives/grd-text-0.1.png"
                reviewContent={false}
                sublineIcon={true}
            />
         
        </div>

       
        <Footer/>
    </div>
    )
}

export default GrassRootDevMain