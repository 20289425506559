import React from 'react'
import "./GrassRootText.css"

const leftTextData = [
    {
        title:"Early Engagement and Exposure",
        content:"Our grassroots development program starts with early engagement, introducing children to various sports. This early exposure is crucial for discovering and nurturing inherent talents, allowing each child to explore and find their passion within the sporting world.",
        icon:"/images/icons/num-1.svg"
    },
    
    {
        title:"Qualified Coaching Expertise",
        content:"The success of our grassroots development program is fueled by the expertise of our qualified coaches. These mentors are passionate advocates for youth development. They employ age-appropriate coaching methodologies and embrace a player-centric approach to ensure the holistic growth of each participant.",
        icon:"/images/icons/num-3.svg"
    },
    {
        title:"Multi-Sport Exposure",
        content:"We believe in the benefits of exposing children to multiple sports at the grassroots level. Our programs encourage participation in various sports, allowing children to discover their interests and develop a diverse skill set. This approach enhances physical literacy and contributes to overall athletic versatility.",
        icon:"/images/icons/num-5.svg"
    },
    

]

const rightTextData = [
    {
        title:"Inclusive Participation",
        content:"The Association emphasizes inclusivity, making sports accessible to all children regardless of background or skill level. Everyone deserves the opportunity to experience the transformative power of sports.",
        icon:"/images/icons/num-2.png"
    },
    {
        title:"Structured Skill Development",
        content:"The program prioritizes structured skill development. Through carefully designed training modules, young athletes undergo a progression of skill acquisition aligned with their age and developmental stage. This structured approach, which incorporates the Spiral Approach to Learning, ensures a comprehensive and sustainable development journey.",
        icon:"/images/icons/num-4.svg"
    },
   
    {
            title:"Parental Involvement",
            content:"Recognizing parents' pivotal role in a child's development, the Multi-Sports Association of Canada (MAC) encourages active parental involvement in grassroots programs. Workshops, informational sessions, and collaborative events create a supportive ecosystem where families contribute to the growth and success of young athletes.",
        icon:"/images/icons/num-6.svg"
    },
    // {
    //     title:"Community Engagement and Outreach",
    //     content:"The, (NXGMSA), actively engages with the local community through grassroots development initiatives. Collaborations with schools, community centres, and local organizations create opportunities for widespread participation, bringing the joy of sports to a broader audience and fostering a sense of belonging.",
    //     icon:"/images/icons/num-7.svg"
    // },
]

function GrassRootText() {
  return (
    <div className='GrassRootText-container standard-padding-space'>
        <div className='container'>
            <div className='text-content text-center my-3'>
                <h3 className='body-heading mx-auto' style={{width:"80%"}}>Critical Components of Grassroots Development</h3>

            </div>
            <div className='row pt-4'>
                <div className='col-lg-6 px-0 d-flex flex-column align-items-center justify-content-center  main-box' >
                    {
                        leftTextData.map((ele,ind)=>{
                            return(
                                <div className='box px-1' 
                                    style={ind == leftTextData.length-1 ?{ borderBottom:"none"}:{}}
                                >
                                    <div className='d-flex justify-content-center justify-content-lg-start align-items-center gap-2 my-2'>
                                        <img src={ele.icon} className='box-icon'/>
                                        <h5 className='sub-heading mb-0' style={{fontWeight:"600"}}>
                                            {ele.title}
                                        </h5>
                                    </div>
                                    <p className='body-paragraph'>
                                        {ele.content}
                                    </p>
                                </div>
                            )
                        })
                    }
                    
                </div>
                <div className='col-lg-6 px-0 d-flex flex-column align-items-center justify-content-center'>
                    {
                            rightTextData.map((ele,ind)=>{
                                return(
                                    <div className='box ' 
                                        style={ind == rightTextData.length-1 ?{ borderBottom:"none"}:{}}
                                    >
                                        <div className='d-flex justify-content-center justify-content-lg-start align-items-center gap-2 my-2'>
                                            <img src={ele.icon} className='box-icon'/>
                                            <h5 className='sub-heading mb-0' style={{fontWeight:"600"}}>
                                                {ele.title}
                                            </h5>
                                        </div>
                                        <p className='body-paragraph'>
                                            {ele.content}
                                        </p>
                                    </div>
                                )
                            })
                        }
                </div>

            </div>
        </div>
    </div>
  )
}

export default GrassRootText