import React, { useState } from 'react'
import "./TextContent.css"
import { useNavigate } from 'react-router-dom';
import DropDownContainer from './DropDownContainer';
import TextCard from '../TextCard/TextCard';

function TextContent({
  heading,
  subHeading="",
  postSubHeading="",
  postHeading="",
  postContent="",
  content,
  btn=false,
  btnRefPhone=false,
  btnText,
  btnRef="/",
  shiftRow=false,
  img,
  reviewContent= false,
  reviewHeading,
  reviewPara,
  mobileTextCenter=true,
  titleList=false,
  titleListContent=[],
  dropDown=false,
  dropDownList=[],
  marginTop=false,
  sublineIcon=true,
  doubleContent=false,
  simpleList=false,
  simpleTitleList=false,
  foldableText=false,
  spaces=true
}) {
  const [dropOpen , setDropOpen] = useState(false)
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-3'>{paragraph}</p>);
  const navigate = useNavigate()
  return (
    <div className={`text-content-section  ${spaces ? "standard-padding-space":"py-1"}`} style={marginTop ? {paddingTop:0}:{}}>
      <div className='container'>
        <div className={`row d-flex ${shiftRow && "flex-row-reverse"} align-items-center gy-2`} >
          <div className={`col-lg-6 p-2 px-3 px-lg-4 d-flex align-items-center justify-content-center ${shiftRow ? "justify-content-lg-end" : "justify-content-lg-start"}`}>
            <img src={img} className='img-fluid'/>
          </div>
          <div className='col-lg-6'>
            <div className={`text-content ${mobileTextCenter && "text-center text-lg-start"} `} style={{width:"95%"}}>
              <p className='sub-heading mb-0'>{subHeading}</p>
              {/* <div className='paddLeft'> */}
              <div className='paddLef'>
                {
                  foldableText ?

                  <>
                  <div
                      className="folding-text d-flex flex-column align-items-start"
                      onClick={() => {
                        setDropOpen((prev) => !prev);
                      }}
                    >
                      <div
                        className={` ${dropOpen ? "d-none" : "d-block close"}`}
                      >
                        <h3 className='body-heading my-2 mt-3 '>{heading}</h3>
                          { sublineIcon && <img src='/images/icons/subline.svg' />}
                          <p className='sub-heading mb-3 mb-xxl-4' style={{fontSize:"35px",fontWeight:"600"}}>{postSubHeading}</p>
                          <div className=''>{paragraphs}</div>
                      </div>
                      <div
                        className={` ${dropOpen ? "d-block open" : "d-none"}`}
                        
                      >
                         <h3 className='body-heading my-2 mt-3 '>{heading}</h3>
                          { sublineIcon && <img src='/images/icons/subline.svg' />}
                          <p className='sub-heading mb-3 mb-xxl-4' style={{fontSize:"35px",fontWeight:"600"}}>{postSubHeading}</p>
                          <div className=''>{paragraphs}</div>
                          <h3 className='body-heading mt-3 my-xl-3 mt-xl-4'>{postHeading}</h3>
                          <p className='body-paragraph my-1 mb-3'>{postContent}</p>
                          {doubleContent &&
                            <>
                              <h3 className='body-heading mb-2 mt-5 '>{doubleContent.heading}</h3>
                              <p className='body-paragraph my-1 mb-3'>{doubleContent.content}</p>
                            </>
                          }
                      </div>
                      <button className='genral-btn align-self-end mx-auto mx-lg-1 my-3'>
                        READ MORE
                      <img
                        src={
                          dropOpen
                            ? "/images/icons/read-more-arrow-up.png"
                            : "/images/icons/read-more-arrow-down.png"
                        }
                        className="arrow-icon"
                        alt='arrow icon'
                      />
                      </button>
                    </div>
                </>
                  :
                  <>
                    <h3 className='body-heading my-2 mt-3 '>{heading}</h3>
                  { sublineIcon && <img src='/images/icons/subline.svg' />}
                  <p className='sub-heading mb-3 mb-xxl-4' style={{fontSize:"35px",fontWeight:"600"}}>{postSubHeading}</p>
                  <div className=''>{paragraphs}</div>
                  {postHeading && <h3 className='body-heading mt-3 my-xl-3 mt-xl-4'>{postHeading}</h3>}
                  {postContent && <p className='body-paragraph my-1 mb-3'>{postContent}</p>}
                  {doubleContent &&
                    <>
                      <h3 className='body-heading my-2 mt-3 '>{doubleContent.heading}</h3>
                      <p className='body-paragraph my-1 mb-3'>{doubleContent.content}</p>
                    </>
                  }
                  </>
                }
                
                {
                  simpleList &&
                  <div>
                    <ul className='ps-0'>
                      {
                        simpleList.map((point)=>{
                          return(<li style={{listStyle:"none"}}><p className='body-paragraph my-2'>{point}</p></li>)
                        })
                      }
                      
                    </ul>
                  </div>
                }
                {
                  titleList && 
                  <div>
                    {
                      titleListContent.map((ele)=>{
                        return(
                        <p className='body-paragraph text-start'>
                          <span style={{color:"black",fontSize:"18px",fontWeight:"600",marginRight:"5px"}}>{ele.title}</span>{ele.content}
                        </p>)
                      })
                    }
                  </div>
                }
                {
                  simpleTitleList &&
                  <div>
                    <ul className='ps-1'>
                    {
                      simpleTitleList.map((ele)=>{
                        return(
                          <li style={{listStyle:"none"}}>
                          <p className='body-paragraph text-start'>
                            <span style={{color:"black",fontSize:"18px",fontWeight:"600"}} className='font-Barlow'>{ele.title} </span>{ele.content}
                          </p>
                        </li>
                        )
                      })
                    }
                      
                    </ul>
                  </div>
                }
                
                {btn && <button className='genral-btn mt-4' onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                {btnRefPhone && <a href='tel:363 653 5869'><button className='genral-btn mt-4' >{btnText}</button></a>}
              </div>
              {
                reviewContent &&
                <>
                    <div className='my-2'>
                      <h5 className='sub-heading mb-1' style={{fontSize:"19px"}}>{reviewHeading}</h5>
                      <p className='body-heading mb-0' style={{fontSize:"22px"}}>{reviewPara}</p>
                    </div>
                  
                </>
              }
              {
                dropDown &&
                <>
                  {
                    dropDownList.map((ele,ind)=>{
                      return(
                        <>
                        <DropDownContainer
                          title={ele.title}
                          content={ele.content}
                        />
                        {ind < dropDownList.length-1 ? <div className='line-seprator'></div>:<></>}
                        </>
                      )
                    })
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextContent