import React from 'react'
import "./WhyUsBanner.css"

function WhyUsBanner() {
  return (
    <div className='WhyUsBanner-container '>
        <div className='container'>
            <div className='box center-text text-center d-flex flex-column justify-content-center align-items-center py-3'>
                
                {/* <p className='sub-heading' style={{ color:"white"}}>WHY A MULTI SPORTS ASSOCIATION</p> */}
                <h3 className='body-heading mt-3' style={{color:"white"}}>WHY A MULTI SPORTS ASSOCIATION</h3>
                <img src='/images/icons/white-double-subline.png' style={{maxWidth:"90%",marginTop:"2px",marginBottom:"2px"}}/>
                <p className='body-paragraph w-75' style={{color:"white"}}>The Multi-Sports Association of Canada (MAC) believes in the power of diversity, offering a dynamic and holistic approach to athletic development. We understand that skills learned in one sport can complement and enhance those in another, leading to a more versatile and resilient athlete. Join us in embracing the advantages of a multi-sport approach with unlimited potential.</p>
                {/* <p className='body-paragraph w-75' style={{color:"white"}}>Engaging in multi-sport development for kids aged 12 and under can offer several advantages that contribute to future success in elite sports and potentially increase the chances of obtaining university scholarships. Here are some reasons why this approach is often considered beneficial</p> */}

            </div>
        </div>
    </div>
  )
}

export default WhyUsBanner