import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import TextCard from '../component/TextCard/TextCard'
import WhyUsBanner from '../component/WhyUsBanner/WhyUsBanner'
import { Helmet } from 'react-helmet'

const cardContent = [
    {
    title:"Physical Literacy",
    actualPara:"Participating in various sports helps develop vital movement skills and physical literacy, enhancing overall athleticism and the capability to learn new sports skills quickly.",
    },
    {
    title:"Reduce Burnout & Overuse Injuries",
    actualPara:"Specializing in one sport at a young age can lead to burnout and overuse injuries. Exposure to different sports allows for a more balanced physical development, reducing the risk of physical fatigue and injury.",
    },
    {
    title:"Transferable Skills",
    actualPara:"Skills learned in one sport often have applications in others. For example, agility, balance, coordination, and teamwork are transferable skills that can benefit a child across various sports, providing a well-rounded skill set.",
    },
    {
    title:"Increased Motivation & Passion",
    actualPara:"Exposure to various sports allows children to explore their interests and passions, fostering a positive attitude toward physical activity and increasing motivation.",
    },
    {
    title:"Diverse Athletic Experiences",
    actualPara:"Exposure to different sports helps children understand various coaching styles, team dynamics, and competition formats, making them more adaptable to other environments.",
    },
    {
    title:"Late Specialization Model",
    actualPara:"The late specialization model suggests that delaying specialization in a single sport until later in adolescence can lead to better long-term athletic success. Multi-sport development aligns with this model, allowing for a more gradual and informed approach to specialization.",
    },
    {
    title:"Enhanced Mental Toughness",
    actualPara:"Facing challenges and learning to adapt to various sports environments can contribute to the development of mental toughness. This mental resilience is crucial for success in elite sports, where competition can be intense and demanding.",
    },
    {
    title:"Wider Network & Social Skills",
    actualPara:"Participating in multiple sports exposes children to a broader network of coaches, teammates, and parents. Building a diverse social network can be valuable for future opportunities, including potential connections to coaches and scouts.",
    },
    {
    title:"Versatility & Versatility Appeal",
    actualPara:"Coaches and recruiters often value athletes who are versatile. Proficiency in different sports demonstrates adaptability and a broader range of skills, making an athlete more appealing to college recruiters and professional scouts.",
    },
]

function WhyUs() {
    return (
        <div className="home-page">
           <Helmet>
            <title>
            NexGEN: Elevate Your Athlete's Journey with Multi-Sport Excellence
            </title>
            <meta
              name="description"
              content="Discover the commitment and innovation of NexGEN Multi-Sports Association. Embrace the advantages of multi-sport development for a versatile, resilient athlete."
            ></meta>
          </Helmet>
          <HeroBanner 
            subHeading=""
            Heading="WHY US"
            nextLineSpanHeading=""
            Paragraph="Elevate Your Athlete's Journey with NexGEN's Unmatched Commitment and Innovation"
            btn={false}
            btnText=""
            bgImgLg="/images/creatives/why-hero-bg-lg.jpg"
            bgImg="/images/creatives/why-hero-bg-mb.webp"
            btnRef="/"
            mobileText={false}
          />
          <WhyUsBanner/>
      <div className='right-gradient'>
          <TextCard
            heading=""
            content=""
            dropDown={false}
            cardContent={cardContent}
            subLine={false}
            marginTop={true}
            removeUpperSpace={true}
          />
         
        </div>
        {/* <div className='why-us-text-content standard-padding-space' style={{backgroundColor:"#F8F8F8"}}>
            <div className='container'>
                <div className='center-text text-center d-flex flex-column justify-content-center align-items-center my-3'>
                
                    <h3 className='body-heading '>TWO STREAM APPROACH</h3>
                    <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"2px",marginBottom:"5px"}}/>
                    <p className='body-paragraph' style={{width:"90%"}}>
                    The NexGen Multi-Sports Association, stands out with its innovative dual-stream approach, offering the Christian stream and the Open stream to cater to diverse participant preferences. The Christian stream is tailored for individuals seeking a faith-centric sports experience, emphasizing Christian values, character development, and spiritual mentorship. In contrast, the Open stream provides an inclusive and secular sports environment, focusing on sportsmanship and teamwork without integrating religious elements explicitly.
                    </p>
                    <p className='body-paragraph' style={{width:"90%"}}>
                    The Christian stream of the association has a clear mission to promote various sports activities within a Christian framework. It prioritizes inclusive sports programs for all ages and skill levels, fostering Christian fellowship and mentorship. Participants benefit from character development through athletics and engage in community outreach and service projects, demonstrating a commitment to making a positive impact beyond the sports field.
                    </p>
                    <p className='body-paragraph' style={{width:"90%"}}>
                    The association organizes faith-centric events and collaborates with Christian institutions, such as churches and schools, to expand its reach. Educational initiatives are incorporated, ensuring participants understand the intersection of Christian values and sportsmanship. The commitment to excellence in sports is evident through quality coaching and skill development, allowing participants to achieve their athletic potential while staying true to their Christian values.
                    </p>
                    <p className='body-paragraph' style={{width:"90%"}}>
                    For those in the Open stream curious about the Christian stream, the association provides optional opportunities for exploration without imposing beliefs. This approach reflects a commitment to inclusivity, acknowledging diverse preferences and beliefs while offering a fulfilling and enriching sports experience for all participants. Whether deeply rooted in Christian values or seeking a more secular and open environment, The NexGen Multi-Sports Association, ensures a supportive and respectful atmosphere for everyone
                    </p>

                </div>
            </div>
        </div> */}
        <Footer/>
    </div>
      )
}

export default WhyUs