import React from 'react'
import "./IconsText.css"
import { useNavigate } from 'react-router-dom'

function IconsText() {
    const navigate = useNavigate()
  return (
    <div className='IconsText-container '>
        <div className='container'>
            <div className='icons-box d-flex py-4 px-3 row'>
                <div className='box col-lg-3 col-md-6 text-center d-flex align-items-center justify-content-center flex-column gap-2 mt-lg-0 mt-3'>
                    <img src='/images/icons/play.webp' className='box-icon'/>
                    <h4 className='title font-Barlow'>WANT YOUR KIDS TO PLAY 
                    <br></br><span style={{whiteSpace:"nowrap"}}>AT THE MAC?</span></h4>
                    {/* <p className='body-paragraph'>Join a team in your local community here in Ontario</p> */}
                    <button className="genral-btn mt-4" onClick={()=>{navigate("/contact")}}>CONTACT US</button>
                </div>
                <div className='box col-lg-3 col-md-6 text-center d-flex align-items-center justify-content-center flex-column gap-2 mt-lg-0 mt-3'>
                    <img src='/images/icons/coach.webp' className='box-icon'/>
                    <h4 className='title font-Barlow'>WANT TO COACH 
                    <br></br><span style={{whiteSpace:"nowrap"}}>AT THE MAC?</span></h4>
                    {/* <p className='body-paragraph'>Want to coach a team in The MAC?</p> */}
                    <button className="genral-btn mt-4" onClick={()=>{window.open("https://forms.gle/Lkqz774dhhoqovtn6")}}>CONTACT US</button>
                </div>
                <div className='box col-lg-3 col-md-6 text-center d-flex align-items-center justify-content-center flex-column gap-2 mt-lg-0 mt-3'>
                    <img src='/images/icons/Volunteer.webp' className='box-icon'/>
                    <h4 className='title font-Barlow'>WANT TO VOLUNTEER 
                    <br></br><span style={{whiteSpace:"nowrap"}}>AT THE MAC?</span></h4>
                    {/* <p className='body-paragraph'>Want to volunteer in The MAC?</p> */}
                    <button className="genral-btn mt-4" onClick={()=>{window.open("https://forms.gle/u5rroTx6CHM19zJm7")}}>CONTACT US</button>
                </div>

                <div className='box col-lg-3 col-md-6 text-center d-flex align-items-center justify-content-center flex-column gap-2 mt-lg-0 mt-3'>
                    <img src='/images/icons/Officiate.webp' className='box-icon' />
                    <h4 className='title font-Barlow'>WANT TO OFFICIATE 
                    <br></br><span style={{whiteSpace:"nowrap"}}>AT THE MAC?</span></h4>
                    {/* <p className='body-paragraph'>Want to officiate in The MAC?</p> */}
                    <button className="genral-btn mt-4" onClick={()=>{window.open("https://forms.gle/tq2G8tknLFqcpTLx9")}}>CONTACT US</button>
                </div>

             

               

               

            </div>
        </div>
    </div>
  )
}

export default IconsText