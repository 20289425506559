import React, { useState,useRef,useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import "./Header.css";

const Header = ({slideState ,darkBg=false}) => {
  const [menuState, setMenuState] = useState(false);
  const [changeArrowcolor, setChangeArrowColor] = useState(false);
  const [changeArrowcolorGd, setChangeArrowColorGd] = useState(false);
  const [changeArrowcolorDev, setChangeArrowColorDev] = useState(false);
  const [changeArrowcolorSoc, setChangeArrowColorSoc] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [isDropdownOpenGdDesk, setIsDropdownOpenGdDesk] = useState(false);
  const [isDropdownOpenGd, setIsDropdownOpenGd] = useState(false);
  const [isDropdownOpenDevDesk, setIsDropdownOpenDevDesk] = useState(false);
  const [isDropdownOpenDev, setIsDropdownOpenDev] = useState(false);
  const [isDropdownOpenDeskAthalons, setIsDropdownOpenDeskAthalons] = useState(false);
  const [isDropdownOpenSocialDesk, setIsDropdownOpenSocialDesk] = useState(false);

  const [grassDrop, setGrassDrop] = useState(false);

  const navigate = useNavigate()
  const clickCountRef = useRef(0);
  const dropdownRef = useRef();
  const dropdownGdRef = useRef();
  const dropdownDevRef = useRef();
  const dropdownRefAthalons = useRef();
  const dropdownRefSocial = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRefAthalons.current && !dropdownRefAthalons.current.contains(event.target) && isDropdownOpenDeskAthalons) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDeskAthalons(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDeskAthalons]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRefSocial.current && !dropdownRefSocial.current.contains(event.target) && isDropdownOpenSocialDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenSocialDesk(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenSocialDesk]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDesk(false);
        }
      }
      if (dropdownGdRef.current && !dropdownGdRef.current.contains(event.target) && isDropdownOpenGdDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenGdDesk(false);
        }
      }
      if (dropdownDevRef.current && !dropdownDevRef.current.contains(event.target) && isDropdownOpenDevDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDevDesk(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk,isDropdownOpenGdDesk,isDropdownOpenDevDesk]);


const onClickService = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpen(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpen(false)
        navigate("/about");
        clickCountRef.current = 0;
      }
  };
const onClickGd = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpenGd(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpenGd(false)
        navigate("/why-us");
        clickCountRef.current = 0;
      }
  };
const onClickDev = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpenDev(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpenDev(false)
        navigate("/events");
        clickCountRef.current = 0;
      }
  };

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };

  return (
    <>
    
    <div className={`header `} 
      style={darkBg?{backgroundColor:"#292929"}:{}}
    >
      <div className="header-wrapper container">
        <nav className="navbar d-md-flex flex-md-row d-block">
          <nav className="navbar-container px-lg-0 px-2 py-2">
            <div className="nav-elements d-flex justify-content-between align-items-center px-lg-2">
              <input className="d-none" type="checkbox" id="menu-toggle" />
              {/* <img src="/images/icons/nav-logo.svg" className="nav-logo" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}}/> */}
              <img src="/images/icons/nav-logo-new-w.png" className="nav-logo" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}}/>
              <nav 
                // style={{borderLeft:"2px solid #7a7a7a"}}
              >
                <ul
                  className="nav-list d-xl-flex gap-xl-4 gap-lg-3 align-items-center d-none main-nav ps-0"
                  id="js-menu"
                >
                  {/* <li>
                    <Link to={"/home"} className="nav-links font-Open-Sans">
                      Home
                    </Link>
                  </li> */}

                  <div className="navigation-dropdown" ref={dropdownRef}>
                  <li onMouseEnter={()=>{setIsDropdownOpenDesk(true);setIsDropdownOpenDeskAthalons(false);setIsDropdownOpenDevDesk(false);setIsDropdownOpenGdDesk(false)}} >

                      <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColor(true)}} onMouseOut={()=>{setChangeArrowColor(false)}}>
                          <Link to={"/about"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}} style={{color:`${changeArrowcolor ? "#e98a8a":"white"}`}}>ABOUT</Link>
                          <img src={`/images/icons/drop-icon.svg`} className={isDropdownOpenDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenDesk(false)}}>
              
                        <Link to={"/long-term-athlete-development"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}>LTAD</p>
                        </Link>
                        <Link to={"/curriculum"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}>curriculum</p>
                        </Link>
                      </div>
                    </li>
                    </div>

                  


                  <div className="navigation-dropdown" ref={dropdownDevRef}>
                  <li onMouseEnter={()=>{setIsDropdownOpenDevDesk(true);setIsDropdownOpenDesk(false);setIsDropdownOpenDeskAthalons(false);setIsDropdownOpenGdDesk(false)}} >

                      <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColorDev(true)}} onMouseOut={()=>{setChangeArrowColorDev(false);setGrassDrop(false)}}>
                          <Link to={"/why-us"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDevDesk(false)}} style={{color:`${changeArrowcolorDev ? "#e98a8a":"white"}`}}>why us</Link>
                          <img src={`/images/icons/drop-icon.svg`} className={isDropdownOpenDevDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenDevDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDevDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenDevDesk(false)}} style={{left:"0"}}>
              
                       
                        <Link to={"/advantage"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDevDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}>Advantage</p>
                        </Link>
                        <Link to={"/unique-approach"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDevDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}>unique approach</p>
                        </Link>
                        <Link to={"/quality-first"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDevDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}>quality first</p>
                        </Link>
                       
                        <div className="p-0 text-start" onMouseOver={()=>setGrassDrop(true)} onMouseOut={()=>setGrassDrop(false)}>
                          <Link to={"/development"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDevDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Development
                            <img src='/images/icons/drop-icon-b.png' alt="arrow" style={{transform:"rotate(0deg)",marginLeft:"8px",marginBottom:"0px" , height:"15px",width:"15px"}}/>
                            </p>
                          </Link>
                          {
                            grassDrop &&
                            <div className="navigation-dropdown mt-2" ref={dropdownRefAthalons}>
                            <li onMouseOver={()=>{setIsDropdownOpenDesk(false);setIsDropdownOpenDevDesk(true)}} >

                              <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setIsDropdownOpenDeskAthalons(true)}}>
                                  <Link className="nav-links" to={"/grass-root-development"}  onClick={()=>{setIsDropdownOpenDeskAthalons(false)}} >Grassroots</Link>
                                  <img src='/images/icons/drop-icon-b.png' alt="arrow" style={{transform:"rotate(-90deg)",marginLeft:"8px",marginBottom:"0px" , height:"15px",width:"15px"}}/>
                              </div>
                              <div className={`dropdown-content-left ${isDropdownOpenDeskAthalons ? 'open' : 'none-delay'}`} onMouseOver={()=>{setIsDropdownOpenDeskAthalons(true)}} onMouseOut={()=>{setIsDropdownOpenDeskAthalons(false)}}>
                                <Link to={"/4-u-team-support-development"} className="nav-links" onClick={()=>{setIsDropdownOpenDeskAthalons(false)}}>
                                  <p className="mb-0" style={{textTransform:"uppercase"}}>4U DEVELOPMENT  </p>
                                </Link>
                                <Link to={"/5-u-team-support-development"} className="nav-links" onClick={()=>{setIsDropdownOpenDeskAthalons(false)}}>
                                  <p className="mb-0" style={{textTransform:"uppercase"}}>5U DEVELOPMENT  </p>
                                </Link>
                                <Link to={"/7-u-team-support-development"} className="nav-links" onClick={()=>{setIsDropdownOpenDeskAthalons(false)}}>
                                  <p className="mb-0" style={{textTransform:"uppercase"}}>7U DEVELOPMENT  </p>
                                </Link>
                                <Link to={"/fast-tracking"} className="nav-links" onClick={()=>{setIsDropdownOpenDeskAthalons(false)}}>
                                  <p className="mb-0" style={{textTransform:"uppercase"}}>fast tracking</p>
                                </Link>
                              </div>
                            </li>
                            </div>
                          }
                        </div>
                        
                      </div>
                    </li>
                    </div>
                    <li>
                    <Link to={"/services"} className="nav-links font-Open-Sans">
                    services
                    </Link>
                  </li>
                 
                  <div className="navigation-dropdown" ref={dropdownGdRef}>
                  <li onMouseEnter={()=>{setIsDropdownOpenGdDesk(true)}} >

                      <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColorGd(true)}} onMouseOut={()=>{setChangeArrowColorGd(false);setIsDropdownOpenDeskAthalons(false)}}>
                          <Link to={"/events"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGdDesk(false);setIsDropdownOpenDeskAthalons(false)}} style={{color:`${changeArrowcolorGd ? "#e98a8a":"white"}`}}>events</Link>
                          <img src={`/images/icons/drop-icon.svg`} className={isDropdownOpenGdDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenGdDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenGdDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenGdDesk(false);setIsDropdownOpenDeskAthalons(false)}} style={{left:"0"}}>
              
                       
                        
                        <Link to={"/house-leagues"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGdDesk(false)}} onMouseOver={()=>{setIsDropdownOpenDeskAthalons(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}>house Leagues</p>
                        </Link>
                        <Link to={"/elite-testing"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGdDesk(false)}} onMouseOver={()=>{setIsDropdownOpenDeskAthalons(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}>sports testing</p>
                        </Link> 
                        <Link to={"/leaderboard"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGdDesk(false)}} onMouseOver={()=>{setIsDropdownOpenDeskAthalons(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}>leaderboard</p>
                        </Link> 
                        
                      </div>
                    </li>
                    </div>
                    
                  <li>
                    <Link to={"/blog"} className="nav-links font-Open-Sans">
                    NEWS
                    </Link>
                  </li>
                  
                  <div className="navigation-dropdown" ref={dropdownRefSocial}>
                  <li onMouseEnter={()=>{setIsDropdownOpenSocialDesk(true);setIsDropdownOpenDeskAthalons(false);setIsDropdownOpenDevDesk(false);setIsDropdownOpenGdDesk(false)}} >

                      <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColorSoc(true)}} onMouseOut={()=>{setChangeArrowColorSoc(false)}}>
                          <Link className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenSocialDesk(false)}} style={{color:`${changeArrowcolorSoc ? "#e98a8a":"white"}`}}>Socials</Link>
                          <img src={`/images/icons/drop-icon.svg`} className={isDropdownOpenSocialDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenSocialDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenSocialDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenSocialDesk(false)}} style={{left:"0px"}}>
              
                        <Link to={"/"} className="nav-links font-Open-Sans mx-auto" onClick={()=>{setIsDropdownOpenSocialDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}><img src='/images/icons/facebook-inner-icon.svg' className='social-icon'/></p>
                        </Link>
                        <Link to={"/"} className="nav-links font-Open-Sans mx-auto" onClick={()=>{setIsDropdownOpenSocialDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}><img src='/images/icons/linked-inner-icon.svg' className='social-icon'/></p>
                        </Link>
                        <Link to={"/"} className="nav-links font-Open-Sans mx-auto" onClick={()=>{setIsDropdownOpenSocialDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}> <img src='/images/icons/twitter-inner-icon.svg' className='social-icon'/></p>
                        </Link>
                        <Link to={"/"} className="nav-links font-Open-Sans mx-auto" onClick={()=>{setIsDropdownOpenSocialDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}><img src='/images/icons/mail-inner-icon.svg' className='social-icon'/></p>
                        </Link>
                        <Link to={"/"} className="nav-links font-Open-Sans mx-auto" onClick={()=>{setIsDropdownOpenSocialDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"uppercase"}}><img src='/images/icons/pintrest-inner-icon.svg' className='social-icon'/></p>
                        </Link>
                      </div>
                    </li>
                    </div>
                </ul>
              </nav>

              <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-xl-none d-inline"
              >
                <img
                  src="/images/icons/hamburger-menu.png"
                  className="img-fluid menu-btn"
                  alt="menu"
                />
                
              </label>
              
              <div className="nav-buttton ml-5 d-xl-flex d-none  extras-btns">
               <button className="genral-btn font-Open-Sans" style={{fontSize:"14px",fontWeight:"600"}} onClick={()=>{navigate("/contact")}} >
                CONTACT US
                </button>
                
              </div>
            </div>
          </nav>

          {/* Dropdown in responsive mode */}
          <nav className="responsive-popup-menu w-100">
            <div className="py-3 d-xl-none" style={{ display: menuState ? "block" : "none" }}>
            <ul
              className="resp-nav-list d-xl-none w-100 gap-4 hs-dropdown [--trigger:hover] pl-4"
              style={{ display: menuState ? "block" : "none" }}
            >
              <li>
                <Link to={"/home"} className="nav-links font-Open-Sans" style={{textTransform:"uppercase"}}>
                  Home
                </Link>
              </li>
              
               <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickService} >
                      <a className="nav-links font-Open-Sans" style={{textTransform:"uppercase"}}>About</a>
                      <img src='/images/icons/drop-icon-b.png' className={isDropdownOpen ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpen ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                    <Link to={"/long-term-athlete-development"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>LTAD</p>
                    </Link>
                    <Link to={"/curriculum"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>curriculum</p>
                    </Link>
                  </div>
                </div>
                </li>
                <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickGd} >
                      <a className="nav-links font-Open-Sans" style={{textTransform:"uppercase"}}> why us</a>
                      <img src='/images/icons/drop-icon-b.png' className={isDropdownOpenGd ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpenGd ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                    <Link to={"/advantage"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGd(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Advantage</p>
                    </Link>
                    <Link to={"/unique-approach"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGd(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>unique approach</p>
                    </Link>
                    <Link to={"/quality-first"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGd(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>quality first</p>
                    </Link>
                    <Link to={"/development"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGd(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>development</p>
                    </Link>
                    <Link to={"/grass-root-development"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGd(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Grassroots</p>
                    </Link>
                    <Link to={"/4-u-team-support-development"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGd(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>4U DEVELOPMENT  </p>
                    </Link>
                    <Link to={"/5-u-team-support-development"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGd(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>5U DEVELOPMENT  </p>
                    </Link>
                    <Link to={"/7-u-team-support-development"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGd(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>7U DEVELOPMENT  </p>
                    </Link>
                    <Link to={"/fast-tracking"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenGd(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>fast tracking</p>
                    </Link>
                  </div>
                </div>
                </li>
                <li>
                  <Link to={"/services"} className="nav-links font-Open-Sans" style={{textTransform:"uppercase"}}>
                  services
                  </Link>
                </li>
                
              <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickDev} >
                      <a className="nav-links font-Open-Sans" style={{textTransform:"uppercase"}}> events</a>
                      <img src='/images/icons/drop-icon-b.png' className={isDropdownOpenDev ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpenDev ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                    
                   
                    <Link to={"/house-leagues"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDev(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>house leagues</p>
                    </Link>
                    
                    <Link to={"/leaderboard"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDev(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>leaderboard</p>
                    </Link>
                    <Link to={"/elite-testing"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDev(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>sports testing</p>
                    </Link>
                    
                  </div>
                </div>
                </li>
              
                
              <li>
                <Link to={"/blog"} className="nav-links font-Open-Sans" style={{textTransform:"uppercase"}}>
                NEWS
                </Link>
              </li>
            </ul>
            <div className="nav-buttton extras-btns">
              <button className="genral-btn font-Open-Sans" style={{fontSize:"14px",fontWeight:"600"}}  onClick={()=>{navigate("/contact")}}>
                CONTACT US
                </button>
            </div>
            </div>
          </nav>
        </nav>
      </div>
    </div>
    </>
  );
};

export default Header; 