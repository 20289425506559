import React,{useState,useEffect} from "react";
import "./HeroBanner.css";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import {Spinner} from "react-bootstrap"


const HeroBanner = ({
  Heading,
  subHeading,
  postSubHeading=null,
  postSubHeadingRed=null,
  spanHeading="",
  Paragraph,
  btnText,
  bgImg,
  bgVideo=false,
  btn=false,
  btnRef="/",
  socialLinks=false,
  serviceForm=false,
  bgEffect=false,
  respBgImg=null,
  isCheveron=false,
  heightAuto=false,
  paraWhite=false,
  bannerMarginBottom=false,
  bgImgLg=null,
  mobileText=true,
  customHeight=null,
  bgSmallScreenWidth=false,
  largeMobileHeading=false
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1150);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1150);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form-main", {
        email: email,
        name:name,
        message:message,
        phoneNum:phone,
        formType: "Contact page Form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message sent Successfully!");
          setLoading(false);
          // setEmail("");
          window.open("/thank-you", '_blank');
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Failed to send message, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); 
    const maxLength = 25;


    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  };
  return (
    <>
    <div
      className={`${customHeight && `${customHeight}`} common_hero_section  ${bgVideo && "bg-video"} ${heightAuto && "h-cus-auto"} ${!bannerMarginBottom && "mb-5"} ${serviceForm&& "h-auto"} ${bgSmallScreenWidth && "bgSmallScreenWidth"}`}
      style={{ backgroundImage: `url(${!isSmallScreen&& bgImgLg ?bgImgLg: bgImg})`}}
    >
      <div className="pre-header w-100 d-lg-flex d-none  p-2 ">
        <div className="container d-flex align-items-center justify-content-between px-3">

          <div className="d-flex align-items-center gap-2">
            <img src="/images/icons/pre-header-mail.svg" />
            <a className="hero-paragraph mb-0" href="mailto:info@Multisportsassociation.ca" style={{color:"white",textDecoration:"none"}}>info@Multisportsassociation.ca  </a>
          </div>
          <div className="d-flex align-items-center gap-2">
            <img src="/images/icons/pre-header-phone.svg" />
            <a className="hero-paragraph mb-0" href="tel:363 653 5869" style={{color:"white",textDecoration:"none"}}>(363) 653 5869</a>
          </div>
          <div className="d-flex align-items-center gap-2">
            <img src="/images/icons/pre-header-loc.svg" />
            <p className="hero-paragraph mb-0">Multi-Sports Association Ontario, Canada</p>
          </div>
        </div>
      </div>
      <div className={`${bgEffect && "bg-effect"} h-100`}>
       { bgVideo && <video autoPlay loop muted className="bg-video-mp" playsinline="true" disablePictureInPicture="true">
        <source src={bgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>} 

      <div className="hero-container h-100  px-0"
      
      >
     <div className="wrapper d-flex flex-column ">
     
      <Header 
      />
      <div 
        className=" d-flex align-items-center py-5 my-auto justify-content-center container"
        >
        <div className="row gy-2 w-100 align-items-center">
          <div
            className={
              serviceForm
                ? "col-lg-6 mt-2"
                : "col-12 hero_content_wrapper px-3 w-100"
            }
          >
            <div className={`hero-text-content w-100 ${serviceForm?"text-start":"text-center"} ${!isSmallScreen&&bgImgLg && "d-none"  } ${!mobileText && "d-none"}`}>
            <h5 className="hero-sub-heading">{subHeading}</h5>
            <h1 className={`hero-section-heading my-2 ${largeMobileHeading && "largeMobileHeading"}`} style={serviceForm ? {fontSize:""}:{}}>
              {Heading}
              <span className="heading-color-change">{spanHeading}</span>
            </h1>

           { postSubHeading && <h5 className="hero-sub-heading" >{postSubHeading}</h5>}
           { postSubHeadingRed && <h5 className="sub-heading" >{postSubHeadingRed}</h5>}
            <p className="body-paragraph mx-auto" style={paraWhite?{color:"white"}:{}}>{Paragraph}</p>
            {btn && <button className="genral-btn mt-4" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
            {isCheveron && <img src="/images/icons/chevron.svg" />}
            {socialLinks && (
              <div className="social-links py-2 mt-lg-3 mt-3 mb-lg-5 mb-0 ">
                  <div className="link-container d-flex align-items-center gap-1 mb-2">
                    <img src="/images/icons/social-phone.svg" />
                    <p className="m-1 body-paragraph" style={{fontSize:"18px",color:"white"}}>(363) 653 5869</p>
                  </div>
                  <div className="link-container d-flex align-items-center gap-1 mb-2">
                    <img src="/images/icons/social-mail.svg" />
                    <p className="m-1 body-paragraph" style={{fontSize:"18px",color:"white"}}>info@Multisportsassociation.ca </p>
                  </div>
                
                <div className="link-container d-flex align-items-center gap-1">
                  <img src="/images/icons/social-location.svg" />
                  <p className="m-0 body-paragraph" style={{fontSize:"18px",color:"white"}}>Multi-Sports Association Ontario, Canada</p>
                </div>
                {/* <div className="link-container d-flex align-items-center gap-1">
                  <img src="/images/icons/social-time.png" />
                  <p className="m-0 body-paragraph" style={{fontSize:"18px",color:"white"}}>Monday to Friday (7:30 AM - 7:30 PM)</p>
                </div> */}
              </div>
            )}
          </div>
          </div>
          {serviceForm && (
            <div className="col-lg-6 mt-5 mt-lg-0 pb-5 mx-auto">
              <form className="service-form d-flex flex-column px-lg-5 py-5 pt-lg-5 pt-3 px-3" onSubmit={formSubmitHandler}>
                <h3 className="body-heading " style={{ color: "white",fontSize:"35px",textTransform:"capitalize" }}>
                Let's Get Started 
                </h3>
                {/* <p
                  className="body-paragraph text-start"
                  style={{ color: "white",fontSize:"20px" }}
                >
                  Your Gateway to Tailored Solutions
                </p> */}
                <input 
                   type="text" 
                   value={name}
                   maxLength="36"
                   onChange={handleNameChange}
                  placeholder="Your Full Name" 
                />
                <input 
                   type="email" 
                   value={email}
                   maxLength="66"
                   onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email Address" 
                />
                <input 
                  type="tel" 
                  value={phone}
                  maxLength="25"
                  onChange={handlePhoneChange}
                  placeholder="Phone Number" 
                />
                <textarea 
                  type="text" 
                  value={message}
                  maxLength="350"
                  rows={5}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your Message" 
                />
                <button className="service-from-btn  genral-btn px-5" type="submit" style={{fontWeight:"600"}}>Submit
                {loading ? (
                      <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                      />
                  ) : (
                  success ? (
                      <div>
                      {setLoading(false)}
                      {/* {alert(success)} */}

                      {setSuccess(false)}
                      </div>
                  ) : (
                      error && (
                      <div>
                          {setLoading(false)}
                          {alert(error)}
                          {setError(false)}
                      </div>
                      )
                  )
                  )}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
      </div>
      </div>
      </div>
    </div>
    </>
  );
};

export default HeroBanner; 