import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'
import TextContent from '../component/TextContent/TextContent'
import TextCard from '../component/TextCard/TextCard'

const cardContent = [
  {
    "title": "Personalized Coaching",
    "actualPara": "Coaches provide individualized attention to tailor training programs based on each child's strengths and weaknesses."
  },
  {
    "title": "Targeted Skill Development",
    "actualPara": "Prioritizes specific skills or techniques to facilitate quick progress in a sport."
  },
  {
    "title": "Smaller Group Sizes",
    "actualPara": "Enable more focused attention, immediate feedback, and a conducive learning environment, leading to accelerated skill development and improved performance."
  }
]

const secondCardContent = [
  {
    icon:'/images/icons/ft-icon-1.webp',
    "title": "Semi Private Coaching",
    "breifPara": "Providing young athletes access to experienced coaches specializing in their sport accelerates their progress...",
    "actualPara": "Providing young athletes access to experienced coaches specializing in their sport accelerates their progress through expert guidance, personalized training plans, and mentorship to reach their full potential."
  },
  {
    icon:'/images/icons/ft-icon-2.webp',
    "title": "Customized Programs",
    "breifPara": "Implement structured and progressive training programs focused on skill development for rapid performance improvements.",
    "actualPara": "Implement structured and progressive training programs focused on skill development for rapid performance improvements."
  },
  {
    icon:'/images/icons/ft-icon-3.webp',
    "title": "Nutritional Guidance",
    "breifPara": "Proper nutrition is crucial for young athletes' athletic development. It optimizes performance, aids recovery, and supports overall growth.",
    "actualPara": "Proper nutrition is crucial for young athletes' athletic development. It optimizes performance, aids recovery, and supports overall growth."
  },
  {
    icon:'/images/icons/ft-icon-4.webp',
    "title": "Testing & Monitoring",
    "breifPara": "Testing young athletes provides valuable insights into their abilities and potential...",
    "actualPara": "Testing young athletes provides valuable insights into their abilities and potential. It helps identify talent, customize training programs, monitor progress, and develop mental toughness."
  }
]



function FastTracking() {
    return (
        <div className="home-page">
             <Helmet>
            <title>
            Fast-Tracking Exceptional Talent: NexGen's Approach for Kids 8 and Under
            </title>
            <meta
              name="description"
              content="Discover NexGen's specialized approach for nurturing extraordinary young athletes aged 8 and under. Personalized training, mentorship, and holistic development for tomorrow's champions."
            ></meta>
          </Helmet>
          <HeroBanner 
            postSubHeading=""
            Heading="Fast Tracking"
            nextLineSpanHeading=""
            Paragraph=''
            btn={false}
            btnText=""
            bgImgLg="/images/creatives/fastTrack-hero-bg-lg.webp"
            bgImg="/images/creatives/fastTrack-hero-bg.webp"
            btnRef="/"
            mobileText={false}
            customHeight={"h-65"}
            bgSmallScreenWidth={true}
          />
          <RedPostBanner
            heading='ACCELERATE YOUR CHILD’S GROWTH'
            subLine={true}
            content={`The MAC's fast-tracking system is designed for families who want to expedite an athlete's development and progress. This approach involves more intense training, personalized coaching, and targeted skill development to help athletes reach their full potential more rapidly than traditional training methods.`}
          />
      <div className='right-gradient'>

            {/* <div className='centeredText-content'>
                <div className='container'>
                        <div className='text-content text-center d-flex flex-column justify-content-center align-items-center my-3'>
                
                            <h3 className='body-heading px-3'>The Mac’s  Approach to Fast Tracking Talent</h3>
                            <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"4px",marginBottom:"4px"}}/>
                            <p className='body-paragraph w-75 my-2'><b>The Multi-Sports Association of Canada (MAC),</b> recognizes that nurturing athletes requires extra time and effort. The MAC offers a supportive environment for children who aspire to excel and make significant improvements. An all-encompassing approach to training, development, and support is vital to accelerate their progress. Evaluating young athletes is essential for creating customized training programs, monitoring progress, preventing injuries, building mental resilience, and optimizing performance. Fast-tracking athletic development through specialized classes with smaller groups offers numerous benefits for children aiming to enhance their skills rapidly. The combination of intense training, personalized coaching, and focused skill development enables young athletes to progress quickly while receiving the necessary attention to detail from coaches.</p>

                            <h3 className='body-heading px-3 mt-5'>How Fast Tracking Elevates Children's Sport Development</h3>
                            <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"4px",marginBottom:"4px"}}/>
                            <h3 className='body-heading px-3 mb-4'>More frequent and intense training sessions enable faster skill improvement.</h3>
                            <h3 className='body-heading px-3 fast-tracking-title mt-2'>Personalized Coaching:</h3>
                            <p className='body-paragraph w-75 my-2'>Coaches provide individualized attention to tailor training programs based on each child's strengths and weaknesses.</p>

                            <h3 className='body-heading px-3 fast-tracking-title mt-2'>Targeted Skill Development:</h3>
                            <p className='body-paragraph w-75 my-2'>Prioritizes specific skills or techniques to facilitate quick progress in a sport.</p>

                            <h3 className='body-heading px-3 fast-tracking-title mt-2'>Smaller group sizes:</h3>
                            <p className='body-paragraph w-75 my-2'>Enable more focused attention, immediate feedback, and a conducive learning environment, leading to accelerated skill development and improved performance.</p>
                            
                            
                            <h3 className='body-heading px-3 mt-5'>WHAT DOES FAST-TRACKING LOOK LIKE?</h3>
                            <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"4px",marginBottom:"4px"}}/>
                            <h3 className='body-heading px-3 fast-tracking-title mt-2'>Specialized Coaching:</h3>
                            <p className='body-paragraph w-75 my-2'>Providing young athletes access to experienced coaches specializing in their sport accelerates their progress through expert guidance, personalized training plans, and mentorship to reach their full potential.</p>

                            <h3 className='body-heading px-3 fast-tracking-title mt-2'>Customized Programs</h3>
                            <p className='body-paragraph w-75 my-2'>Implement structured and progressive training programs focused on skill development for rapid performance improvements.</p>

                            <h3 className='body-heading px-3 fast-tracking-title mt-2'>Nutritional Guidance:</h3>
                            <p className='body-paragraph w-75 my-2'>Proper nutrition is crucial for young athletes' athletic development. It optimizes performance, aids recovery, and supports overall growth.</p>

                            <h3 className='body-heading px-3 fast-tracking-title mt-2'>Testing & Monitoring:</h3>
                            <p className='body-paragraph w-75 my-2'>Testing young athletes provides valuable insights into their abilities and potential. It helps identify talent, customize training programs, monitor progress, and develop mental toughness.</p>

                        </div>
                </div>
            </div> */}

            <TextContent
                heading= "The Mac’s  Approach to Fast Tracking Talent"
                subHeading=""
                content={"The Multi-Sports Association of Canada (MAC), recognizes that nurturing athletes requires extra time and effort. The MAC offers a supportive environment for children who aspire to excel and make significant improvements. An all-encompassing approach to training, development, and support is vital to accelerate their progress. Evaluating young athletes is essential for creating customized training programs, monitoring progress, preventing injuries, building mental resilience, and optimizing performance. Fast-tracking athletic development through specialized classes with smaller groups offers numerous benefits for children aiming to enhance their skills rapidly. The combination of intense training, personalized coaching, and focused skill development enables young athletes to progress quickly while receiving the necessary attention to detail from coaches."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={true}
                img="/images/creatives/ft-text-1.webp"
                reviewContent={false}
                sublineIcon={true}
                marginTop={true}
            />
            

            <TextCard
              heading="How Fast Tracking Elevates Children's Sports Development"
              content="More frequent and intense training sessions enable faster skill improvement."
              dropDown={false}
              cardContent={cardContent}
              subLine={true}
              marginTop={true}
              isBottomSection={false}
            />

            <TextCard
              dropDown={true}
              heading="WHAT DOES FAST-TRACKING LOOK LIKE?"
              content={""}
              cardContent={secondCardContent}
            />
         
              
        <Footer/>
        </div>
    </div>
    )
}

export default FastTracking