import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import TextCard from '../component/TextCard/TextCard'
import { Helmet } from 'react-helmet'
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'

const cardContent = [
    {
      icon:'/images/icons/curriculum-icon-1.webp',
    title:"Early Talent Identification",
    breifPara:"The MAC Curriculum takes a proactive approach to talent identification. We closely monitor young athletes in our programs...",
    actualPara:"The MAC Curriculum takes a proactive approach to talent identification. We closely monitor young athletes in our programs and identify those who demonstrate exceptional skills, passion, and dedication.\n Coaches and input from parents and guardians play a crucial role in recognizing and assessing the potential of young talents.",
    },
    {
      icon:'/images/icons/curriculum-icon-2.webp',
    title:"Personalized Development Plans",
    breifPara:"For identified exceptional talents, The MAC Curriculum creates personalized development plans tailored to their unique strengths...",
    actualPara:"For identified exceptional talents, The MAC Curriculum creates personalized development plans tailored to their unique strengths, areas for improvement, and specific goals. These plans encompass a blend of advanced skill development, sport-specific training, and exposure to higher-level challenges.",
    },
    // {
    //   icon:'/images/icons/curriculum-icon-3.webp',
    // title:"Advanced Skill Clinics & Workshops",
    // breifPara:"The MAC Curriculum offers a range of semi private skill clinics and workshops by expert coaches, providing young talents with specialized training and guidance...",
    // actualPara:"The MAC Curriculum offers a range of semi private skill clinics and workshops by expert coaches, providing young talents with specialized training and guidance. These sessions focus on refining advanced techniques, strategic understanding, and mental aspects of the game to elevate their skills further.",
    // },
    // {
    //   icon:'/images/icons/curriculum-icon-4.webp',
    // title:"Mentorship Programs",
    // breifPara:"Exceptional talents benefit from mentorship programs, connecting them with experienced athletes and coaches to provide insights, guidance, and inspiration...",
    // actualPara:"Exceptional talents benefit from mentorship programs, connecting them with experienced athletes and coaches to provide insights, guidance, and inspiration. Mentorship fosters a supportive environment where young athletes can learn from those who have navigated similar paths.",
    // },
    // {
    //   icon:'/images/icons/curriculum-icon-5.webp',
    // title:"Integration into Older Age Groups",
    // breifPara:"Occasionally The MAC Curriculum may integrate young athletes into older age groups for specific training sessions or competitive opportunities to challenge and...",
    // actualPara:"Occasionally The MAC Curriculum may integrate young athletes into older age groups for specific training sessions or competitive opportunities to challenge and further develop exceptional talents. This exposure ensures that young athletes are continually challenged and motivated to reach their highest potential.",
    // },
    // {
    //   icon:'/images/icons/curriculum-icon-6.webp',
    // title:"Sports Science and Performance Analysis",
    // breifPara:"The MAC Curriculum uses sports science and performance analysis tools to evaluate and enhance the physical and mental aspects of training for outstanding talents...",
    // actualPara:"The MAC Curriculum uses sports science and performance analysis tools to evaluate and enhance the physical and mental aspects of training for outstanding talents. Data-driven insights assist in refining training plans, tracking progress, and addressing areas in need of attention",
    // },
    {
      icon:'/images/icons/curriculum-icon-7.webp',
    title:"Holistic Development",
    breifPara:"While focusing on athletic prowess, MAC remains committed to the holistic development of young talents. Emphasis is placed on character-building, leadership skills...",
    actualPara:"While focusing on athletic prowess, MAC remains committed to the holistic development of young talents. Emphasis is placed on character-building, leadership skills, and a positive mindset to ensure a well-rounded and resilient athlete.",
    },
    {
      icon:'/images/icons/curriculum-icon-8.webp',
    title:"Regular Progress Assessments",
    breifPara:"Regular progress assessments are done continuously to track the development of exceptional talents. Adjustments to the development plan are made to ensure that the program evolves...",
    actualPara:"Regular progress assessments are done continuously to track the development of exceptional talents. Adjustments to the development plan are made to ensure that the program evolves in alignment with the athlete's growth and changing needs.",
    },
]

function Curriculam() {
    return (
        <div className="home-page">
           <Helmet>
            <title>
            NexGen Multi-Sport Curriculum: Youth Sports Education
            </title>
            <meta
              name="description"
              content="NexGen Multi-Sport curriculum for kids under 12. Fostering physical skills, social growth, and lifelong values."
            ></meta>
          </Helmet>
          <HeroBanner 
            subHeading=""
            Heading="THE CURRICULUM"
            nextLineSpanHeading=""
            Paragraph="The Nexgen Multi-Sport Curriculum"
            btn={false}
            btnText=""
            bgImgLg="/images/creatives/curriculum-hero-bg-lg.jpg"
            bgImg="/images/creatives/curriculum-hero-bg-mb.webp"
            btnRef="/"
            mobileText={false}
          />
          <RedPostBanner
            heading='TALENT IDENTIFICATION'
            content='We have adopted The MAC’s Multi-Sport Curriculum for kids aged 12 years and under. It is a comprehensive and innovative approach to youth sports education. The curriculum is designed to cater to the diverse developmental needs of children in this age group, fostering not only physical skills but also promoting social and cognitive growth. It encompasses a wide range of sports, allowing children to explore and discover their interests while developing fundamental athletic abilities.'
          />
      <div className='right-gradient'>
          <TextCard
            dropDown={true}
            heading="Multi-Sport Curriculum"
            content={"The MAC Multi-Sport Curriculum features age-appropriate activities, progressive skill development, and fosters a positive and inclusive environment. It emphasizes enjoyment, teamwork, sportsmanship, and character development. Coaches prioritize safety and well-being, creating a supportive atmosphere for children to learn and grow."}
            cardContent={cardContent}
            removeUpperSpace={true}
          />
         
        </div>
        <Footer/>
    </div>
      )
}

export default Curriculam