import React, { Fragment, useEffect, useState } from 'react';
import './LandingPage.css';
// import ImgContentSection from '../Image By Content Section/ImgContentSection';
import { Helmet } from 'react-helmet';
// import NavbarCS from '../HeroMenu/NavbarCS'; 
import PartnershipAwards from './Components/PartnershipAwards';
import LocationContent from './Components/LocationContent'; 
import BusinessGrowth from './Components/BusinessGrowth';
// import Contactform from '../Contact Form/Contactform';
// import Footer from '../Footer_/Footer';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
// import NotFound from '../../Pages/Page Not Found/NotFound';
import NotFound from '../../screens/NotFound';
import Footer from '../Footer/Footer';


import Header from '../Header/Header';
import TextContent from '../TextContent/TextContent';

const LocationPages = () => {
    const [locationPage, setLocationPage] = useState({});
    const [loading, setLoading] = useState(false);
    const {slug} = useParams();

    let locationDataCalled = false

    useEffect(() => {
        fetchLocationPageDataById()
    }, []);

    const fetchLocationPageDataById = () => {
        if(locationDataCalled) return;
        locationDataCalled = true;

        setLoading(true)
        axios.get(`/api/locationpages/url/${slug}`, {})
        .then((res) => {
            if (res.data.status === "success") {
                
                setLoading(false)
                setLocationPage(res.data.data)
            }else { setLoading(false)  }
        }).catch((err) => { setLoading(false) })
    }

    const MapHelmetMetaTags = () => {
        return(
            <Helmet>
                <title>{locationPage?.meta_title}</title>
                <meta name="keywords" content={locationPage?.meta_keywords} />
                <link rel="canonical" href={locationPage?.meta_link} />
                <meta name="description" content={locationPage?.meta_description} />
            </Helmet>
        )
    };

    const isEmpty = (obj) => { return Object.keys(obj).length === 0 };

    const LocationPageModule = () => {
        return ( 
            <div className='location_page_wrapper'>
                { MapHelmetMetaTags() }
                <Header
                    darkBg={true}
                />
                {/* <ImgContentSection
                    LogoSectionHeading={locationPage?.main_heading}
                    LogoSectionHeadingSpan=''
                    LogoSectionParagraph={locationPage?.main_paragraph}
                    LogoStripReq={false}
                    LogoSection={true}  
                    imgUrl={"/images/creatives/ecom-img-5.png"} 
                    reverse={'flex-row-reverse'}
                /> */}
                <TextContent
                    heading={locationPage?.main_heading}
                    content={locationPage?.main_paragraph}
                    img={"/images/creatives/home-text-1.png"}

                />
                <PartnershipAwards />
                <LocationContent heading={locationPage?.heading_1} paragraph={locationPage?.paragraph_1} />
                <LocationContent heading={locationPage?.heading_2} paragraph={locationPage?.paragraph_2} />
                <BusinessGrowth normalHeading={locationPage?.highlighted_heading} highlightedHeading={''} />
                <LocationContent heading={locationPage?.heading_3} paragraph={locationPage?.paragraph_3} />
                <LocationContent heading={locationPage?.heading_4} paragraph={locationPage?.paragraph_4} />
                {/* <Contactform TopSpace={false} /> */}
                <div style={{height:"50px"}}>

                </div>
                <Footer 
                    newsletterSection={false}
                />
            </div>
        )
    }

    return ( 
        <Fragment> 
            { loading ? <div className='w-100 text-center'> <Spinner style={{color: 'red', width: '120px', height: '120px'}} /> </div> : ( isEmpty(locationPage) ? <NotFound /> : LocationPageModule()) }
        </Fragment>
    )
};

export default LocationPages;