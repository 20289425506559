import React from 'react'
import "./DevelopmentBanner.css"

function DevelopmentBanner() {
  return (
    <div className='DevelopmentBanner-container '>
        <div className='container'>
            <div className='box center-text text-center d-flex flex-column justify-content-center align-items-center py-5'>
                
                <h3 className='body-heading ' style={{color:"white"}}>THE ART OF ATHLETE DEVELOPMENT AT THE MAC</h3>
                <img src='/images/icons/white-double-subline.png' style={{maxWidth:"90%",marginTop:"2px",marginBottom:"2px"}}/>
                <p className='body-paragraph w-75 mb-0' style={{color:"white"}}>At The  Multi-Sports Association Clubs of Canada (MAC) our commitment to athlete focused development lies at the heart of our mission. We recognize that each athlete is unique, and their journey to success is a personal one. Therefore, our programs are meticulously crafted to cater to the individual needs of every player, ensuring holistic growth both on and off the field.</p>
      
            </div>
        </div>
    </div>
  )
}

export default DevelopmentBanner