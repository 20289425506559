 
function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}


function CardBox(props) {
  const breifTitle = truncateText(props.title,50)
  return (
    <div className="card-box">
      <div className="w-100">
        <img src={props.cardImg} alt="someImage" />
        <div className="card-text-content">
          <div className="mb-0 mt-3 d-flex align-items-center gap-1"><img src="/images/icons/admin-icon.svg" className="admin-icon"/><p className="author mb-0" >{props.author}</p></div>
          <h5 className="title">{breifTitle}</h5>
        </div>
        <div className="line-seprator mx-auto my-3"></div>
      </div>
        <div className="blog-info d-flex align-items-center justify-content-between mb-3">
          <div className="mb-0 mt-1 d-flex align-items-center gap-1"><img src="/images/icons/calender-icon.svg" className="date-icon mb-0"/><p className="pb-date mb-0" >{props.publishDate}</p></div>
          <a className="read-more-anchor" onClick={()=>{props.redirectionFunction(props.anchor)}}>READ MORE</a>
        </div>
    </div>
  )
}

export default CardBox