import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import LeaderBoardList from '../component/LeaderBoardList/LeaderBoardList'
import { Helmet } from 'react-helmet'
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'

function LeaderBoard() {
    return (
        <>
         <Helmet>
            <title>
            NexGen Community Leaderboard: Top 100 Users & Rankings.
            </title>
            <meta
              name="description"
              content="Discover the top 100 users in NexGen Multi-Sport Association community, showcasing badges, points, and rankings. Join the elite league today!"
            ></meta>
          </Helmet>
              <HeroBanner 
                postSubHeading=""
                Heading="LEADERBOARD"
                nextLineSpanHeading=""
                Paragraph=''
                btn={false}
                btnText=""
                btnRef="/"
                bgImg="/images/creatives/leader-hero-bg-mb.jpg"
                bgImgLg="/images/creatives/leader-hero-bg-lg.jpg"
                mobileText={false}
              />
              <RedPostBanner
                heading="Because Numbers Don't Lie"
                content={`Introducing our new Leaderboard for Skills, designed to boost motivation and healthy competition among MAC Members! This dynamic ranking system evaluates young athletes in categories such as athleticism, speed, sports skills, and sportsmanship. By showcasing their progress and achievements, the leaderboard generates excitement, pride, and a tangible sense of accomplishment. It's a powerful tool that inspires our young athletes to continually improve and reach new heights. Join us in celebrating their success and fostering an environment of growth and excellence!`}
              />
          <div className='right-gradient'>
            <LeaderBoardList/>
                
    
            <Footer
                newsletterSection={false}
            />
            </div>
    
           
        </>
      )
}

export default LeaderBoard