import React from 'react'
import "./ServicesCarasoul.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules"
import ServiceCard from './serviceCard';

function ServicesCarasoul({
    heading="",
    content="",
    serviceData=[],
    subline=true,
    marginTopRemoval=false
}) {



    const handlePrevButtonClick = () => {
        const swiperInstance = document.querySelector('#service-cards-slider').swiper;
        swiperInstance.slidePrev();
      };
      
      const handleNextButtonClick = () => {
        const swiperInstance = document.querySelector('#service-cards-slider').swiper;
        swiperInstance.slideNext();
      };

      const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-3'>{paragraph}</p>);
  return (
    <div className='ServicesCarasoul-container standard-padding-space' 
        style={marginTopRemoval? {paddingTop:"0"}:{}}
    >
        <div className='container'>

            <div className='text-content text-center mb-3'>

                <h3 className='body-heading text-center mb-0'>{heading}</h3>
                {subline && <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"5px",marginBottom:"7px"}}/>}
                <p className='body-paragraph px-2'>{paragraphs}</p>


            </div>

        

                <Swiper
                    spaceBetween={60}
                    autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                    }}
                    loop={true}
                    // modules={[Autoplay]}
                    navigation={
                        {
                          nextEl: '.swiper-button-next',
                          prevEl: '.swiper-button-prev',
                        }
                        }
                    breakpoints={{
                        200:{
                        slidesPerView: 1,
                        },
                        1100: {
                        slidesPerView: 3,
                        }
                    }}
                    pagination={{ clickable: true }}
                    observer ={true}
                    observeParents={true}
                    parallax={true}
                    className=" my-5 px-3 px-md-5 py-4"
                    style={{position:"relative"}}
                    id="service-cards-slider"
                    >
                    
                    
                <div className="swiper-button-prev swiper-button d-lg-block d-none" onClick={handlePrevButtonClick}> 
                <img src="/images/icons/indus-carousel-arrow-left.svg" className="arrow-img"/>
                </div>
                {
                        serviceData.map((ele,ind)=>{
                        return(<>
                            <SwiperSlide>
                                <ServiceCard
                                    heading={ele.heading}
                                    content={ele.content}
                                />
                            </SwiperSlide>
        
                            </>)
                        })
                    }
                    <div className="swiper-button-next swiper-button d-lg-block d-none" onClick={handleNextButtonClick}>
                    <img src="/images/icons/indus-carousel-arrow-right.svg" className="arrow-img"/>
                    </div>
                </Swiper>
                </div>
    </div>

  )
}

export default ServicesCarasoul