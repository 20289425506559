import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import DevelopmentBanner from '../component/DevelopmentBanner/DevelopmentBanner'
import TextContent from '../component/TextContent/TextContent'

function Development() {
    return (
        <div className="home-page">
          <HeroBanner 
            subHeading=""
            Heading="DEVELOPMENT"
            nextLineSpanHeading=""
            Paragraph="Crafting Futures: he Art of Athlete Development at MAC"
            btn={false}
            btnText=""
            bgImgLg="/images/creatives/development-hero-bg-lg.jpg"
            bgImg="/images/creatives/development-hero-bg-mb.webp"
            btnRef="/"
            mobileText={false}
            bgSmallScreenWidth={true}
          />
          <DevelopmentBanner/>
      <div className='right-gradient'>
            <TextContent
                heading= "Individualized Player Development"
                subHeading=""
                content={"We tailor training sessions to each child's strengths and improvement areas, working closely with experienced coaches to provide personalized feedback for continuous performance improvement."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={true}
                marginTop={true}
                img="/images/creatives/development-text-0.1.jpg"
                // img="/images/creatives/development-text-0.5.jpg"
                reviewContent={false}
                sublineIcon={true}
            />
            <div className='container text-center py-5'>
                <div className='my-5'>
                    <h2 className='body-heading'>Skill Progression Pathways</h2>
                    <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"2px",marginBottom:"5px"}} className='my-3'/>
                    <p className='body-paragraph'>The Multi-Sport Association Clubs of Canada (MAC) offer clear skill progression pathways, guiding children from foundational skills to advanced techniques, aligning with their age, experience, and aspirations.</p>
                </div>
            </div>
            {/* <TextContent
                heading= "Skill Progression Pathways"
                subHeading=""
                content={"The Multi-Sport Association Clubs of Canada (MAC) offer clear skill progression pathways, guiding children from foundational skills to advanced techniques, aligning with their age, experience, and aspirations."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                marginTop={true}
                // img="/images/creatives/development-text-2.webp"
                img="/images/creatives/development-text-0.2.jpg"
                reviewContent={false}
                sublineIcon={true}
            /> */}
            <TextContent
                heading= "Life Skills Integration"
                subHeading=""
                content={"Beyond the sports arena, we are committed to providing children with essential life skills. Leadership, communication, and teamwork are fundamental to our development approach, preparing child athletes for success in their sporting pursuits and broader personal and professional lives."}
                doubleContent={{
                    heading:"Mentorship Programs",
                    content:"Exceptional talents benefit from mentorship programs, connecting them with experienced athletes and coaches to provide insights, guidance, and inspiration. Mentorship fosters a supportive environment where young athletes can learn from those who have navigated similar paths."
                }}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                marginTop={true}
                // img="/images/creatives/development-text-3.webp"
                img="/images/creatives/development-text-0.4.jpg"
                reviewContent={false}
                sublineIcon={false}
                foldableText={true}
            />
            <TextContent
                heading= "Mental & Physical Well-being"
                subHeading=""
                content={"Understanding the importance of a balanced approach to athletic development, we emphasize mental and physical well-being. Our programs incorporate sports psychology elements to enhance mental resilience, focus, and confidence alongside rigorous physical training routines."}
                doubleContent={{
                    heading:"Sports Science & Performance Analysis",
                    content:"The MAC Curriculum uses sports science and performance analysis tools to evaluate and enhance the physical and mental aspects of training for outstanding talents. Data-driven insights assist in refining training plans, tracking progress, and addressing areas in need of attention"
                }}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={true}
                marginTop={true}
                img="/images/creatives/development-text-0.2.jpg"
                reviewContent={false}
                sublineIcon={false}
                foldableText={true}
            />
           
            <div className='left-gradient'>
            <TextContent
                heading= "Club Cohesion & Team Building"
                subHeading=""
                content={"We understand the importance of teamwork in creating a supportive and motivating atmosphere. Our programs include team-building activities to promote club cohesion, allowing players to develop their skills and form strong bonds with their teammates. A unified team is essential for success."}
                doubleContent={{
                    heading:"Integration into Older Age Groups",
                    content:"Occasionally The MAC Curriculum may integrate young athletes into older age groups for specific training sessions or competitive opportunities to challenge and further develop exceptional talents. This exposure ensures that young athletes are continually challenged and motivated to reach their highest potential."
                }}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                marginTop={true}
                // img="/images/creatives/development-text-2.webp"
                img="/images/creatives/development-text-0.3.jpg"
                reviewContent={false}
                sublineIcon={false}
                foldableText={true}
            />
            
            <TextContent
                heading= "Continuous Coach Education"
                subHeading=""
                content={"Our dedication to player-focused development also extends to our coaching staff. The Multi-Sports Association Clubs of Canada (MAC) invests in ongoing coach education to ensure that our coaching team stays updated with industry best practices. This dedication to excellence improves the quality of coaching and, subsequently, enhances the development experience for every child athlete."}
                doubleContent={{
                    heading:"Enrichment Classes, Intensive Skill Clinics & Workshops",
                    content:"The MAC Curriculum offers semi-private enrichment classes, Intensive Skill Clinics, and Workshops, led by expert coaches to provide young athletes with specialized training to accelerate their growth. Additionally, workshops conducted by industry experts support and encourage parents in their children's athletic journey."
                }}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={true}
                marginTop={true}
                // img="/images/creatives/development-text-5.webp"
                img="/images/creatives/development-text-0.5.jpg"
                reviewContent={false}
                sublineIcon={false}
                foldableText={true}
            />
            {/* <TextContent
                heading= "Continuous Coach Education"
                subHeading=""
                content={"Our dedication to player-focused development also extends to our coaching staff. The Multi-Sports Association Clubs of Canada (MAC) invests in ongoing coach education to ensure that our coaching team stays updated with industry best practices. This dedication to excellence improves the quality of coaching and, subsequently, enhances the development experience for every child athlete."}
                doubleContent={{
                    heading:"",
                    content:""
                }}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                marginTop={true}
                // img="/images/creatives/development-text-4.webp"
                img="/images/creatives/development-text-0.5.jpg"
                reviewContent={false}
                sublineIcon={true}
            />


            <TextContent
                heading= "Enrichment Classes, Intensive Skill Clinics & Workshops:"
                subHeading=""
                content={"The MAC Curriculum offers semi-private enrichment classes, Intensive Skill Clinics, and Workshops, led by expert coaches to provide young athletes with specialized training to accelerate their growth. Additionally, workshops conducted by industry experts support and encourage parents in their children's athletic journey."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={true}
                marginTop={true}
                // img="/images/creatives/development-text-1.webp"
                img="/images/creatives/development-text-0.2.jpg"
                reviewContent={false}
                sublineIcon={true}
            />
            <TextContent
                heading= "Mentorship Programs:"
                subHeading=""
                content={"Exceptional talents benefit from mentorship programs, connecting them with experienced athletes and coaches to provide insights, guidance, and inspiration. Mentorship fosters a supportive environment where young athletes can learn from those who have navigated similar paths."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                marginTop={true}
                // img="/images/creatives/development-text-1.webp"
                img="/images/creatives/development-text-0.2.jpg"
                reviewContent={false}
                sublineIcon={true}
            />
            <TextContent
                heading= "Integration into Older Age Groups:"
                subHeading=""
                content={"Occasionally The MAC Curriculum may integrate young athletes into older age groups for specific training sessions or competitive opportunities to challenge and further develop exceptional talents. This exposure ensures that young athletes are continually challenged and motivated to reach their highest potential."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={true}
                marginTop={true}
                // img="/images/creatives/development-text-1.webp"
                img="/images/creatives/development-text-0.3.jpg"
                reviewContent={false}
                sublineIcon={true}
            />
            <TextContent
                heading= "Sports Science and Performance Analysis:"
                subHeading=""
                content={"The MAC Curriculum uses sports science and performance analysis tools to evaluate and enhance the physical and mental aspects of training for outstanding talents. Data-driven insights assist in refining training plans, tracking progress, and addressing areas in need of attention"}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                marginTop={true}
                // img="/images/creatives/development-text-1.webp"
                img="/images/creatives/development-text-0.4.jpg"
                reviewContent={false}
                sublineIcon={true}
            /> */}
            </div>
        </div>
       
        <Footer/>
    </div>
      )
}

export default Development