import React from 'react'
import "./DropDownContent.css"
import DropDownBox from "./DropDownBox"

const dropdownData = [
  {
    "title": "Spiral Skill Integration",
    "content": "The Spiral Approach to Learning is seamlessly integrated into Skill building sessions. Each session revisits and reinforces fundamental skills, ensuring a continuous and progressive development journey for every participant."
  },
  {
    "title": "Personal Attention Coaching",
    "content": "Our coaches adopt an individualized coaching approach within the skill-building sessions, recognizing the diverse needs of young athletes. This personalized attention empowers each child to thrive at their own pace, fostering confidence and a sense of accomplishment."
  },
  {
    "title": "Skill Application in Game Settings",
    "content": "House League games are platforms for applying learned skills in real-game settings. This practical application enhances the learning experience, allowing athletes to understand the relevance of their training and reinforcing the joy of competition."
  },
  {
    "title": "Parental Involvement",
    "content": "The MAC encourages parental involvement in House Leagues. Parents play a vital role in supporting their child's journey, creating a collaborative and nurturing community beyond the playing field."
  }
]




function DropDownContent({
    shiftRow=false,
    img,
    mobileTextCenter=true
  }) {
    return (
      <div className='drop-down-content-section standard-padding-space'>
        <div className='container'>
          <div className={`row d-flex ${shiftRow && "flex-row-reverse"} align-items-center gy-2`} >
            <div className={`col-lg-6 px-0 d-flex align-items-center justify-content-center ${shiftRow ? "justify-content-lg-end" : "justify-content-lg-start"}`}>
              <img src={img} className='img-fluid'/>
            </div>
            <div className='col-lg-6 px-3 py-3' style={{background:"#ECECEC",height:"auto"}}>
              <div className={`text-content ${mobileTextCenter && "text-center "} `}>
                <h3 className='body-heading' style={{fontSize:"22px"}}>How The MAC Unique Approach Works in House League</h3>
                
              {
                    dropdownData.map((ele,ind)=>{
                        return(
                            <div className='col-12'>
                                <DropDownBox title={ele.title} content={ele.content} />
                                {
                                  ind !== dropdownData.length-1 
                                  ?
                                  <div className='line-seprator'></div>
                                  :
                                  <></>
                                }
                            </div>
                        )
                    })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

export default DropDownContent