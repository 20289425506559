import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import TextCard from '../component/TextCard/TextCard'
import { Helmet } from 'react-helmet'
import RedPostBanner from '../component/RedPostBanner/RedPostBanner'

const cardContent = [
    {
    title:"Reduced Risk of Overuse Injuries",
    icon:"/images/icons/advan-card-1.svg",
    actualPara:"LTAD minimizes the risk of overuse injuries by avoiding early specialization. Athletes participate in various activities, enabling their bodies to develop multiple physical skills and reducing the strain associated with repetitive movements.",
    },
    {
    title:"Well-Rounded Individuals",
    icon:"/images/icons/advan-card-2.svg",
    actualPara:"LTAD shapes exceptional athletes and well-rounded individuals. Athletes who experience a holistic developmental journey are better equipped with life skills such as teamwork, resilience, and leadership, fostering success beyond the sports arena.",
    },
    {
    title:"Long-Term Athletic Success",
    icon:"/images/icons/advan-card-3.svg",
    actualPara:"By focusing on long-term development, The MAC's LTAD program prepares multi-sport athletes for sustained success. The program emphasizes building a strong foundation, both physically and mentally, which contributes to athletes reaching their peak performance levels over the course of their careers.",
    },
    {
    title:"Enhanced Skill Transfer",
    icon:"/images/icons/advan-card-4.svg",
    actualPara:"Exposure to multiple sports enhances skill transferability. Athletes develop a diverse set of motor skills, cognitive abilities, and strategic thinking that can be applied across different sports, creating a more versatile and adaptable skill set that inspires potential.",
    },
]


function Advantage() {
    return (
        <div className="home-page">
           <Helmet>
            <title>
            Innovative Approach to Developing Young Athletes | NXGMSA
            </title>
            <meta
              name="description"
              content="Discover MAC Canada's Spiral Approach to Learning, revolutionizing youth sports development. Empower your child with a holistic athletic journey for lifelong success."
            ></meta>
          </Helmet>
          <HeroBanner 
            subHeading=""
            Heading="Advantages"
            nextLineSpanHeading=""
            Paragraph="Advantages of LTAD for Multi-Sport Athletes"
            btn={false}
            btnText=""
            bgImgLg="/images/creatives/advan-hero-bg-lg.jpg"
            bgImg="/images/creatives/advan-hero-bg-mb.webp"
            btnRef="/"
            mobileText={false}
          />
          <RedPostBanner
            heading='ADVANTAGES OF LTAD FOR MULTI-SPORT ATHLETES'
            content='The MAC is dedicated to supporting multi-sport athletes with its Long-Term Player Athlete commitment. This dedication aims to develop well-rounded individuals who excel not only in sports but also in all aspects of life. The success of our athletes reflects the benefits of this approach, as they demonstrate adaptability and enduring success in their endeavors.'
          />
      <div className='right-gradient'>
          {/* <TextCard
            dropDown={true}
            redDropDown={false}
            heading=""
            content={""}
            subLine={false}
            cardContent={cardContent}
            removeUpperSpace={true}
          /> */}
          <TextCard
             heading=""
             content=""
             dropDown={false}
             cardContent={cardContent}
             subLine={false}
             marginTop={true}
             removeUpperSpace={true}
             isBottomSection={false}
             divisonSize={6}
          />

        {/* <div className='advan-centered-text'>
          <div className=' container text-center'>
            <p className='body-paragraph'>In conclusion, The Multi-Sports Association of Canada (MAC)’s commitment to Long-Term Player Athlete for multi-sport athletes reflects a dedication to cultivating not only high-performance athletes but also individuals poised for success in all facets of life. The advantages of this approach are evident in the well-rounded, adaptable, and enduring success of our athletes.</p>
          </div>
        </div> */}
         
        </div>
        <Footer/>
    </div>
      )
}

export default Advantage