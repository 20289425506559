import React from 'react'
import "./EventsContent.css"


const eventData = [
    {
        catagoryNum:0,
        // thumb:'/images/creatives/event-thumb-1.jpg',
        thumb:'/images/creatives/event-thumb-0.1.jpg',
        date:"November 17, 2023",
        name : 'Zoltan Tenke Classic',
        location : "Gryphon Fieldhouse – Guelph, ON",
        content : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        catagoryNum:0,
        thumb:'/images/creatives/event-thumb-2.jpg',
        date:"November 17, 2023",
        name : 'Zoltan Tenke Classic',
        location : "Gryphon Fieldhouse – Guelph, ON",
        content : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        catagoryNum:0,
        // thumb:'/images/creatives/event-thumb-3.jpg',
        thumb:'/images/creatives/event-thumb-0.3.jpg',
        date:"November 17, 2023",
        name : 'Zoltan Tenke Classic',
        location : "Gryphon Fieldhouse – Guelph, ON",
        content : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        catagoryNum:3,
        // thumb:'/images/creatives/event-thumb-3.jpg',
        thumb:'/images/creatives/event-thumb-0.3.jpg',
        date:"November 17, 2023",
        name : 'Zoltan Tenke Classic',
        location : "Gryphon Fieldhouse – Guelph, ON",
        content : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        catagoryNum:2,
        // thumb:'/images/creatives/event-thumb-1.jpg',
        thumb:'/images/creatives/event-thumb-0.1.jpg',
        date:"November 17, 2023",
        name : 'Zoltan Tenke Classic',
        location : "Gryphon Fieldhouse – Guelph, ON",
        content : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        catagoryNum:4,
        thumb:'/images/creatives/event-thumb-2.jpg',
        date:"November 17, 2023",
        name : 'Zoltan Tenke Classic',
        location : "Gryphon Fieldhouse – Guelph, ON",
        content : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
]

function EventsContent({selectedCatagory,content="",title=""}) {
  return (
    <div className='EventsContent-container standard-margin-space'>
        <div className='container'>
            <div className='text-content text-center'>
                {/* <p className='body-paragraph'>Current 2023 – 2024 Competition Calendar last updated Nov 30.</p>
                <p className='body-paragraph'> Only track and field competitions that are sanctioned by Athletics Ontario/Athletics Canada will be listed on this event list. More information on sanctioning and can be found here.</p>
                <br></br>
                <p className='body-paragraph'>For a list of this year’s Athletics Ontario Championships click here</p>
                <p className='body-paragraph'>Full list of World Athletics sanctioned competitions can be found here.</p>
                <p className='body-paragraph'>World Para Athletics endorsed/sanctioned competitions in Canada can be found here.</p>
                <br></br> */}
                <h3 className='body-heading my-3'>{title}</h3>
                <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"2px"}}/>
                <p className='body-paragraph mt-4'>{content}</p>

            </div>

            {
                eventData.map((ele,ind)=>{
                    if(ele.catagoryNum == selectedCatagory)
                    {
                        return(
                        <>
                        <div className='row align-items-center gy-3 my-2'>
                        <div className='col-lg-5'>
                            <img src={ele.thumb} className='img-fluid card-img' />
                            </div>
                            <div className='col-lg-7'>
                                <div className='card-content'>
                                    <div className='top'>
                                        <div className='d-flex gap-1'>
                                            <p className='card-date'>
                                            {ele.date} | 
                                            </p>
                                            <p className='card-name'>
                                            {ele.name}
                                            </p>
                                        </div>
                                    </div>

                                    <div className='mid'>
                                        <div className='d-flex align-items-center gap-2'>
                                            <img src='/images/icons/card-loc.svg' />
                                            <p className='card-location mb-0'>{ele.location}</p>
                                        </div>
                                    </div>
                                    
                                    <div className='end'>
                                        <p className='body-paragraph my-4'>
                                            {ele.content}
                                        </p>
                                        <button className='genral-btn'>
                                            REGISTERED NOW
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {
                            ind < (eventData.length-2) && <div className='line-seprator mt-4'></div>
                        }
                       </>
                        )
                    }
                    
                })
            }
            

        </div>
    </div>
  )
}

export default EventsContent