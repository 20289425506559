import React from 'react'
import "./TextCard.css"
import TextCardBox from './TextCardBox'
import TextCardBoxSimple from './TextCardBoxSimple';


function TextCardSimpleBox({
    title,
    actualPara
})
{
    return (
        <div
          className="text-card-box p-4 px-5 d-flex flex-column align-items-start"
          
        >
          <div className="content-info w-100 d-flex gap-3 align-items-center justify-content-center text-center my-3">
            <h5 className="title mx-auto" style={{color:'#002952'}}>{title}</h5>
          </div>
          <p
            className={`body-paragraph text-center`}
          >
            {actualPara}
          </p>
        </div>
      );
}


function TextCard({
    dropDown=true,
    heading="",
    content="",
    cardContent=[],
    subLine=true,
    marginTop=false,
    redDropDown=true,
    isBottomSection=!dropDown,
    removeUpperSpace=false,
    divisonSize=4
}) {
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-3'>{paragraph}</p>);
  return (
    <div className={`text-card-container standard-padding-space ${removeUpperSpace && "pt-0"} `}  style={marginTop ? {paddingTop:0}:{}}>
        <div className='container '>
            <div className='text-content text-center'>
                <p className='sub-heading'></p>
                <h3 className='body-heading'>{heading}</h3>
                {subLine && <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"2px"}}/>}
                <p className='body-paragraph px-3 mt-2'>{paragraphs}</p>
            </div>
            <div className='container-fluid pt-3'>
                <div className='row gy-xl-4  gy-3 justify-content-center'>
                    {
                        cardContent.map((ele)=>{
                            return(
                            <>
                                {
                                    dropDown?
                                    redDropDown?
                                    <div className='col-lg-6 col-12'>
                                        <TextCardBox
                                        icon={ele.icon}
                                            title={ele.title}
                                            breifPara={ele.breifPara}
                                            actualPara={ele.actualPara}
                                        />
                                    </div>
                                    :
                                    <div className='col-lg-6 col-12'>
                                      <TextCardBoxSimple
                                        title={ele.title}
                                        actualPara={ele.actualPara}
                                        icon={ele.icon}
                                      />
                                    </div>
                                    :
                                    <>
                                    <div className={`col-lg-${divisonSize} col-12`}>
                                    <TextCardSimpleBox
                                        title={ele.title}
                                        actualPara={ele.actualPara}
                                    />
                                    </div>
                                    
                                    </>
                                }
                                </>
                            )
                        })
                    }
                    {
                      (!dropDown && isBottomSection) &&
                    <div className='col-lg-10 col-12'>
                      <div
                        className="text-card-box p-4 px-5 d-flex flex-column align-items-start"
                        
                      >
                        <div className="content-info w-100 d-flex gap-3 align-items-center justify-content-center text-center my-3">
                          <h5 className="title mx-auto" style={{color:'#002952'}}>Long-Term Athletic Development</h5>
                        </div>
                        <p
                          className={`body-paragraph text-center`}
                        >
                          Multi-sport development aligns with the principles of long-term athletic development (LTAD), emphasizing age-appropriate activities that promote overall well-being, skill acquisition, and sustained participation in sports throughout life.
                        </p>
                        {/* <p
                          className={`body-paragraph text-center`}
                        >
                          While multi-sport development offers numerous benefits, it's essential to consider individual preferences and interests. Some children may show a strong passion for a specific sport at an early age, and in such cases, a balanced approach that accommodates their interests and the principles of LTAD is crucial. Ultimately, the goal is to foster a positive and sustainable relationship with sports that promotes lifelong physical activity and well-being.
                        </p> */}
                      </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default TextCard