import React,{useState} from 'react'
import "./TextCard.css"

function TextCardBox({
  icon = '/images/icons/curriculum-star-icon.svg',
    title,
    breifPara,
    actualPara
}) {
    const [dropOpen , setDropOpen] = useState(false)
  return (
    <div
      className="text-card-box p-2 px-3 d-flex flex-column align-items-start"
      onClick={() => {
        setDropOpen((prev) => !prev);
      }}
    >
      <div className="content-info d-flex gap-3 align-items-center justify-content-start my-3">
        <img src={icon} style={{width:"40px", height:"40px"}}/>
        <h5 className="title mb-0">{title}</h5>
      </div>
      <p
        className={`body-paragraph ${dropOpen ? "d-none" : "d-block close"}`}
      >
        {breifPara}
      </p>
      <p
        className={`body-paragraph ${dropOpen ? "d-block open" : "d-none"}`}
        style={{ lineHeight: "1.8rem" }}
      >
        {actualPara.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-2'>{paragraph}</p>)}
      </p>
      <button className='genral-btn align-self-end mx-auto mx-lg-1 my-3'>
        READ MORE
      <img
        src={
          dropOpen
            ? "/images/icons/read-more-arrow-up.png"
            : "/images/icons/read-more-arrow-down.png"
        }
        className="arrow-icon"
        alt='arrow icon'
      />
      </button>
      {/* <img
        src={
          dropOpen
            ? "/images/icons/curriculum-card-arrow-open.svg"
            : "/images/icons/curriculum-card-arrow-close.svg"
        }
        className="align-self-end my-3"
        style={{ cursor: "pointer" }}
      /> */}
    </div>
  );
}

export default TextCardBox