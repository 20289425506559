import React, { useState } from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import VisionMission from '../component/VisionMission/VisionMission'
import RedPostBanner from "../component/RedPostBanner/RedPostBanner"

function About() {
  return (
    <div className="home-page">
      <HeroBanner 
        subHeading=""
        Heading="About Us"
        nextLineSpanHeading=""
        Paragraph="Where Young Sports Dreams Come to Life"
        btn={false}
        btnText=""
        // bgImgLg="/images/creatives/about-hero-bg-lg.jpg"
        bgImg="/images/creatives/about-hero-bg-mb.webp"
        bgImgLg="/images/creatives/about-hero-bg-lg.jpg"
        btnRef="/"
        mobileText={false}
      />
      <RedPostBanner
        subHeading=''
        heading='Multi-Sports Association Clubs of Canada'
        subLine={true}
        content='The MAC athlete development model for children aged 10 and under recognizes the multidimensional nature of athletic growth. By incorporating talent identification through multi-sport development, long-term athlete development, sports nutrition, and athlete intelligence, this model aims to produce well-rounded athletes who not only excel in their chosen sport but also develop essential life skills that extend beyond the athletic arena.'
      />
  <div className='left-gradient'>
      
      <TextContent
         heading= "Dear  MAC Parents"
         subHeading="Welcome to the MAC"
         content={"At the MAC, we truly believe that every young athlete, regardless of their age or skill level, deserves the opportunity to enjoy the fun of sports and grow as both participants and individuals. Guided by the principles of Long-Term Athlete Development (LTAD), our goal is to create an environment where young athletes can learn and participate in sports at levels that match their developmental stage. This ensures a fulfilling and lasting experience throughout their journey, from Grassroots Sports to the Talented Identification Pathway. We are here to fully support and empower your child's sports development while fostering a lifelong love for the game."}
         btn={false}
         btnText=""
         btnRef='/'
         shiftRow={true}
        //  img="/images/creatives/about-text-main.webp"
         img="/images/creatives/about-text-main-0.1.jpg"
         reviewContent={true}
         reviewPara={"Multi-Sports Association Clubs of Canada"}
         reviewHeading={"Sincerely,"}
         marginTop={true}
      />
      
  <VisionMission/>
</div>
<div className='left-gradient-upper'>
    {/* <Sponsor/> */}
<div className='right-gradient'> 
    <div className='about-text-content standard-padding-space' style={{backgroundColor:"#f6f6f6"}}>
        <div className='container'>
            <div className='center-text text-center d-flex flex-column justify-content-center align-items-center my-3'>
            
                <h3 className='body-heading '>THE MAC ATHLETE DEVELOPMENT MODEL</h3>
                <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"2px",marginBottom:"2px"}}/>
                <p className='body-paragraph w-75'>The MAC's athlete development model for children under ten is a holistic approach that offers numerous benefits for nurturing young athletes. Structured around four pillars, the model contributes to well-rounded development: talent identification through multi-sport development, long-term athlete development, sports nutrition for kids, and athlete intelligence.</p>

            </div>
           

            <TextContent
                heading= "TALENT IDENTIFICATION"
                subHeading=""
                content={"The first pillar focuses on identifying and developing talents through exposure to various sports. Children are encouraged to engage in multiple sports instead of specializing in a single sport at an early age. This approach helps discover their inherent abilities and interests, prevents early burnout and overuse injuries, and provides a more comprehensive understanding of physical abilities and preferences."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={true}
                // img="/images/creatives/about-text-4.webp"
                img="/images/creatives/about-text-0.1.png"
                reviewContent={false}
                sublineIcon={false}
            />
            <TextContent
                heading= "LTAD"
                subHeading=""
                content={"The second pillar of Long-Term Athlete Development (LTAD) emphasizes a gradual and progressive approach to athlete development. Instead of intense and specialized training at a young age, the MAC's model advocates for age-appropriate training that evolves as the child grows physically and mentally. Diversifying sports activities helps children acquire various skills and physical attributes, contributing to overall athletic development."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                // img="/images/creatives/about-text-3.webp"
                img="/images/creatives/about-text-0.2.png"
                reviewContent={false}
                sublineIcon={false}
            />
            <TextContent
                heading= "NUTRITION"
                subHeading=""
                content={"The third pillar sports nutrition for kids emphasized in The MAC's model is designed to educate and involve parents, coaches, and young athletes in the third pillar, Proper nutrition. It's critical for young athletes to have balanced and nutritious diets to support their growth, energy, and recovery. The MAC's model provides guidance on healthy eating habits, hydration, and the role of nutrients in physical and mental well-being."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={true}
                // img="/images/creatives/about-text-2.webp"
                img="/images/creatives/about-text-0.3.png"
                reviewContent={false}
                sublineIcon={false}
            />
            <TextContent
                heading= "ATHLETE INTELLIGENCE"
                subHeading=""
                content={"The fourth pillar, athlete intelligence, encompasses the mental and cognitive aspects of athletic development, including understanding the body, emotions, goal-setting, self-discipline, resilience, and decision-making both on and off the field, with coaches playing a critical role."}
                btn={false}
                btnText=""
                btnRef='/'
                shiftRow={false}
                // img="/images/creatives/about-text-1.webp"
                img="/images/creatives/about-text-0.4.png"
                reviewContent={false}
                sublineIcon={false}
            />

            {/* <div className='center-text text-center d-flex flex-column justify-content-center align-items-center my-3'>
                
                <h3 className='body-heading '>SPORT TALKS:</h3>
                <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"2px",marginBottom:"2px"}}/>
                <p className='body-paragraph w-75'>The Multi-Sports Association Clubs of Canada (MAC) provides faith-centered discussion groups called SPORTS TALKS for families seeking a spiritual experience through sports. These groups foster a sense of community and inclusivity, welcoming participation from all, with the understanding that the discussions will have a spiritual focus.</p>
                
            </div> */}
        </div>
    </div>
</div>
</div>
<div className='right-gradient'>
      
      <CardCarousel
        subHeading="NEWS & FEEDS"
        heading="OUR POPULAR NEWS & BLOGS"
        content={""}
        />

        <Testimonials/>

      <Footer
        newsletterSection={false}
      />

      </div>
</div>
  )
}

export default About