import React from 'react'
import "./TextCard.css"

function TextCardBoxSimple({
    title,
    actualPara,
    icon
}) {
  return (
    <div
      className="text-card-box p-2 px-3 d-flex flex-column align-items-start"
      
    >
      <div className="content-info d-flex gap-3 align-items-center justify-content-start my-3">
        <img src={icon} />
        <h5 className="title mb-0">{title}</h5>
      </div>
      
      <p
        className={`body-paragraph `}
        style={{ lineHeight: "1.8rem" }}
      >
        {actualPara}
      </p>
      
    </div>
  );
}
export default TextCardBoxSimple