import React from 'react'
import "./LeaderBoardList.css"

const LeaderBoardData = [
    {
        ranking:"1",
        userImg:"/images/icons/user-img.png",
        user:'Cameron Steward',
        post:"300008",
        rank:"NEW FACE",
        badgeCount:6,
    },
    {
        ranking:"2",
        userImg:"/images/icons/user-img.png",
        user:'Cameron Steward',
        post:"300008",
        rank:"NEW FACE",
        badgeCount:6,
    },
    {
        ranking:"3",
        userImg:"/images/icons/user-img.png",
        user:'Cameron Steward',
        post:"300008",
        rank:"NEW FACE",
        badgeCount:6,
    },
]

function LeaderBoardList() {
  return (
    <div className='LeaderBoardList-container standard-padding-space pt-0 pt-lg-auto'>
        <div className='container'>
        <div className='text-content text-center d-flex flex-column justify-content-center align-items-center my-3'>
                    <p className='sub-heading'>LEADERBOARD</p>
                    <h3 className='body-heading px-3'>WHO ARE THE TOP 100 KIDS?</h3>
                    <img src='/images/icons/double-subline.svg' style={{maxWidth:"90%",marginTop:"4px",marginBottom:"4px"}}/>
                    {/* <p className='body-paragraph w-75 my-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                    
                    <div className='my-4 container-fluid'>
                        <div className='row'>

                            <div className=' col-3 col-sm-2'>
                                <h3 className='head-text'>
                                    Ranking
                                </h3>
                            </div>
                            <div className=' col-3 col-sm-3'>
                                <h3 className='head-text'>
                                    User
                                </h3>
                            </div>
                            <div className=' col-sm-2 col-3'>
                                <h3 className='head-text'>
                                    Points
                                </h3>
                            </div>
                            <div className=' col-sm-2 col-3'>
                                <h3 className='head-text'>
                                    Rank
                                </h3>
                            </div>
                            <div className=' col-12 col-sm-2'>
                                <h3 className='head-text'>
                                    Badges
                                </h3>
                            </div>
                            <div className='col-xxl-1 col-12 my-2'>
                                <button className='list-toogle-btn'>
                                Boys U18
                                </button>
                            </div>

                        </div>

                        {
                            LeaderBoardData.map((ele) => {
                                return (
                                    <div className='list-box row align-items-center my-3'>

                                        <div className=' col-sm-2 col-3'>
                                            <div className='d-flex align-items-center gap-2'>
                                                <img src='/images/icons/ranking-medal.png' className='mx-1' />
                                                <p className='mb-0 normal-txt'>{ele.ranking}</p>
                                            </div>
                                        </div>
                                        <div className=' col-sm-3 col-3'>
                                            <div className='d-flex align-items-center flex-column flex-md-row gap-2'>
                                                <img src={ele.userImg} />
                                                <p className='mb-0 bold-txt'>{ele.user}</p>
                                            </div>
                                        </div>
                                        <div className=' col-sm-2 col-3'>
                                            <p className='normal-txt mb-0'>
                                                {ele.post}
                                            </p>
                                        </div>
                                        <div className=' col-sm-2 col-3'>
                                            <p className='normal-txt mb-0'>
                                                {ele.rank}
                                            </p>
                                        </div>
                                        <div className=' col-sm-3 col-12 '>
                                            <div className='d-flex flex-wrap justify-content-center my-3 my-sm-0'>
                                                {
                                                    Array.from({ length: ele.badgeCount }, (_, index) => (
                                                        <img className='badge-icon' key={index} src={`/images/icons/badge-${index + 1}.svg`} />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }

                        
                        <div className='row'>
                            <button className='genral-btn w-100'>LEARN MORE</button>
                        </div>
                    </div>


                    
                </div>
        </div>

    </div>
  )
}

export default LeaderBoardList