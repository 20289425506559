import React from 'react';
import './Agility.css';

const Agility = () => {

    const agilityData = [
        {
          title: 'Pro Agility (PAT)',
          description: 'Test your agility and change of direction abilities with the Pro Agility test.This test is performed twice, once starting with a left turn and then with a right turn.',
        },
        {
            title: '3-Cone Drill (L Drill)',
            description: 'Improve your speed and agility with the 3-Cone Drill. Starting in a three-point stance, participants run straight distances, touching the line before each change of direction.',
        },
        {
            title: 'T-Test',
            description: 'The T-Test challenges your speed, agility, and change of direction abilities. The test is performed two times, starting from each direction.',
        },
        {
            title: 'Edgren Side Step Test',
            description: 'The Edgren Side Step Test focuses on evaluating your lateral agility and quickness and captures essential measurements providing valuable feedback to enhance your lateral agility performance.',
        },
        {
            title: '30-Second Reaction Agility Test',
            description: 'The 30-Second Reaction Agility Test challenges your reaction time and agility skills. This provides valuable measurements enabling you to refine your reaction speed and agility.',
        },
        {
            title: 'Testing Your Limits',
            description: 'Tests such as the Beep, Rhiet test and the 300-yard shuttle run, to name a few, are among the assessments we use to gauge your endurance capacity.',
        },
    ];

    return (
        <div className='agility_test' style={{backgroundImage: `url('/images/creatives/agility-bg.jpg')`}}>
            <div className='container'>
                <div className='agility_details'> 
                    <h1 className='font-mohave'>Accelerate your game with <br/> improved Reactive speed </h1>
                    <div className='text-horizontal-line'></div>
                </div>

                <div className='agility_wrapper'>
                    <div className='row'>
                        {agilityData.map((item, index) => (
                            <div key={index} className="col-lg-4 col-md-6 col-12 mb-3">
                                <div className="agility_card">
                                    <h3>{item.title}</h3>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Agility;
