import React, { Fragment } from 'react';
import './ImgContent.css';

// props: flex-row-reverse for reversing the order of rows

const ImgContent = ({imgUrl, heading, reverse, iconLogoParaReq, iconLogoParaArray, listItemsReq, listItems, logoItemsReq, logoItems, subHeading, paragraph, breakPara, buttonReq, btnText}) => {
  return (
    <div className='parent_img_cn_common'>
      <div className={`img_by_cn_section_common`}>
        <div className='container-fluid px-0 overflow-x-hidden'>
          <div className={`row m-0 ms-0 me-0 ${reverse} gx-5 align-items-center`}>
            <div className={`col-lg-6 mb-4 mb-lg-0 col-md-12 col-sm-12 p-0  text-center`}>
              <img className='img-fluid content_img px-0 p-0' src={imgUrl} alt='content_img' />
            </div>
            <div className='col-lg-5 col-md-12 col-sm-12 px-0'>
              <div className='container'>
                <div className='row'>
                  <div className={`col-lg-12 col-md-12 col-12`}> 
                    {subHeading && <h5 className='sub-heading'>{subHeading}</h5>}

                    <h3 className='mb-4 body-heading'>{heading}</h3>

                    <div className='text-horizontal-line mb-4'></div>
                    
                    {paragraph && <p className='body-paragraph'>{paragraph}</p>}

                    {listItemsReq && (
                      <ul className={`ul-items ${listItems.length > 4 ? 'multi-column' : ''}`}>
                        {listItems.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </ul>
                    )}

                    {logoItemsReq && (
                      <div className='row'>
                        {logoItems.map((item) => {
                          return (
                            <div className='col-lg-6 col-6 mb-3' key={item.id}>
                              <div className='icon-container'>
                                <div className='icon'>
                                  <img src='/images/icons/arrow.svg' className='img-fluid' />
                                </div>
                                <div className='content'>
                                  <h3>{item.heading}</h3>
                                  <p className='body-paragraph'>{item.paragraph}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}

                    {iconLogoParaReq && 
                      <Fragment> 
                        {iconLogoParaArray.map((item) => {
                          return (
                            <div className='icon_logo_para_wrapper'>
                              <div className='logo_head'>
                                <div><img className='img-fluid' src={item.icon} /></div>
                                <div><h5 className='font-mohave'>{item.heading}</h5></div>
                              </div>
                              <div className='para_space body-paragraph'> <p>{item.paragraph}</p> </div>
                            </div>
                          )
                        })}
                      </Fragment>
                    }

                    {breakPara && <p>{breakPara}</p>}
                    <div className='w-100 text-center text-lg-start'>
                    {buttonReq && <a href='tel:363 653 5869'><button className='default-bt genral-btn '>{btnText}</button></a>}
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImgContent;