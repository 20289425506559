import React from 'react';
import './GameStrength.css';

const GameStrength = () => {
    return (
        <div className='game_strength'>
            <div className='container'>
                <div className='game_details'>
                    <h1 className='font-mohave'>Elevate Your Game with Strength Tests</h1>  
                </div> 
 
                <div className='strength_row'>
                    <div className='card_column'>
                        <div className='strength_card'>
                            <h4>1RM Bench</h4>
                            <p>We measure essential metrics such as velocity, power, force, relative scoring, dip, and height during both the concentric and eccentric phases.</p>
                        </div>
                    </div>
                    <div className='img_column'> <img className='img-fluid' src='/images/creatives/1.png' /> </div>
                </div>

                <div className='strength_rvrs_row'> 
                    <div className='img_column'> <img className='img-fluid' src='/images/creatives/2.png' /> </div>
                    <div className='card_column'>
                        <div className='strength_card'>
                            <h4>1RM Squat</h4>
                            <p>Utilizing our advanced equipment, we measure metrics such as velocity, power, force, relative scoring, dip, and height during both the concentric and eccentric phases.</p>
                        </div>
                    </div>
                </div>

                <div className='strength_row'>
                    <div className='card_column'>
                        <div className='strength_card'>
                            <h4>Grip Test</h4>
                            <p>Our Grip Test utilizes a Dynamometer to measure the maximum force and tension in the forearm. By quantifying your grip strength in pounds, we gain insights into your muscular capabilities.</p>
                        </div>
                    </div>
                    <div className='img_column three'> <img className='img-fluid' src='/images/creatives/3.png' /> </div>
                </div>

                <div className='strength_rvrs_row'> 
                    <div className='img_column four'> <img className='img-fluid' src='/images/creatives/4.png' /> </div>
                    <div className='card_column'>
                        <div className='strength_card'>
                            <h4>Rotational Med Ball Toss</h4>
                            <p>Athletes need rotational power, and our Rotational Med Ball Equipped with an IMU for precise measurements, we capture metrics such as velocity, power, force, and relative scoring.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameStrength
