import './App.css';
import "./bootstrap.min.css"
import About from './screens/About';
import Curriculam from './screens/Curriculam';
import Home from './screens/Home';
import { Routes, Route, Navigate } from 'react-router-dom';
import WhyUs from './screens/WhyUs';
import What from './screens/What';
import HouseLeagues from './screens/HouseLeagues';
import GrassRootDevMain from './screens/GrassRootDevMain';
import FourU from './screens/FourU';
import SevenU from './screens/SevenU';
import FiveU from './screens/FiveU';
import FastTracking from './screens/FastTracking';
import Development from './screens/Development';
import Ltad from './screens/Ltad';
import Advantage from './screens/Advantage';
import UniqueApproach from './screens/UniqueApproach';
import QualityFirst from './screens/QualityFirst';

import Blog from "./screens/news"
import BlogInner from "./screens/newsInner"
import Contact from './screens/Contact';
import LeaderBoard from './screens/LeaderBoard';
import Events from './screens/Events';
import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";
import LocationScreen from './screens/LocationScreens/LocationScreen';
import LocationPages from "./component/LocationPages_/LocationPages"
import NotFound from './screens/NotFound';
import Thank from './screens/Thank';
import EliteTesting from './screens/EliteTesting';



function App() {
  return (
    <div style={{backgroundColor:"white"}}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to={"/"} />} />
        <Route path="/about" element={<About />} />
        <Route path="/curriculum" element={<Curriculam />} />
        <Route path="/why-us" element={<WhyUs />} />
        <Route path="/services" element={<What />} />
        <Route path="/house-leagues" element={<HouseLeagues />} />
        <Route path="/grass-root-development" element={<GrassRootDevMain />} />
        <Route path="/4-u-team-support-development" element={<FourU />} />
        <Route path="/5-u-team-support-development" element={<FiveU />} />
        <Route path="/7-u-team-support-development" element={<SevenU />} />
        <Route path="/fast-tracking" element={<FastTracking />} />
        <Route path="/long-term-athlete-development" element={<Ltad />} />

        <Route path="/development" element={<Development />} />
        <Route path="/advantage" element={<Advantage />} />
        <Route path="/unique-approach" element={<UniqueApproach />} />
        <Route path="/quality-first" element={<QualityFirst />} />
        <Route path="/contact" element={< Contact/>} />
        <Route path="/leaderboard" element={< LeaderBoard/>} />
        <Route path="/events" element={< Events/>} />
        <Route path="/elite-testing" element={< EliteTesting/>} />

        <Route path="/blog" element={< Blog/>} />
        <Route path={`/blog/:id`} element={< BlogInner/>} />

        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        
        <Route path="/thank-you" element={<Thank />} />

        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>
        <Route path='/*' element={<NotFound />}/>
    

      </Routes>
    </div>
  );
}

export default App;

