import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'

function Thank() {
    return (
        <div className="home-page">
         
          <HeroBanner 
            subHeading=""
            Heading="Thank You"
            nextLineSpanHeading=""
            Paragraph="Your form has been submitted.We would reach to you soon!"
            btn={false}
            btnText=""
            bgImg="/images/creatives/plane-hero-bg.png"
            btnRef="/"
            bannerMarginBottom={true}
          />
       
        <Footer
            newsletterSection={false}
        />
    </div>
  )
}

export default Thank