export const BodyTestArr = [
    {
      id: 1,
      heading: "Bilateral Bounds",
      para: `Boost your power and quickness with our Bilateral Bounds test. This assessment involves performing three long bounding strides from right to left and then three bounds from left to right.`,
    },
    {
      id: 2,
      heading: "Broad Jump",
      para: `Uncover your explosive power and lower-body strength with our Broad Jump test. The Broad Jump measures your lower-body balance and strength.`,
    },
    {
      id: 3,
      heading: "Countermovement Jump (CMJ)",
      para: `By quickly squatting to a self-selected depth and then jumping as high as possible, you showcase your explosive strength.`,
    },
    {
      id: 4,
      heading: "Multi Rebound Jump",
      lastDiv: true,
      para: `This test utilizes multiple jump heights to measure the reactive strength index (RSI) of athletes. It also provides valuable metrics and left/right differentials.`,
    },
];
