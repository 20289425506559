import React, { Fragment } from 'react';
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import ImgContent from '../component/Image By Content/ImgContent';
import UnleashPotential from '../component/Unleash Potential/UnleashPotential';
import Agility from '../component/Agility Test/Agility';
import SpeedTest from '../component/Speed Tests/SpeedTest';
import BodyTest from '../component/Body Test/BodyTest';
import { BodyTestArr } from '../component/Constants/BodyTestArr';
import GameStrength from '../component/Game Strength Test/GameStrength';
import Footer from '../component/Footer/Footer';

const EliteTesting = () => { 
  return (
    <Fragment>
      
      <HeroBanner 
        subHeading=""
        Heading="ELITE TESTING"
        nextLineSpanHeading=""
        Paragraph="Maximize Your Athletic Performance With Real Data"
        btnText=""
        btn={false}
        btnRef="/"
        // bgImgLg="/images/creatives/elite-testing-bg.jpg"
        // bgImg="/images/creatives/elite-testing-bg-mb.jpg"
        bgImg="/images/creatives/SportsTesting-hero-bg-new-mob.jpg"
        bgImgLg="/images/creatives/SportsTesting-hero-bg-new.jpg"
        mobileText={false}
        // bgSmallScreenWidth={true}
      
      />
    
      <ImgContent  
        iconLogoParaReq={true}
        iconLogoParaArray={[
          { id: 1, icon: '/images/icons/height-measurements.svg', heading: 'Height Measurements', paragraph: `When it comes to determining an athlete's height, precision is key. That's why we utilise advanced tools like stadiometers to ensure accurate measurements. With the athlete standing straight, in bare feet, and their back against a flat surface, our team takes precise height measurements using these state-of-the-art devices.` },
          { id: 2, icon: '/images/icons/weight-measurements.svg', heading: 'Weight Measurement', paragraph: `Understanding body weight distribution and imbalances is crucial for optimising performance. That's why we employ cutting-edge dual plate force plates. By utilising this innovative technology, we can collect detailed data on body weight and identify any differences between the left and right sides of the body.`  }
        ]}
        buttonReq={true}
        btnText={'REGISTER NOW'}  
        heading={'ASSESSMENTS'} 
        subHeading={'Anthropometrics'}
        imgUrl={'/images/creatives/elite-text-1.png'} 
      />
      <ImgContent  
        iconLogoParaReq={true}
        iconLogoParaArray={[
          { id: 1, icon: '/images/icons/height-measurements.svg', heading: 'Heart Rates', paragraph: ' Our cutting-edge heart rate monitors allow us to delve into the internal load experienced by athletes during their activities. By measuring heart rate, we gain valuable insights into how the body physiologically responds to the workload of a specific session. This information helps us understand the impact of training on your cardiovascular system and tailor our coaching and training strategies accordingly.' },
          { id: 2, icon: '/images/icons/weight-measurements.svg', heading: 'GPS', paragraph: `With the aid of GPS systems, we accurately measure external loads—physical work performed by your body through movement. This data helps us quantify the intensity, distance, speed, and other vital metrics associated with your performance. By analyzing external loads, we can fine-tune training programs, optimize your child’s technique, and enhance overall athletic development.` }
        ]}
        reverse={'flex-row-reverse'}
        buttonReq={true}
        btnText={'REGISTER NOW'}  
        heading={'Performance Insights: External and Internal Values'} 
        imgUrl={'/images/creatives/elite-text-2.png'} 
      /> 
      <SpeedTest />
      <BodyTest arrayData={BodyTestArr} />
      <GameStrength />
      <UnleashPotential />
      <Agility />

      <Footer
        newsletterSection={false}
      />
    </Fragment>
  )
}

export default EliteTesting; 